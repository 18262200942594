const vasListAllProducts = {
  request: {},

  response: {
    name: "TOP",
    productType: "<product category>",
    members: [
      {
        name: "Ithuba",
        productType: "<product category>",
        members: [
          {
            name: "Ithuba Product",
            amount: 0,
            productId: "71",
            productType: "Ithuba",
            provider: "Ithuba",
          },
        ],
      },
      {
        name: "TOPUP AIRTIME",
        productType: "<product category>",
        members: [
          {
            name: "test topup prod",
            productId: "28",
            productType: "TOPUP AIRTIME",
            provider: "Vodacom",
          },
        ],
      },
      {
        name: "Electricity",
        productType: "<product category>",
        members: [
          {
            name: "Electricity Product",
            amount: 0,
            productId: "72",
            productType: "Electricity",
            provider: "Aeon",
          },
        ],
      },
    ],
  },
};

export default vasListAllProducts;
