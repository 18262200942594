const transaction = {
  request: {
    paymentIntentId: "{{paymentIntentID}}",
    amount: 10000,
    currency: "ZAR",
    cardItems: [
      {
        quantity: 2,
        ref: "Test ref",
        description: "Test description",
        amount: 10000,
      },
    ],
    merchantId: "f3d69c2f-38c9-43eb-9946-809bd9a5ff04",
    profileId: "fb9aca6d-3614-4cd1-b1dc-8e4b5056c7a7",
    merchantRef: "Test ref",
  },

  response: {
    transactionId: "71916204-3cf2-43a5-8852-28bc6afea571",
    paymentIntentId: "78e9eca2-6684-4ad2-b6cc-45a6caa4e8c5",
    amount: 10000,
    currency: {
      id: 1,
      createdAt: "2022-08-15T18:06:06.317+02:00",
      lastModified: "2022-08-15T18:06:06.317+02:00",
      isoCode: "ZAR",
      isoNumber: 710,
      fractionalNumber: 2,
      symbol: "R",
    },
    transactionStatus: {
      id: 1,
      createdAt: "2022-08-15T18:06:06.293+02:00",
      lastModified: "2022-08-15T18:06:06.293+02:00",
      name: "PAYMENT_INITIATED",
    },
    createdAt: "2022-10-04T08:19:00.562+02:00",
    lastModified: "2022-10-04T08:19:00.562+02:00",
    merchantRef: "Demo Test Ref",
  },
};

export default transaction;
