import React from 'react';

import { Grid } from '@material-ui/core';

import FaqAccordion from '../components/FaqAccordion';

const FAQPage = () => {
  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={12}
        style={{
          background: '#00C1FF1A',
          color: '#00C1FF',
          margin: '0 auto',
        }}
        className="tj-grid-container"
      >
        <h2>Receipt Field Descriptions</h2>
        <FaqAccordion />
      </Grid>
    </Grid>
  );
};

export default FAQPage;
