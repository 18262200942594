import React from "react";
import styled from "styled-components";

const ReceiptContainer = styled.div`
  width: 350px;
  height: 100%;
  background: #fff;
  border: 1px solid #000;
  padding: 10px;
  color: #000;
  font-family: "VT323", monospace;
  display: inline-block;
  position: relative;
`;

const AddressContainer = styled.div`
  margin-bottom: 20px;
  padding: 0 20px;
  text-align: center;
`;

const PaymentContainer = styled.div`
  margin-top: 40px;
  padding: 1rem;
`;

const Address = styled.div`
  font-size: 12px;
`;

const TransactionDetails = styled.div`
  display: flex;
  margin: 0 10px 10px;
  font-size: 10px;
  justify-content: space-between;
`;

const Detail = styled.div`
  width: 50%;
  font-size: 10px;
  text-transform: uppercase;
`;

const CenterHeading = styled.div`
  text-align: center;
  padding: 1rem;
  margin-top: 10px;
`;

const PaymentDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
`;

const Amount = styled.div`
  width: 50%;
  font-size: 10px;
  text-transform: uppercase;
  text-align: right;
`;

const TJReceiptApprovedExample = () => {
  return (
    <ReceiptContainer>
      <CenterHeading>
        <h4>&lt;&lt;EFT Purchase&gt;&gt;</h4>
        <h4>Customer Copy</h4>
        <h4 style={{ fontSize: 14, fontWeight: "600" }}>Approved</h4>
        &nbsp;
        <span style={{ fontSize: 14, fontWeight: "600" }}>00</span>
      </CenterHeading>
      <AddressContainer>
        <Address style={{ display: "flex", justifyContent: "center" }}>
          TJ store, 13 Oxbow Cresent, Century City
        </Address>
      </AddressContainer>

      {/* <TransactionDetails>Helped by: Cashier Name</TransactionDetails>
        <div className="centerItem bold">
          <div className="item">ExtraCare Card #: *********1875</div>
        </div> */}
      <TransactionDetails>
        <Detail>20170116</Detail>
        <Detail>175120</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>120034005600780</Detail>
        <Detail>TJ123456</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>541216******2356</Detail>
        <Detail>100234</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>Credit Card</Detail>
        <Detail>C</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>TJ5432154321</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>649279</Detail>
      </TransactionDetails>

      <TransactionDetails>
        <Detail>Bank Merchant ID:</Detail>
        <Detail>0000651122</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>TC:</Detail>
        <Detail>D738E9696C12A12B</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>MASTERCARD:</Detail>
        <Detail>0000008000</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>A0000000041014</Detail>
        <Detail>E800</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>PIN Statement:</Detail>
        <Detail>PIN Verified</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>IAD:</Detail>
        <Detail>06070A0364AC02</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>UTI:</Detail>
        <Detail>48952612-8733-45c8-bb78-5aa6c763ec14</Detail>
      </TransactionDetails>
      <PaymentContainer>
        <PaymentDetails>
          <Detail>TOTAL</Detail>
          <Amount>R125.20</Amount>
        </PaymentDetails>
        <PaymentDetails>
          <Detail>Cash Amount</Detail>
          <Amount>R0.00</Amount>
        </PaymentDetails>
      </PaymentContainer>
      <CenterHeading></CenterHeading>
    </ReceiptContainer>
  );
};

export default TJReceiptApprovedExample;
