const vasListProducts = {
  request: {},

  response: [
    {
      name: "iTunes Fixed R150",
      amount: 15000,
      productId: "10",
      productType: "Digital Content",
      provider: "Stellr",
    },
    {
      name: "PlayStation Wallet Top Up R500",
      amount: 50000,
      productId: "27",
      productType: "Digital Content",
      provider: "Stellr",
    },
    {
      name: "Mock POR (Success)",
      amount: 100000,
      productId: "12",
      productType: "Digital Content",
      provider: "Stellr",
    },
  ],
};

export default vasListProducts;
