const settle = {
  request: {
    merchantId: "f3d69c2f-38c9-43eb-9946-809bd9a5ff04",
    profileId: "fb9aca6d-3614-4cd1-b1dc-8e4b5056c7a7",
    transactionId: "{{transactionId}}",
  },

  response: {
    transactionId: "5d004f00-35c9-46aa-a3c3-ed6be0c8e0c8",
    transactionStatus: "PAYMENT_SETTLED",
    iso8583ResponseCode: "00",
    iso8583ResponseCodeMeaning: "Approved or completed successfully",
    responseText: "Approved",
  },
};

export default settle;
