import React, { useState } from 'react';

import { Grid } from '@material-ui/core';

import TJSwagger from '../components/TJSwagger';

// APIs
import jposYAML from '../yaml/jpos.yaml';
import junctionPOSData from '../content/Junction@POS/content';

// Layout helpers
import MaterialTable from 'material-table';
import { tableIcons } from '../helpers/tableHelpers';
import styled, { keyframes } from 'styled-components';
import { fadeInUp, fadeInLeft, fadeInRight } from 'react-animations';
import jPosimg from '../static/images/jpospmain.png';
import jPosLogo from '../static/images/jposlogo.png';
import AdditionalResourceImg from '../static/images/additional-resources-bg.png';
import TJTabs from '../components/TJTabs';
import TJTextAccordion from '../components/TJTextAccordion';
import TJReceiptAccordion from '../components/TJReceiptAccordion';
import TJReceiptTextAccordion from '../components/TJRecieptTextAccordion';
import RESTAPISimUserGuideRemote from '../RESTAPISimUserGuideRemote_v1.7.01.pdf';
import TerminologyUsed from '../TerminologyUsed.pdf';

const fadeInUpAnimation = keyframes`${fadeInUp}`;
const fadeInRightAnimation = keyframes`${fadeInRight}`;
const fadeInLeftAnimation = keyframes`${fadeInLeft}`;

const AnimationContainer = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const AnimationContainerMobile = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    display: block;
  }
  @media screen and (min-width: 960px) {
    display: none;
  }
`;

const FadeInUp = styled.div`
  top: 35px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: -40px;
  text-align: center;
  z-index: 1;
  position: absolute;
  animation: 1s ${fadeInUpAnimation};
  width: 345px;

  @media screen and (min-width: 1025px) {
    width: 400px;
  }
  img {
    width: 300px;
    @media screen and (min-width: 600px) {
      width: 345px;
    }
    @media screen and (min-width: 1025px) {
      width: 400px;
    }
  }
`;

const FadeInRight = styled.div`
  bottom: 10%;
  right: 10%;
  z-index: 2;
  position: absolute;
  animation: 1s ${fadeInRightAnimation};

  img {
    width: 120px;
    @media screen and (min-width: 600px) {
      width: 150px;
    }
  }
`;

const FadeInLeft = styled.div`
  animation: 0.5s ${fadeInLeftAnimation};
  margin-top: 15px;
  width: 300px;
  height: 300px;
  border-radius: 15px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(0, 130, 217, 1) 58%,
    rgba(0, 193, 255, 1) 93%
  );
  @media screen and (min-width: 600px) {
    width: 345px;
    height: 345px;
  }
  @media screen and (min-width: 1025px) {
    width: 400px;
    height: 400px;
  }
`;

const PartyUseCase = () => {
  return (
    <Grid container spacing={0}>
      <h1>content</h1>
    </Grid>
  );
};

export default PartyUseCase;
