import React from "react";
import styled from "styled-components";

const ReceiptContainer = styled.div`
  width: 350px;
  height: 100%;
  background: #fff;
  margin-right: 6.3vh;
  border: 1px solid #000;
  padding: 10px;
  color: #000;
  display: inline-block;
  font-family: "VT323", monospace;
  position: relative;
  float: left;
`;

const ReceiptLogoContainer = styled.div`
  text-align: center;
  padding: 20px;
  color: #3dbdee;
`;

const AddressContainer = styled.div`
  margin-bottom: 40px;
  padding: 0 20px;
  text-align: center;
`;
const Address = styled.div`
  font-size: 12px;
`;

const TransactionDetails = styled.div`
  display: flex;
  margin: 0 10px 10px;
  font-size: 10px;
  justify-content: space-between;
`;

const TransactionDetailsMerge = styled.div`
  display: flex;
  margin: 0 10px 10px;
  font-size: 10px;
  color: #3dbdee;
  justify-content: space-between;
`;

const CenterHeading = styled.div`
  text-align: center;
  padding: 1rem;
`;

const Detail = styled.div`
  width: 50%;
  font-size: 10px;
`;

const PaymentDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
`;

const PaymentDetailsMerged = styled.div`
  display: flex;
  color: #3dbdee;
  justify-content: space-between;
  font-weight: 600;
`;

const Amount = styled.div`
  width: 50%;
  font-size: 10px;
  text-align: right;
`;

const TJReceiptApproved = () => {
  return (
    <ReceiptContainer>
      <ReceiptLogoContainer>&lt;&lt;logo&gt;&gt;</ReceiptLogoContainer>
      <AddressContainer>
        <Address>Store Name</Address>
        <Address>Store Address</Address>
        <Address>Contact Number</Address>
      </AddressContainer>
      <CenterHeading>
        <h4 style={{ color: "#3dbdee" }}>&lt;&lt;TranType&gt;&gt;</h4>
        <h4>Customer/Merchant Copy</h4>
      </CenterHeading>
      <TransactionDetails>
        <Detail>Terminal ID</Detail>
        <Detail>&lt;&lt;pos&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>Date and Time:</Detail>
        <Detail>&lt;&lt;POSDate&gt;&gt; &lt;&lt;POSTime&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>Store Merchant ID:</Detail>
        <Detail>&lt;&lt;store&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetailsMerge>
        <Detail>Bank Merchant ID:</Detail>
        <Detail>&lt;&lt;data.BankMID&gt;&gt;</Detail>
      </TransactionDetailsMerge>
      <TransactionDetails>
        <Detail>Card Name:</Detail>
        <Detail>&lt;&lt;card_name&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>Card Number:</Detail>
        <Detail>&lt;&lt;card&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>STAN:</Detail>
        <Detail>&lt;&lt;STAN&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>RRN:</Detail>
        <Detail>&lt;&lt;rrn&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetailsMerge>
        <Detail>AUTH ID:</Detail>
        <Detail>&lt;&lt;authID&gt;&gt;</Detail>
      </TransactionDetailsMerge>
      <TransactionDetailsMerge>
        <Detail>PAN Entry Mode:</Detail>
        <Detail>&lt;&lt;card_entry&gt;&gt;</Detail>
      </TransactionDetailsMerge>
      <TransactionDetailsMerge>
        <Detail>PIN Statement:</Detail>
        <Detail>&lt;&lt;pin_statement&gt;&gt;</Detail>
      </TransactionDetailsMerge>
      <TransactionDetailsMerge>
        <Detail>TSI:</Detail>
        <Detail>&lt;&lt;tsi&gt;&gt;</Detail>
      </TransactionDetailsMerge>
      <TransactionDetailsMerge>
        <Detail>TVR:</Detail>
        <Detail>&lt;&lt;tvr&gt;&gt;</Detail>
      </TransactionDetailsMerge>
      <TransactionDetailsMerge>
        <Detail>&lt;&lt;CryptogramType&gt;&gt;:</Detail>
        <Detail>&lt;&lt;cryptogram&gt;&gt;</Detail>
      </TransactionDetailsMerge>
      <TransactionDetailsMerge>
        <Detail>APL:</Detail>
        <Detail>&lt;&lt;apl&gt;&gt;</Detail>
      </TransactionDetailsMerge>
      <TransactionDetailsMerge>
        <Detail>AID:</Detail>
        <Detail>A0000000041010</Detail>
      </TransactionDetailsMerge>
      <TransactionDetailsMerge>
        <Detail>IAD:</Detail>
        <Detail>&lt;&lt;aid&gt;&gt;</Detail>
      </TransactionDetailsMerge>
      <TransactionDetails>
        <Detail>UTI:</Detail>
        <Detail>&lt;&lt;reference&gt;&gt;</Detail>
      </TransactionDetails>
      <CenterHeading>
        <span style={{ color: "#3dbdee" }}>*****************************</span>
        <span style={{ display: "block", color: "#3dbdee" }}>
          &lt;&lt;ReceiptData&gt;&gt;
        </span>
        <span style={{ color: "#3dbdee" }}>*****************************</span>
      </CenterHeading>
      <PaymentDetails>
        <Detail>TOTAL</Detail>
        <Amount>R &lt;&lt;amount&gt;&gt;</Amount>
      </PaymentDetails>
      <PaymentDetailsMerged>
        <Detail>Cash Amount</Detail>
        <Amount>R &lt;&lt;cash&gt;&gt;</Amount>
      </PaymentDetailsMerged>
      <CenterHeading>
        <span style={{ fontSize: 14, fontWeight: "600" }}>
          &lt;&lt;response_text&gt;&gt;
        </span>
        &nbsp;
        <span style={{ fontSize: 14, fontWeight: "600" }}>
          &lt;&lt;response_code&gt;&gt;
        </span>
      </CenterHeading>
    </ReceiptContainer>
  );
};

export default TJReceiptApproved;
