const payment = {
  request: {
    tran: "PAYMENT",
    amount: 5000,
    reference: "f40030cc-c3af-4aee-a2e2-09c080fbf498",
    pos: "57290093",
    store: "RESTSIM00000001",
    chain: "Test",
  },

  response: {
    authID: "977880",
    tran: "PAYMENT",
    response_code: "00",
    response_text: "Approved",
    amount: 5000,
    accountBalance: "55",
    creditAvailable: "10200",
    reference: "f40030cc-c3af-4aee-a2e2-09c080fbf498",
    seq: "000046",
    rrn: "000046241042",
    pos: "57290093",
    store: "RESTSIM00000001",
    card: "402167******7052",
    card_name: "Investec Platinum",
    card_entry: "S",
    sign: false,
    receipt: [],
    data: [],
  },
};

export default payment;
