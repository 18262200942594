const threeDSLookup = {
  request: {
    pan: "4895749143709709",
    expiry: "2610",
    purchAmount: 94.99,
    description: "Movie",
    okUrl:
      "https://4a0b4817-9e73-4451-9bb7-404a4adcb171.mock.pstmn.io/bankservcallback",
    failUrl:
      "https://4a0b4817-9e73-4451-9bb7-404a4adcb171.mock.pstmn.io/bankservcallback",
    currencyCode: "710",
    profileId: "fb9aca6d-3614-4cd1-b1dc-8e4b5056c7a7",
    md: "test123",
    transactionId: "{{transactionId}}",
  },

  response: {
    transactionId: "71916204-3cf2-43a5-8852-28bc6afea571",
    redirectToACSForm: "url",
  },
};

export default threeDSLookup;
