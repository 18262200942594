import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import returns from "../transactionData/Junction@pos/j@pReturn";
import reversal from "../transactionData/Junction@pos/j@pReversal";
import cashBack from "../transactionData/Junction@pos/j@pCashBack";
import voucher from "../transactionData/Junction@pos/j@pVoucher";
import token from "../transactionData/Junction@pos/j@pToken";
import payment from "../transactionData/Junction@pos/j@pPayment";
import paymentRefund from "../transactionData/Junction@pos/j@pPaymentRefund";
import purchase from "../transactionData/Junction@pos/j@pPurchase";
import deposit from "../transactionData/Junction@pos/j@pDeposit";
import cashWithdrawl from "../transactionData/Junction@pos/j@pCashWithdrawl";
import balanceEnquiry from "../transactionData/Junction@pos/j@pBalanceEnquiry";
import digitalVoucher from "../transactionData/Junction@pos/j@pDigitalVoucher";
import loyalty from "../transactionData/Junction@pos/j@pLoyalty";
import giftcardIssue from "../transactionData/Junction@pos/j@pGiftcardIssue";
import giftCardReload from "../transactionData/Junction@pos/j@pGiftcardReload";
import pushFunds from "../transactionData/Junction@pos/j@pPushFunds";
import eFuelFinalisation from "../transactionData/Junction@pos/j@pEFuelFinal";
import eFuelPreAuth from "../transactionData/Junction@pos/j@pEFuelAuth";

// Assets
const TJTab = withStyles((theme) => ({
  root: {
    textTransform: "uppercase",
    minWidth: 130,
    width: 130,
    height: 60,
    fontWeight: 800,
    marginRight: theme.spacing(4),
    fontFamily: "Montserrat-Bold",
    background: "#00C1FF33",
    wordBreak: "keep-all",
    marginBottom: 20,
    borderRadius: 10,
    color: "#000000",
    "&:hover": {
      color: "#00C1FF",
      background: "#034EA2",
    },
    "&$selected": {
      color: "#00C1FF",
      background: "#034EA2",
    },
    "&:focus": {
      color: "#00C1FF",
    },
  },
  selected: {},
}))((props) => <Tab {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography style={{ color: "#000" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {},
}));

export default function TJTabs({ data }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        textColor="primary"
        variant="fullWidth"
        aria-label="transaction-types"
      >
        {data.transactionTypes.map((tranType, index) => {
          return (
            <TJTab label={tranType.type} key={index} {...a11yProps(index)} />
          );
        })}
      </Tabs>

      {data.transactionTypes.map((tranType, index) => {
        return (
          <TabPanel key={index} value={value} index={index}>
            {tranType.description}

            {tranType.type === "PURCHASE" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>{JSON.stringify(purchase.request, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(purchase.response, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>
                  *Example shows basic use case with sample values. Contact your
                  Transaction Junction representative for Chain, Store and POS
                  values.
                </p>
              </div>
            )}
            {tranType.type === "REVERSAL" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>{JSON.stringify(reversal.request, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(reversal.response, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>
                  *Example shows basic use case with sample values. Contact your
                  Transaction Junction representative for Chain, Store and POS
                  values.
                </p>
              </div>
            )}
            {tranType.type === "RETURN" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>{JSON.stringify(returns.request, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>{JSON.stringify(returns.response, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>
                  *Example shows basic use case with sample values. Contact your
                  Transaction Junction representative for Chain, Store and POS
                  values.
                </p>
              </div>
            )}

            {tranType.type === "TOKEN" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>{JSON.stringify(token.request, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>{JSON.stringify(token.response, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>
                  *Example shows basic use case with sample values. Contact your
                  Transaction Junction representative for Chain, Store and POS
                  values.
                </p>
              </div>
            )}
            {tranType.type === "VOUCHER" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>{JSON.stringify(voucher.request, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>{JSON.stringify(voucher.response, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>
                  *Example shows basic use case with sample values. Contact your
                  Transaction Junction representative for Chain, Store and POS
                  values.
                </p>
              </div>
            )}
            {tranType.type === "GIFTCARD ISSUE" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(giftcardIssue.request, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(giftcardIssue.response, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>
                  *Example shows basic use case with sample values. Contact your
                  Transaction Junction representative for Chain, Store and POS
                  values.
                </p>
              </div>
            )}
            {tranType.type === "GIFTCARD RELOAD" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(giftCardReload.request, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(giftCardReload.response, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>
                  *Example shows basic use case with sample values. Contact your
                  Transaction Junction representative for Chain, Store and POS
                  values.
                </p>
              </div>
            )}
            {tranType.type === "PAYMENT" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>{JSON.stringify(payment.request, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>{JSON.stringify(payment.response, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>
                  *Example shows basic use case with sample values. Contact your
                  Transaction Junction representative for Chain, Store and POS
                  values.
                </p>
              </div>
            )}
            {tranType.type === "PAYMENT REFUND" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(paymentRefund.request, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(paymentRefund.response, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>
                  *Example shows basic use case with sample values. Contact your
                  Transaction Junction representative for Chain, Store and POS
                  values.
                </p>
              </div>
            )}
            {tranType.type === "PUSH FUNDS" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(pushFunds.request, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(pushFunds.response, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>
                  *Example shows basic use case with sample values. Contact your
                  Transaction Junction representative for Chain, Store and POS
                  values.
                </p>
              </div>
            )}
            {tranType.type === "EFUEL PRE-AUTH" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(eFuelPreAuth.request, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(eFuelPreAuth.response, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>
                  *Example shows basic use case with sample values. Contact your
                  Transaction Junction representative for Chain, Store and POS
                  values.
                </p>
              </div>
            )}
            {tranType.type === "EFUEL FINALISATION" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(eFuelFinalisation.request, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(
                              eFuelFinalisation.response,
                              null,
                              2
                            )}
                          </pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>
                  *Example shows basic use case with sample values. Contact your
                  Transaction Junction representative for Chain, Store and POS
                  values.
                </p>
              </div>
            )}
            {tranType.type === "DIGITAL VOUCHER" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(digitalVoucher.request, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(digitalVoucher.response, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>
                  *Example shows basic use case with sample values. Contact your
                  Transaction Junction representative for Chain, Store and POS
                  values.
                </p>
              </div>
            )}
            {tranType.type === "LOYALTY" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>{JSON.stringify(loyalty.request, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>{JSON.stringify(loyalty.response, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>
                  *Example shows basic use case with sample values. Contact your
                  Transaction Junction representative for Chain, Store and POS
                  values.
                </p>
              </div>
            )}
            {tranType.type === "CASH WITHDRAWAL" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(cashWithdrawl.request, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(cashWithdrawl.response, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>
                  *Example shows basic use case with sample values. Contact your
                  Transaction Junction representative for Chain, Store and POS
                  values.
                </p>
              </div>
            )}

            {tranType.type === "CASH BACK" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>{JSON.stringify(cashBack.request, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(cashBack.response, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>
                  *Example shows basic use case with sample values. Contact your
                  Transaction Junction representative for Chain, Store and POS
                  values.
                </p>
              </div>
            )}
            {tranType.type === "DEPOSIT" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>{JSON.stringify(deposit.request, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>{JSON.stringify(deposit.response, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>
                  *Example shows basic use case with sample values. Contact your
                  Transaction Junction representative for Chain, Store and POS
                  values.
                </p>
              </div>
            )}
            {tranType.type === "BALANCE ENQUIRY" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(balanceEnquiry.request, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(balanceEnquiry.response, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>
                  *Example shows basic use case with sample values. Contact your
                  Transaction Junction representative for Chain, Store and POS
                  values.
                </p>
              </div>
            )}
          </TabPanel>
        );
      })}
    </div>
  );
}
