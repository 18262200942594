const purchase = {
  request: {
    tran: "PURCHASE",
    amount: 500,
    reference: "f40030cc-c3af-4aee-a2e2-09c080fbf498",
    pos: "57290093",
    store: "RESTSIM00000001",
    chain: "Test",
  },

  response: {
    authID: "977880",
    tran: "PURCHASE",
    response_code: "00",
    response_text: "Approved",
    amount: 500,
    reference: "aa901a39-5c3c-4a5e-816c-b9acd9f66622",
    seq: "000046",
    rrn: "000046241042",
    pos: "57290093",
    store: "RESTSIM00000001",
    card: "402167******7052",
    ext_payment: "24",
    acc_type: "Savings",
    approved: "60000",
    ledger_balance: 10000,
    available_balance: 10000,
    card_name: "Investec Platinum",
    card_entry: "S",
    sign: "false",
    receipt: [],
    data: [],
  },
};

export default purchase;
