const apis = [
  {
    name: 'POS Integration',
    excerpt:
      'This API describes the interface between the POS and Junction@POS and will be used by POS integrators wishing to integrate their POS systems for the purpose of online transaction processing via Transaction Junction.',
    slug: 'pos-intergration',
    icon: 'fal fa-cash-register',
    navItems: [
      {
        name: 'More',
        slug: 'more',
      },
    ],
  },
  {
    name: 'VAS Provider API',
    excerpt:
      'This API provides an interface between Transaction Junction’s IMBEKO system and a provider of digital products and services via a simple HTTP request/response model.',
    slug: 'vas-provider-api',
    icon: 'fas fa-building',
    navItems: [
      {
        name: 'Overview',
        slug: 'overview',
      },
      {
        name: 'Implementation Guide',
        slug: 'implementation-guide',
      },
      {
        name: 'Operations',
        slug: 'operations',
      },
    ],
  },
  {
    name: 'QR Code APIs',
    excerpt:
      'QR providers use these APIs to integrate to Transaction Junction’s dynamic, generic QR code solution so that shoppers at Transaction Junction retailers can “scan to pay”.',
    slug: 'qrcode-api',
    icon: 'fal fa-qrcode',
    navItems: [
      {
        name: 'Overview',
        slug: 'overview',
      },
      {
        name: 'Implementation Guide',
        slug: 'implementation-guide',
      },
      {
        name: 'Operations',
        slug: 'operations',
      },
      {
        name: 'Additional Resources',
        slug: 'additional-resources',
      },
    ],
  },
  {
    name: 'Card Payment API',
    excerpt:
      'This API is used to process card-present payments with IMBEKO’s variant of the ISO 20022 nexo message standards.',
    slug: 'card-payment-api',
    icon: 'fal fa-credit-card',
    navItems: [
      {
        name: 'Overview',
        slug: 'overview',
      },
      {
        name: 'Implementation Guide',
        slug: 'implementation-guide',
      },
      {
        name: 'Operations',
        slug: 'operations',
      },
      {
        name: 'Response Codes',
        slug: 'response-codes',
      },
      {
        name: 'Additional Resources',
        slug: 'additional-resources',
      },
    ],
  },
  {
    name: 'VAS Client API',
    excerpt:
      'This API enables merchants to sell the digital products and other value added services (VAS) that are available through Transaction Junction’s IMBEKO system.',
    slug: 'vas-client-api',
    icon: 'fas fa-store',
    navItems: [
      {
        name: 'Overview',
        slug: 'overview',
      },
      {
        name: 'Implementation Guide ',
        slug: 'implementation-guide',
      },
      {
        name: 'Operations',
        slug: 'operations',
      },
      {
        name: 'Response Codes',
        slug: 'response-codes',
      },
      {
        name: 'Additional Resources',
        slug: 'additional-resources',
      },
    ],
  },
  {
    name: 'Internet Payment Gateway',
    excerpt:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
    slug: 'internet-payment-gateway',
    icon: 'fal fa-comments-alt-dollar',
    navItems: [],
  },
  {
    name: 'Hosted Payment Page',
    excerpt:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
    slug: 'payment-gateway-api',
    icon: 'fal fa-dungeon',
    navItems: [
      {
        name: 'Overview',
        slug: 'overview',
      },
      {
        name: 'Authentication ',
        slug: 'authentication',
      },
      {
        name: 'Make Payment',
        slug: 'make-payment',
      },
      {
        name: 'Finalise Payment',
        slug: 'finalise-payment',
      },
      {
        name: 'Process a Refund',
        slug: 'process-a-refund',
      },

      {
        name: 'Additional Resources',
        slug: 'additional-resources',
      },
    ],
  },
  {
    name: 'Direct Intergration',
    excerpt:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
    slug: 'direct-intergration-api',
    icon: 'fal fa-hand-holding-usd',
    navItems: [
      {
        name: 'Overview',
        slug: 'overview',
      },
      {
        name: 'Authentication ',
        slug: 'authentication',
      },
      {
        name: 'Make Payment',
        slug: 'make-payment',
      },
      {
        name: 'ThreeDSecure',
        slug: 'three-d-secure',
      },
      {
        name: 'Finalise Payment',
        slug: 'finalise-payment',
      },
      {
        name: 'Webhook',
        slug: 'webhook',
      },
      {
        name: 'Additional Resources',
        slug: 'additional-resources',
      },
    ],
  },

  // {
  //   name: 'Open Payment API ',
  //   excerpt: 'description to go here',
  //   slug: 'openpayment-api',
  //   icon: 'fal fa-money-check',
  //   navItems: [
  //     {
  //       name: 'Overview',
  //       slug: 'overview',
  //     },
  //     {
  //       name: 'Implementation Guide',
  //       slug: 'implementation-guide',
  //     },
  //     {
  //       name: 'Section 3',
  //       slug: 'section-3',
  //     },

  //     {
  //       name: 'Section 4',
  //       slug: 'section-4',
  //     },
  //     {
  //       name: 'Section 5',
  //       slug: 'section-5',
  //     },
  //     {
  //       name: 'Additional Resources',
  //       slug: 'additional-resources',
  //     },
  //   ],
  // },

  // {
  //   name: 'eCommerce ',
  //   excerpt: 'description to go here',
  //   slug: 'ecommerce-api',
  //   icon: 'fal fa-globe',
  //   navItems: [
  //     {
  //       name: 'Overview',
  //       slug: 'overview',
  //     },
  //     {
  //       name: 'Implementation Guide',
  //       slug: 'implementation-guide',
  //     },
  //     {
  //       name: 'Section 3',
  //       slug: 'section-3',
  //     },

  //     {
  //       name: 'Section 4',
  //       slug: 'section-4',
  //     },
  //     {
  //       name: 'Section 5',
  //       slug: 'section-5',
  //     },
  //     {
  //       name: 'Additional Resources',
  //       slug: 'additional-resources',
  //     },
  //   ],
  // },
  {
    name: 'Transactions (current REST API page)',
    excerpt: 'API calls for POS initiated transaction are described here',
    slug: 'transactions',
    icon: 'fas fa-keynote',
    navItems: [
      {
        name: 'Implementation Guide',
        slug: 'implementation-guide',
      },
    ],
  },
  {
    name: 'Pay@Table',
    excerpt: 'API calls for PED initiated transactions are described here',
    slug: 'pay@table',
    icon: 'fas fa-receipt',
    navItems: [
      {
        name: 'Overview',
        slug: 'overview',
      },
      {
        name: 'Implementation',
        slug: 'implementation',
      },
    ],
  },
  {
    name: 'Prompts & Notifications',
    excerpt:
      'API calls used to inform the POS or request input are described here',
    slug: 'prompts-&-notifications',
    icon: 'fas fa-bell-on',
    navItems: [
      {
        name: 'Overview',
        slug: 'overview',
      },
    ],
  },
  {
    name: '3rd Party Use Cases',
    excerpt:
      'Specific use cases using generic calls from the Transaction API are described here',
    slug: '3rd-party-use-cases',
    icon: 'fas fa-gift-card',
    navItems: [
      {
        name: 'Overview',
        slug: 'overview',
      },
    ],
  },
  {
    name: 'FAQ',
    excerpt: 'Information and concepts related to the content described here',
    slug: 'faq',
    icon: 'fas fa-map-marker-question',
    navItems: [],
  },
  // {
  //   name: 'Junction@POS REST API',
  //   excerpt:
  //     'This API describes the interface between the POS and Junction@POS and will be used by POS integrators wishing to integrate their POS systems for the purpose of online transaction processing via Transaction Junction.',
  //   slug: 'junction@POS-rest-api',
  //   icon: 'fal fa-cash-register',
  //   navItems: [
  //     {
  //       name: 'Overview',
  //       slug: 'overview',
  //     },
  //     {
  //       name: 'Implementation Guide',
  //       slug: 'implementation-guide',
  //     },
  //     {
  //       name: 'Operations',
  //       slug: 'operations',
  //     },
  //     {
  //       name: 'Receipt Formatting',
  //       slug: 'receipt-formatting',
  //     },
  //     {
  //       name: 'Response Codes',
  //       slug: 'response-codes',
  //     },
  //     {
  //       name: 'Additional Resources',
  //       slug: 'additional-resources',
  //     },
  //   ],
  // },
];

export default apis;
