import React, { useState } from "react";

import { Grid } from "@material-ui/core";

import TJSwagger from "../components/TJSwagger";

// APIs
import cardpayment from "../yaml/cardPaymentApi.yaml";
import CardPaymentData from "../content/CardPayment/content";
import CardPayTextAccordion from "../components/CardPayTextAccordion";

// Layout helpers

import styled, { keyframes } from "styled-components";
import { fadeInUp, fadeInLeft } from "react-animations";
import CardPayimg from "../static/images/Card Payment API.jpg";
import AdditionalResourceImg from "../static/images/additional-resources-bg.png";
import MaterialTable from "material-table";
import { tableIcons } from "../helpers/tableHelpers";

const fadeInUpAnimation = keyframes`${fadeInUp}`;
const fadeInLeftAnimation = keyframes`${fadeInLeft}`;

const AnimationContainer = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const AnimationContainerMobile = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    display: block;
  }
  @media screen and (min-width: 960px) {
    display: none;
  }
`;

const FadeInUp = styled.div`
  top: 35px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: -40px;
  text-align: center;
  z-index: 1;
  position: absolute;
  animation: 1s ${fadeInUpAnimation};
  width: 345px;

  @media screen and (min-width: 1025px) {
    width: 400px;
  }
  img {
    width: 300px;
    @media screen and (min-width: 600px) {
      width: 345px;
    }
    @media screen and (min-width: 1025px) {
      width: 400px;
    }
  }
`;

const FadeInLeft = styled.div`
  animation: 0.5s ${fadeInLeftAnimation};
  margin-top: 15px;
  width: 300px;
  height: 300px;
  border-radius: 15px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(0, 130, 217, 1) 58%,
    rgba(0, 193, 255, 1) 93%
  );
  @media screen and (min-width: 600px) {
    width: 345px;
    height: 345px;
  }
  @media screen and (min-width: 1025px) {
    width: 400px;
    height: 400px;
  }
`;

const List = styled.ul`
  padding-left: 50px;
`;

const CardPaymentPage = () => {
  return (
    <Grid container spacing={0}>
      <Grid container className="tj-grid-container" id="overview">
        <Grid xs={12} md={6} item>
          <AnimationContainerMobile>
            <FadeInUp>
              <img
                style={{ borderRadius: "15px" }}
                src={CardPayimg}
                alt="CardPayment API image"
              />
            </FadeInUp>
            <FadeInLeft />
          </AnimationContainerMobile>

          <h2>Overview</h2>

          <p>
            The Card Payment API extends the ease of access to IMBEKO for
            processing card-present payment transactions irrespective of card
            presentment mode. This means that the interface can be used to
            process payments acquired by cards that have been:
          </p>
          <p>
            {" "}
            <List style={{ color: "#000" }}>
              <li>Manually entered (such as for non-PCI cards)</li>
              <li>Swiped (typically closed loop or store cards)</li>
              <li>Inserted (EMV-compliant contact)</li>
              <li>Tapped (EMV-compliant contactless)</li>
            </List>
          </p>
          <p>
            This includes support for transactions using Google Pay, Samsung Pay
            and Apple Pay.
          </p>
          <p>
            In addition, the interface is able to perform PIN block translations
            from a PIN encrypted by the client to one encrypted under a shared
            master key. Master/Session Key Management is therefore supported.
          </p>
        </Grid>
        <Grid
          xs={6}
          md={6}
          item
          style={{
            position: "relative",
          }}
        >
          <AnimationContainer>
            <FadeInUp>
              <img
                style={{ borderRadius: "15px" }}
                src={CardPayimg}
                alt="CardPayment API image"
              />
            </FadeInUp>
            <FadeInLeft />
          </AnimationContainer>
        </Grid>
        <Grid xs={12} item>
          <p>
            The interface accepts HTTP payment messages in IMBEKO’s variant of
            the ISO 20022 message standards defined by nexo (in particular the
            Acquirer Protocol specification). The acceptable message structures
            are defined by the API.
          </p>
        </Grid>
      </Grid>

      <Grid
        item
        style={{
          background:
            "transparent linear-gradient(270deg, #0082D9 0%, #00C1FF 100%) 0% 0% no-repeat padding-box",

          color: "#fff",
          margin: "0 auto",
        }}
        className="tj-grid-container"
        id="implementation-guide"
      >
        <h2>Implementation Guide</h2>
        <p>
          Once a project has been lodged with Transaction Junction, development
          against the API can be performed by the integrator.
        </p>
        <p>
          The API is published as an OpenAPI 3 YAML document,{" "}
          <a href="#page-api" className="link">
            which is available on this page.
          </a>
        </p>
        <p>
          Using the OpenAPI 3 YAML format implies that much of the client-side
          code can be generated using the OpenAPI toolset encompassing a wide
          variety of programming languages and application frameworks. This
          should significantly reduce the effort required to complete the
          integration because it will largely eliminate any syntactic issues in
          the exchange of messages between the two parties.
        </p>
        <p>
          There is a wealth of information relating to the use of YAML
          documents.{" "}
          <a
            href="https://swagger.io"
            target="_blank"
            rel="nofollow"
            className="link"
          >
            Swagger
          </a>{" "}
          is a good place to start. To view the YAML document, a good option is
          the online Swagger Editor. To generate a Java client, C# client, or Go
          client from the YAML document, a good option is the{" "}
          <a
            href="https://github.com/OpenAPITools/openapi-generator"
            target="_blank"
            rel="nofollow"
            className="link"
          >
            OpenAPITools generator.
          </a>{" "}
          To generate tests from the YAML document, a good option is{" "}
          <a
            href=" https://learning.postman.com/docs/integrations/available-integrations/working-with-openAPI/"
            target="_blank"
            rel="nofollow"
            className="link"
          >
            Postman.
          </a>
        </p>
        <p>
          When the initial testing is complete to the satisfaction of both
          parties, user acceptance testing will be performed before piloting the
          solution or taking it live.
        </p>
        <p>
          Should you require any assistance, contact:{" "}
          <a href="mailto:cardpayment-integration@switch.tj" className="link">
            cardpayment-integration@switch.tj
          </a>
        </p>
      </Grid>
      <Grid
        item
        xs={12}
        id="operations"
        className="tj-grid-container"
        style={{
          background: "#00C1FF1A",
          color: "#00C1FF",
        }}
      >
        <h2 style={{ color: "#000" }}>Operations</h2>

        <p style={{ color: "#000" }}>
          This solution supports the following transaction types:
        </p>
        <p>
          <List style={{ color: "#000" }}>
            <li>Purchase</li>
            <li>Purchase with cashback</li>
            <li>Cash withdrawal</li>
            <li>Refund</li>
            <li>Balance enquiry</li>
            <li>Deposit</li>
          </List>
        </p>
        <CardPayTextAccordion />
      </Grid>
      <Grid item xs={12} className="tj-grid-container" id="page-api">
        <TJSwagger url={cardpayment} />
      </Grid>

      <Grid
        id="response-codes"
        item
        xs={12}
        style={{
          background:
            "transparent linear-gradient(270deg, #0082D9 0%, #00C1FF 100%) 0% 0% no-repeat padding-box",
          color: "#fff",
          margin: "0 auto",
        }}
        className="tj-grid-container"
      >
        <h2>Response Codes</h2>
        <MaterialTable
          style={{ background: "transparent", boxShadow: "none" }}
          icons={tableIcons}
          columns={[
            {
              title: "Response Code",
              field: "code",
              type: "numeric",
              align: "left",
              headerStyle: {
                width: "20%",
              },
              cellStyle: {
                color: "#fff",
                width: "20%",
              },
            },

            {
              title: "Description",
              field: "message",
              cellStyle: {
                width: "60%",
                color: "#fff",
              },
              headerStyle: {
                width: "60%",
              },
            },
          ]}
          options={{
            toolbar: false,
            thirdSortClick: false,
            draggable: false,
            paging: false,
            headerStyle: {
              background: "transparent",
              border: "none",
              color: "#fff",
              fontFamily: "montserrat-bold",
            },
          }}
          data={CardPaymentData.responseCodes}
          title=""
        />
      </Grid>
      <Grid
        item
        id="additional-resources"
        style={{
          background: "#FFFFFFD9",
          backgroundImage: `url(${AdditionalResourceImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          position: "relative",
        }}
        className="tj-grid-container"
      >
        <h2>Additional Developer Resources</h2>
        <h3 className="light-h3">INTEGRATION ASSISTANCE</h3>

        <p>
          <strong> GENERATION OF TRANSACTIONIDENTIFIER</strong>
        </p>
        <p>
          The transactionIdentifier property in the transaction bodies is a
          unique value that identifies the transaction over the full length of
          the authorisation and settlement chains. It is a UUID and follows RFC
          4122.
          <a
            href="https://tools.ietf.org/html/rfc4122"
            target="_blank"
            rel="nofollow"
            style={{
              color: "#034ea2",
              font: "16px Montserrat-Regular",
              textDecorationLine: "underline",
            }}
          >
            (https://tools.ietf.org/html/rfc4122)
          </a>
        </p>
        <p>
          <strong>JAVA GENERATION</strong>
        </p>
        <p>
          The UUID class in the Java SDK makes it easy to generate a UUID value
          for the transactionIdentifier property that makes all calls to this
          API.
          <br />
          <strong>An example follows:</strong>
        </p>
        <div className="code-box-container">
          <div className="body-container">
            <div className="code-container">
              <code>
                import java.util.UUID;
                <br />
                public class GenerateTransactionIdentifier {`{`}
                <br />
                &nbsp;&nbsp;public static final void main(String[] args) {`{`}
                <br />
                &nbsp;&nbsp;UUID ref = UUID.randomUUID(); {`}`}
                <br />
                {`}`}
              </code>
            </div>
          </div>
        </div>

        <p>
          <strong>C# GENERATION</strong>
        </p>
        <p>
          An example program generating a GUID follows. Essentially, a GUID and
          UUID are the same concept.
        </p>
        <div className="code-box-container">
          <div className="body-container">
            <div className="code-container">
              <code>
                using System;
                <br />
                namespace uuid
                <br />
                &nbsp;&nbsp;{`{`} class Program
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`{`}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;static
                void Main(string[] args)
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {`{`}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Guid
                g;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;g
                = Guid.NewGuid();
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Console.WriteLine(g);
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {`}`}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {`}`}
                <br />
                &nbsp;&nbsp;&nbsp;{`}`}
              </code>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default CardPaymentPage;
