import React, { useState } from "react";

import { Grid } from "@material-ui/core";

import TJSwagger from "../components/TJSwagger";

// APIs
import qrclientYaml from "../yaml/qrclient.yaml";
import qrproviderYaml from "../yaml/qrprovider.yaml";
import QRTextAccordion from "../components/QRTextAccordion";

// Layout helpers
import styled, { keyframes } from "styled-components";
import { fadeInUp, fadeInLeft } from "react-animations";
import qrcodeimg from "../static/images/TJQRCode.jpg";

import AdditionalResourceImg from "../static/images/additional-resources-bg.png";

const fadeInUpAnimation = keyframes`${fadeInUp}`;
const fadeInLeftAnimation = keyframes`${fadeInLeft}`;

const AnimationContainer = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const AnimationContainerMobile = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    display: block;
  }
  @media screen and (min-width: 960px) {
    display: none;
  }
`;

const FadeInUp = styled.div`
  top: 35px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: -40px;
  text-align: center;
  z-index: 1;
  position: absolute;
  animation: 1s ${fadeInUpAnimation};
  width: 345px;

  @media screen and (min-width: 1025px) {
    width: 400px;
  }
  img {
    width: 300px;
    @media screen and (min-width: 600px) {
      width: 345px;
    }
    @media screen and (min-width: 1025px) {
      width: 400px;
    }
  }
`;

const FadeInLeft = styled.div`
  animation: 0.5s ${fadeInLeftAnimation};
  margin-top: 15px;
  width: 300px;
  height: 300px;
  border-radius: 15px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(0, 130, 217, 1) 58%,
    rgba(0, 193, 255, 1) 93%
  );
  @media screen and (min-width: 600px) {
    width: 345px;
    height: 345px;
  }
  @media screen and (min-width: 1025px) {
    width: 400px;
    height: 400px;
  }
`;

const QRCodePage = () => {
  return (
    <Grid container spacing={0}>
      <Grid container className="tj-grid-container" id="overview">
        <Grid xs={12} md={6} item>
          <AnimationContainerMobile>
            <FadeInUp>
              <img
                style={{ borderRadius: "15px" }}
                src={qrcodeimg}
                alt="QRcode"
              />
            </FadeInUp>
            <FadeInLeft />
          </AnimationContainerMobile>

          <h2>Overview</h2>
          <p>
            This solution was designed so that a retailer can offer consumers a
            dynamic QR code “scan-to-pay” option without having to integrate to
            QR providers. Instead, QR providers integrate directly to TJ to
            enable transactions initiated on their respective apps. The solution
            can be used in physical retail stores as well as on e-commerce
            sites.
          </p>
          <p>
            The consumer scans the dynamic TJ QR code using their preferred
            smartphone app. This initiates a transaction to the relevant QR
            partner from the consumer’s app. At the same time, a transaction
            request is sent to TJ.
          </p>
          <p>
            Based on the data contained in the URL, the QR partner identifies
            the transaction as a TJ QR. The QR partner performs an API call to
            the TJ QR API to obtain details of the transaction. The TJ API
            responds with the data elements that the QR partner needs to process
            the transaction to an acquiring bank.
          </p>
        </Grid>
        <Grid
          xs={6}
          md={6}
          item
          style={{
            position: "relative",
          }}
        >
          <AnimationContainer>
            <FadeInUp>
              <img
                style={{ borderRadius: "15px" }}
                src={qrcodeimg}
                alt="Junction@POS"
              />
            </FadeInUp>
            <FadeInLeft />
          </AnimationContainer>
        </Grid>
        <Grid xs={12} item>
          <p>
            The QR partner then performs an API call to notify TJ of the
            transaction outcome. TJ compares the outcome of the transaction to
            the corresponding transaction request, and response is sent to the
            POS by TJ. For example, if used in a physical retail store, the
            transaction outcome is sent to the POS and displayed on the PED so
            that the consumer and cashier can see that it was successful.
          </p>
          <p>
            The solution caters for reversal transactions via a call from TJ to
            the relevant QR partner with a request to reverse, typically in the
            case of a timeout.
          </p>
          <p>
            The implementation of TJ’s QR code solution therefore requires two
            interfaces. The one interface – “QR to TJ” – enables the QR partner
            to obtain information regarding the transaction, to indicate to TJ
            who the QR partner is for the transaction, and to notify TJ of the
            outcome of the transaction so that TJ can locate the held
            transaction and formulate a response to the POS. The QR partner must
            generate a client from this interface to connect to TJ.
          </p>
          <p>
            The other API – “QR from TJ” – enables TJ to send a reversal request
            to the QR partner. The QR partner must provide a server endpoint.
          </p>
        </Grid>
      </Grid>

      <Grid
        item
        style={{
          background:
            "transparent linear-gradient(270deg, #0082D9 0%, #00C1FF 100%) 0% 0% no-repeat padding-box",

          color: "#fff",
          margin: "0 auto",
        }}
        className="tj-grid-container"
        id="implementation-guide"
      >
        <h2>Implementation Guide </h2>
        <p>
          Before accessing this website, Transaction Junction and the QR partner
          will have had a kick-off meeting to discuss the functionality, the
          environments that need to be set up, and the details needed to connect
          to those environments. Once a project has been lodged with Transaction
          Junction, development against the API can be performed by the
          integrator.
        </p>
        <p>
          The API is published as an OpenAPI 3 YAML document,{" "}
          <a href="#page-api" className="link">
            which is available on this page.
          </a>
        </p>
        <p>
          Using the OpenAPI 3 YAML format implies that much of the client-side
          code can be generated using the OpenAPI toolset encompassing a wide
          variety of programming languages and application frameworks. This
          should significantly reduce the effort required to complete the
          integration because it will largely eliminate any syntactic issues in
          the exchange of messages between the two parties.
        </p>
        <p>
          There is a wealth of information relating to the use of YAML
          documents.{" "}
          <a
            href="https://swagger.io"
            target="_blank"
            rel="nofollow"
            className="link"
          >
            Swagger
          </a>{" "}
          is a good place to start. To view the YAML document, a good option is
          the online Swagger Editor. To generate a Java client, C# client, or Go
          client from the YAML document, a good option is the{" "}
          <a
            href="https://github.com/OpenAPITools/openapi-generator"
            target="_blank"
            rel="nofollow"
            className="link"
          >
            OpenAPITools generator.
          </a>{" "}
          To generate tests from the YAML document, a good option is{" "}
          <a
            href=" https://learning.postman.com/docs/integrations/available-integrations/working-with-openAPI/"
            target="_blank"
            rel="nofollow"
            className="link"
          >
            Postman.
          </a>
        </p>
        <p>
          When the initial testing is complete to the satisfaction of both
          parties, user acceptance testing will be performed before piloting the
          solution or taking it live.
        </p>
        <p>
          Email{" "}
          <a href="mailto:qr-integration@switch.tj" className="link">
            qr-integration@switch.tj
          </a>{" "}
          if you have any queries about the implementation or would like access
          to the sandbox environment and test cases.
        </p>
      </Grid>
      <Grid
        item
        xs={12}
        id="operations"
        className="tj-grid-container"
        style={{
          background: "#00C1FF1A",
          color: "#00C1FF",
        }}
      >
        <h2 style={{ color: "#000" }}>Operations</h2>

        <p style={{ color: "#000" }}>
          This solution supports the following transaction types:
        </p>
        <ul style={{ color: "#000" }}>
          <li>Purchase</li>
          <li>Purchase with cashback</li>
          <li>Return</li>
          <li>Deposit</li>
          <li>Reversal</li>
        </ul>

        {/* <p style={{ color: "#000" }}>
          Operations available on the “QR to TJ” API are:
        </p>
        <ul style={{ color: "#000" }}>
          <li style={{ listStyleType: "disc" }}>Get Transaction Info</li>
          <ul>
            <li style={{ listStyleType: "circle" }}>
              The QR partner will send a Get Info Request to TJ as a request for
              transactional information.
            </li>
            <li style={{ listStyleType: "circle" }}>
              TJ will send a Get Info Response to the QR partner with the
              transactional information for the QR Partner to process the
              transaction with the acquirer.
            </li>
          </ul>

          <li style={{ listStyleType: "disc" }}>Transaction Notification</li>
          <ul>
            <li style={{ listStyleType: "circle" }}>
              The QR partner will send the Transaction Notification to TJ to
              indicate the outcome of the transaction.
            </li>
            <li style={{ listStyleType: "circle" }}>
              TJ will send a Transaction Notification Response to the QR partner
              to indicate that the Transaction Notification was received.
            </li>
          </ul>
        </ul>

        <p style={{ color: "#000" }}>
          One operation is available on the “QR from TJ” API:
        </p>
        <ul style={{ color: "#000" }}>
          <li style={{ listStyleType: "disc" }}>Transaction Reversal</li>
          <ul>
            <li style={{ listStyleType: "circle" }}>
              In the case of a timeout, TJ will route the Transaction Reversal
              Request to the QR partner to reverse the transaction. The QR
              partner will route the Transaction Reversal Request to the
              acquirer and notify the customer that the transaction has been
              reversed.
            </li>
            <li style={{ listStyleType: "circle" }}>
              The QR partner will send a Transaction Reversal Response to TJ 
              to indicate that the reversal has been received. If no response 
              is received, TJ will send a repeat reversal request to the QR partner 
              for a configured number of seconds until either a response is received 
              or the configured number of retries have completed.
            </li>
          </ul>
        </ul> */}
        <QRTextAccordion />
      </Grid>

      <Grid item className="tj-grid-container" id="page-api">
        <TJSwagger url={qrclientYaml} />
        <TJSwagger url={qrproviderYaml} />
      </Grid>

      <Grid
        item
        id="additional-resources"
        style={{
          background: "#FFFFFFD9",
          backgroundImage: `url(${AdditionalResourceImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          position: "relative",
        }}
        className="tj-grid-container"
      >
        <h2>Additional Developer Resources</h2>
        <h3 className="light-h3">INTEGRATION ASSISTANCE</h3>

        <p>
          <strong>GENERATION OF REFERENCE</strong>
        </p>
        <p>
          The reference property in the transaction bodies is a unique value
          that identifies the transaction over the full length of the
          authorisation and settlement chains. It is a UUID and follows RFC
          4122.
          <a
            href="https://tools.ietf.org/html/rfc4122"
            target="_blank"
            rel="nofollow"
            style={{
              color: "#034ea2",
              font: "16px Montserrat-Regular",
              textDecorationLine: "underline",
            }}
          >
            (https://tools.ietf.org/html/rfc4122)
          </a>
        </p>
        <p>
          <strong>JAVA GENERATION</strong>
        </p>
        <p>
          The UUID class in the Java SDK makes it easy to generate a UUID value
          for the transactionIdentifier property that makes all calls to this
          API.
          <br />
          <strong>An example follows:</strong>
        </p>
        <div className="code-box-container">
          <div className="body-container">
            <div className="code-container">
              <code>
                import java.util.UUID;
                <br />
                public class GenerateReference {`{`}
                <br />
                &nbsp;&nbsp;public static final void main(String[] args) {`{`}
                <br />
                &nbsp;&nbsp;UUID ref = UUID.randomUUID(); {`}`}
                <br />
                {`}`}
              </code>
            </div>
          </div>
        </div>

        <p>
          <strong>C# GENERATION</strong>
        </p>
        <p>
          An example program generating a GUID follows. Essentially, a GUID and
          UUID are the same concept.
        </p>
        <div className="code-box-container">
          <div className="body-container">
            <div className="code-container">
              <code>
                using System;
                <br />
                namespace uuid
                <br />
                &nbsp;&nbsp;{`{`} class Program
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`{`}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;static
                void Main(string[] args)
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {`{`}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Guid
                g;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;g
                = Guid.NewGuid();
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Console.WriteLine(g);
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {`}`}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {`}`}
                <br />
                &nbsp;&nbsp;&nbsp;{`}`}
              </code>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default QRCodePage;
