const vasCancelPurchase = {
  request: {},

  response: {
    responseCode: "00",
    responseText: "Approved",
  },
};

export default vasCancelPurchase;
