const qrCodeGetInfo = {
  request: {
    UTI: "2758d9e0-0633-4e34-ac1e-41e86e6705cc",
    cardAcceptorId: "123456789123456",
    STAN: "913062",
    terminalId: "AC123401",
  },

  response: {
    localTransactionDateTime: "2022-07-13T10:01:25.26+02:00",
    retrievalReferenceNumber: "007916224931",
    transactionType: "PURCHASE",
    amount: 10000,
    tipAmount: 2000,
    cashAmount: null,
    currencyCode: 710,
    cardAcceptor: {
      cardAcceptorId: "123456789123456",
      cardAcceptorName: "Acme Traders Small Str ",
      cardAcceptorCity: "Cape Town",
      cardAcceptorRegion: "WC",
      cardAcceptorCountry: "ZA",
    },
    terminalId: "AC123401",
    responseCode: "00",
    responseText: "Approved",
    STAN: 913062,
    UTI: "2758d9e0-0633-4e34-ac1e-41e86e6705cc",
  },
};

export default qrCodeGetInfo;
