import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import faq from '../content/FAQ/content';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<i class="far fa-arrow-circle-down"></i>}
    {...props}
  />
))(({ theme }) => ({
  marginLeft: 0,
  justifyContent: 'flex-start',
  border: 'none',
  padding: 0,
  alignItems: 'center',
  fontFamily: 'Montserrat-Regular',
  textTransform: 'uppercase',
  color: '#00C1FF',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: '#00C1FF',
    fontSize: 20,
    marginTop: -3,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(0),
    padding: 0,
    flex: 'unset',
    marginRight: 10,
    fontFamily: 'Montserrat-Regular',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
}));

export default function FaqAccordion() {
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {faq.questions.map((content, index) => {
        return (
          <Accordion
            expanded={expanded === `panel1${index}`}
            onChange={handleChange(`panel1${index}`)}
          >
            <AccordionSummary
              aria-controls="panelad-content"
              id="panel1d-header"
            >
              <h4>{content.term}</h4>
            </AccordionSummary>
            <AccordionDetails>
              <p>{content.description}</p>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
