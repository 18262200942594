import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { tableIcons } from "../helpers/tableHelpers";
import junctionPOSData from "../content/Junction@POS/content";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<i class="fas fa-arrow-circle-down"></i>}
    {...props}
  />
))(({ theme }) => ({
  marginLeft: 0,
  justifyContent: "flex-start",
  border: "none",
  padding: 0,
  alignItems: "center",
  fontFamily: "Montserrat-Regular",
  color: "#00C1FF",
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "#00C1FF",
    fontSize: 20,
    marginTop: -3,
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(0),
    padding: 0,
    flex: "unset",
    marginRight: 10,
    fontFamily: "Montserrat-Regular",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
}));

export default function TJReceiptTextAccordion() {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panelad-content" id="panel1d-header">
          <h4>&lt;&lt;POSDate&gt;&gt; and &lt;&lt;POSTime&gt;&gt; </h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            The fields should be populated with the local POS date and time. The
            values should be printed in the following formats:
          </p>
          <ul>
            <li>&lt;&lt;POSDate&gt;&gt; = CCYYMMDD</li>
            <li>&lt;&lt;POSTime&gt;&gt; = HHMMSS</li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <h4>&lt;&lt;Receipt&gt;&gt; </h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            Receipt contains text lines with attributes indicating the order of
            the lines.
          </p>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <h4>&lt;&lt;SignatureReq&gt;&gt; </h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            SignatureReq contains either a “T” or “F” for true and false. When
            the value is “T” then the signature line must be printed and when
            the value is “F” it is not required.
          </p>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <h4>&lt;&lt;TransactionOutcome&gt;&gt; </h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            The transaction outcome is obtained by mapping the response code in
            the transaction response to a description. The list of response
            codes with their descriptions which can be used to map to the
            transaction outcome is provided above. The description is also
            provided in the response_text field of the response however
            consideration of displaying messages such as “Stolen card” or “Not
            Sufficient funds” should be taken, as these might cause unwanted
            reaction at the POS.
          </p>
          <p>At a minimum the following mappings should apply:</p>
          <Grid item md={6}>
            <div className="base-table">
              <MaterialTable
                style={{ background: "transparent", boxShadow: "none" }}
                icons={tableIcons}
                columns={[
                  {
                    title: "Response Code",
                    field: "code",
                    type: "numeric",
                    align: "left",
                  },
                  {
                    title: "Description",
                    field: "message",
                  },
                ]}
                options={{
                  toolbar: false,
                  thirdSortClick: false,
                  draggable: false,
                  paging: false,
                  sorting: false,
                  headerStyle: {
                    background: "#EAEAF1",
                    border: "none",
                    color: "#000",
                    fontWeight: 600,
                    fontFamily: "montserrat-bold",
                    textTransform: "uppercase",
                  },

                  // cellStyle: { color: "#fff" },
                }}
                data={junctionPOSData.receiptTable}
                title=""
              />
            </div>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
