const authorize = {
  request: {
    merchantId: "f3d69c2f-38c9-43eb-9946-809bd9a5ff04",
    profileId: "fb9aca6d-3614-4cd1-b1dc-8e4b5056c7a7",
    transactionId: "{{transactionId}}",
    card: {
      pan: "5163426869252246",
      expiryDate: "2210",
      cvv: "178",
    },
  },

  response: {
    transactionId: "5d004f00-35c9-46aa-a3c3-ed6be0c8e0c8",
    transactionStatus: "PAYMENT_AUTHORISED",
    iso8583ResponseCode: "00",
    iso8583ResponseCodeMeaning: "Approved or completed successfully",
    responseText: "Approved",
    authCode: null,
  },
};

export default authorize;
