const digitalVoucher = {
  request: {
    reference: "8c1b0d6b-a7b1-47aa-afab-d56a5c413f82",
    amount: 500,
    chain: "Test",
    routeID: "1004030808",
    pos: "57290093",
    voucher_reference: "600969907001",
    voucher_type: "posa",
    serial_number: "8891096300034354876",
    store: "RESTSIM00000001",
    tran: "DIGITAL VOUCHER",
  },

  response: {
    tran: "DIGITAL VOUCHER",
    response_code: "00",
    response_text: "Approved",
    amount: 500,
    reference: "5dda8040-8790-45ec-afe6-4c8c00d36e8d",
    serial_number: "8891096300034354876",
    voucher_reference: "600969907001",
    voucher_pin: "pin",
    balance: "500",
    product_id: 600969907001,
    transaction_unique_identifier: "000000169FDB86824897680124",
    product_type: "C",
    product_status: "Active",
    price_to_customer: 500,
    loaded_amount: 500,
    pos: "57290093",
    store: "RESTSIM00000001",
    receipt: [],
    data: [],
  },
};

export default digitalVoucher;
