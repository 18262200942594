const reverse = {
  request: {
    merchantId: "f3d69c2f-38c9-43eb-9946-809bd9a5ff04",
    profileId: "fb9aca6d-3614-4cd1-b1dc-8e4b5056c7a7",
    transactionId: "{{transactionId}}",
  },

  response: {
    transactionId: "762af4c9-bd1c-4cf9-a76b-4b7068bec5bd",
    transactionStatus: "PAYMENT_REVERSED",
    iso8583ResponseCode: "00",
    iso8583ResponseCodeMeaning: "Approved or completed successfully",
    responseText: "Approved",
  },
};

export default reverse;
