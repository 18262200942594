const cashWithdrawl = {
  request: {
    amount: 5000,
    store: "RESTSIM00000001",
    pos: "57290093",
    chain: "Test",
    cash: 5000,
    reference: "ad613415-0593-42d5-a3ae-add8a3937f0f",
  },

  response: {
    response_code: "00",
    response_text: "Approved",
    authID: "975014",
    amount: 5000,
    reference: "ad613415-0593-42d5-a3ae-add8a3937f0f",
    pos: "57290093",
    store: "RESTSIM00000001",
    tran: "CASH WITHDRAWAL",
    rrn: "000003190060",
    data: [],
    card: "476173******0010",
    card_name: "VISA CTLS",
    seq: "000018",
    sign: false,
    cash: 5000,
    ext_payment: "00",
    acc_type: "Credit",
    card_entry: "I",
    cryptogram_type: "TC",
    cryptogram: "4AD8878D11",
    tvr: "0000000000",
    aid: "A0000000041010",
    tsi: "F800",
    cvm_results: "440302",
    iad: "0810A74213023000000000000000000000FF",
    pin_statement: "Pin Verified",
    receipt: [],
  },
};

export default cashWithdrawl;
