import React from 'react';

import { Grid } from '@material-ui/core';

import TJSwagger from '../components/TJSwagger';
import image from '../static/images/Prompts and Notifications API Portal.png';

// APIs
import prompts_notificationsYAMl from '../yaml/prompts_notifications.yaml';

// Layout helpers

const PromptsAndNotifications = () => {
  return (
    <Grid container spacing={0}>
      <Grid container className="tj-grid-container" id="overview">
        <h2>Overview</h2>
        <p>
          The prompts and notifications feature provides a mechanism for
          Junction@POS to notify the POS when certain events occur or to request
          information back from the cashier. To enable the feature, the POS will
          register for the prompts and notifications relevant to the specific
          business flow required.
        </p>
        <p>example:</p>
        <p>
          If the scenario requires the cashier to be notified when the card can
          be removed from the device, the POS could register for the
          PROMPT_REMOVE_CARD notification. This will result in the POS receiving
          a message saying that the card can be removed allowing the cashier to
          remind the customer to take their card. If there is a requirement to
          support extended payment options (budget) the POS could register for
          the IsExtendedPaymentRequired prompt so that the cashier can
          facilitate this.
        </p>
        <div
          style={{
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img src={image} />
          </div>
        </div>
      </Grid>
      <Grid item className="tj-grid-container" id="page-api">
        <TJSwagger url={prompts_notificationsYAMl} />
      </Grid>
    </Grid>
  );
};

export default PromptsAndNotifications;
