import React from 'react';

import { Grid } from '@material-ui/core';
import TJSwagger from '../components/TJSwagger';
import MaterialTable from 'material-table';
import PaymentGatewayData from '../content/PaymentGateway/content';

// APIs
import eCommerceDirect from '../yaml/IPG/EcomGateway-Direct.yaml';
import cardNotPresentYAML from '../yaml/IPG/CNP-DirectIntergration.yaml';
import threeDSecureYAML from '../yaml/IPG/ThreeDSecure.yaml';
import oAuthYAML from '../yaml/IPG/oAuth.yaml';

// Layout helpers
import styled, { keyframes } from 'styled-components';
import { fadeInUp, fadeInLeft } from 'react-animations';
import IPGTabs from '../components/IPGTabs';
import IPGcurl from '../components/IPGcurl';
import { tableIcons } from '../helpers/tableHelpers';

// Images
import jPosimg from '../static/images/jpospmain.png';
import AdditionalResourceImg from '../static/images/additional-resources-bg.png';
import Diagram from '../static/images/OAuthDiagram (002).png';

const fadeInUpAnimation = keyframes`${fadeInUp}`;
const fadeInLeftAnimation = keyframes`${fadeInLeft}`;
// const fadeInRightAnimation = keyframes`${fadeInRight}`;

const AnimationContainer = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const AnimationContainerMobile = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    display: block;
  }
  @media screen and (min-width: 960px) {
    display: none;
  }
`;

const FadeInUp = styled.div`
  top: 35px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: -40px;
  text-align: center;
  z-index: 1;
  position: absolute;
  animation: 1s ${fadeInUpAnimation};
  width: 345px;

  @media screen and (min-width: 1025px) {
    width: 400px;
  }
  img {
    width: 300px;
    @media screen and (min-width: 600px) {
      width: 345px;
    }
    @media screen and (min-width: 1025px) {
      width: 400px;
    }
  }
`;

const FadeInLeft = styled.div`
  animation: 0.5s ${fadeInLeftAnimation};
  margin-top: 15px;
  width: 300px;
  height: 300px;
  border-radius: 15px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(0, 130, 217, 1) 58%,
    rgba(0, 193, 255, 1) 93%
  );
  @media screen and (min-width: 600px) {
    width: 345px;
    height: 345px;
  }
  @media screen and (min-width: 1025px) {
    width: 400px;
    height: 400px;
  }
`;

const DirectIntergration = () => {
  return (
    <Grid container spacing={0}>
      <Grid container className="tj-grid-container" id="overview">
        <Grid xs={12} md={6} item>
          <AnimationContainerMobile>
            <FadeInUp>
              <img src={jPosimg} alt="Junction@POS" />
            </FadeInUp>
            <FadeInLeft />
          </AnimationContainerMobile>

          <h2>Overview (required)</h2>

          <p>
            Say something here about HPP intergration for CNP transactions where
            TJ will be used to securely capture card deets.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <div className="hr-container">
            <hr className="small-hr" />
          </div>
        </Grid>
        <Grid
          xs={6}
          md={6}
          item
          style={{
            position: 'relative',
          }}
        >
          <AnimationContainer>
            <FadeInUp>
              <img src={jPosimg} alt="Junction@POS" />
            </FadeInUp>
            <FadeInLeft />
          </AnimationContainer>
        </Grid>
        <Grid xs={12} item>
          <h4 className="standalone-h4">Prerequisite (Optional)</h4>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </Grid>
      </Grid>
      <Grid
        item
        style={{
          background: '#00C1FF1A',
        }}
        className="tj-grid-container"
        id="authentication"
      >
        <h2>Authentication</h2>
        <h3>What is OAuth?</h3>
        <p>
          When people talk about OAuth, they typically mean OAuth 2.0—an
          authorization framework that describes how unrelated services can
          grant access to resources. It’s an open standard used by apps, APIs,
          and other services over HTTPS.
        </p>
        <p>
          OAuth decouples authentication from authorization, by relying on a
          third party to grant an access token. Doing this reduces your attack
          surface since your client secret is not required to access certain
          resources. The Authorization Server authenticates a user and approves
          their access to a resource by providing a temporary authorization
          code. A token can then be requested using your credentials along with
          this authorization code.
        </p>
        <p>
          <b>
            You would need a merchantId and profileId for this to work, which
            you could obtain from TJ.
          </b>
        </p>
        <br />
        <div style={{ display: 'flex', justifyContent: 'center', margin: 20 }}>
          <img src={Diagram} style={{ borderRadius: 20 }} />
        </div>
        <MaterialTable
          style={{
            background:
              'transparent linear-gradient(270deg, #84a3b9 0%, #a3c0c9 100%) 0% 0% no-repeat padding-box',
            boxShadow: 'none',
            color: 'black',
          }}
          icons={tableIcons}
          columns={[
            {
              title: 'No.',
              field: 'number',
              type: 'numeric',
              align: 'left',
              headerStyle: {
                width: '20%',
              },
              cellStyle: {
                color: '#fff',
                width: '20%',
              },
            },

            {
              title: 'Description',
              field: 'description',
              cellStyle: {
                width: '60%',
                color: '#fff',
              },
              headerStyle: {
                width: '60%',
              },
            },
          ]}
          options={{
            toolbar: false,
            thirdSortClick: false,
            draggable: false,
            paging: false,
            headerStyle: {
              background: 'transparent',
              border: 'none',
              color: '#fff',
              fontFamily: 'montserrat-bold',
            },
          }}
          data={PaymentGatewayData.DirectIntergration.descriptions}
          title=""
        />
        <TJSwagger url={oAuthYAML} />
      </Grid>
      <Grid
        item
        xs={12}
        id="make-payment"
        className="tj-grid-container"
        style={{
          background: '#C8C8D21A',
        }}
      >
        <h2 style={{ color: '#000' }}>Make Payment</h2>

        <p style={{ color: '#000' }}>
          To use Transaction Junction’s secure payment page, you will call our
          API to create a session with the TJ platform and retrieve a payment
          URL. You will then redirect your user to our payment URL where they
          will select a payment method and enter sensitive payment information
          to process a payment. Once the payment has been processed, TJ will
          redirect back to a URL which you define, and include some key
          parameters in the URL. We will also send a webhook notification to an
          endpoint you define.
        </p>
        <p style={{ color: '#000' }}>
          The redirect URL parameters can be used to drive the frontend flow,
          however the decision to release the product or service to the customer
          based on a successful payment must be made using the payment result in
          the webhook notification – this prevents possible fraud.
        </p>
        <p style={{ color: '#000' }}>
          The secure payment page can be customised with elements such as logo
          and colours via the merchant portal – you will be provided with access
          to the merchant portal when an integration is initiated.
        </p>
        <p style={{ color: '#000' }}>
          You would need to call Payment-Intent if you are concerned about a
          duplicates, otherwise, you would only need to call Session.
        </p>
        <p>
          An example can be found in the{' '}
          <a href="#additional-resources" className="link">
            Additional Resources
          </a>{' '}
          section.
        </p>
        <TJSwagger url={eCommerceDirect} />
        <h2>Payment Intent</h2>
        <MaterialTable
          style={{
            background:
              'transparent linear-gradient(270deg, #84a3b9 0%, #a3c0c9 100%) 0% 0% no-repeat padding-box',
            boxShadow: 'none',
            color: 'black',
          }}
          icons={tableIcons}
          columns={[
            {
              title: 'Response Code',
              field: 'code',
              type: 'numeric',
              align: 'left',
              headerStyle: {
                width: '20%',
              },
              cellStyle: {
                color: '#fff',
                width: '20%',
              },
            },

            {
              title: 'Description',
              field: 'message',
              cellStyle: {
                width: '60%',
                color: '#fff',
              },
              headerStyle: {
                width: '60%',
              },
            },
          ]}
          options={{
            toolbar: false,
            thirdSortClick: false,
            draggable: false,
            paging: false,
            headerStyle: {
              background: 'transparent',
              border: 'none',
              color: '#fff',
              fontFamily: 'montserrat-bold',
            },
          }}
          data={PaymentGatewayData.DirectIntergration.responseCodes.Ecomm}
          title=""
        />
        <h2>Transaction</h2>
        <MaterialTable
          style={{
            background:
              'transparent linear-gradient(270deg, #84a3b9 0%, #a3c0c9 100%) 0% 0% no-repeat padding-box',
            boxShadow: 'none',
            color: 'black',
          }}
          icons={tableIcons}
          columns={[
            {
              title: 'Response Code',
              field: 'code',
              type: 'numeric',
              align: 'left',
              headerStyle: {
                width: '20%',
              },
              cellStyle: {
                color: '#fff',
                width: '20%',
              },
            },

            {
              title: 'Description',
              field: 'message',
              cellStyle: {
                width: '60%',
                color: '#fff',
              },
              headerStyle: {
                width: '60%',
              },
            },
          ]}
          options={{
            toolbar: false,
            thirdSortClick: false,
            draggable: false,
            paging: false,
            headerStyle: {
              background: 'transparent',
              border: 'none',
              color: '#fff',
              fontFamily: 'montserrat-bold',
            },
          }}
          data={PaymentGatewayData.DirectIntergration.responseCodes.Ecomm}
          title=""
        />
      </Grid>
      <Grid
        id="three-d-secure"
        style={{
          background: '#00C1FF1A',
        }}
        item
        className="tj-grid-container"
      >
        <TJSwagger url={threeDSecureYAML} />
        <h2>ThreeDSLookUp</h2>
        <MaterialTable
          style={{
            background:
              'transparent linear-gradient(270deg, #84a3b9 0%, #a3c0c9 100%) 0% 0% no-repeat padding-box',
            boxShadow: 'none',
            color: 'black',
          }}
          icons={tableIcons}
          columns={[
            {
              title: 'Session Code',
              field: 'code',
              type: 'numeric',
              align: 'left',
              headerStyle: {
                width: '20%',
              },
              cellStyle: {
                color: '#fff',
                width: '20%',
              },
            },

            {
              title: 'Description',
              field: 'message',
              cellStyle: {
                width: '60%',
                color: '#fff',
              },
              headerStyle: {
                width: '60%',
              },
            },
          ]}
          options={{
            toolbar: false,
            thirdSortClick: false,
            draggable: false,
            paging: false,
            headerStyle: {
              background: 'transparent',
              border: 'none',
              color: '#fff',
              fontFamily: 'montserrat-bold',
            },
          }}
          data={
            PaymentGatewayData.DirectIntergration.responseCodes.ThreeDSLookUp
          }
          title=""
        />
        <h2>ThreeDSValidation</h2>
        <MaterialTable
          style={{
            background:
              'transparent linear-gradient(270deg, #84a3b9 0%, #a3c0c9 100%) 0% 0% no-repeat padding-box',
            boxShadow: 'none',
            color: 'black',
          }}
          icons={tableIcons}
          columns={[
            {
              title: 'Session Code',
              field: 'code',
              type: 'numeric',
              align: 'left',
              headerStyle: {
                width: '20%',
              },
              cellStyle: {
                color: '#fff',
                width: '20%',
              },
            },

            {
              title: 'Description',
              field: 'message',
              cellStyle: {
                width: '60%',
                color: '#fff',
              },
              headerStyle: {
                width: '60%',
              },
            },
          ]}
          options={{
            toolbar: false,
            thirdSortClick: false,
            draggable: false,
            paging: false,
            headerStyle: {
              background: 'transparent',
              border: 'none',
              color: '#fff',
              fontFamily: 'montserrat-bold',
            },
          }}
          data={
            PaymentGatewayData.DirectIntergration.responseCodes
              .ThreeDSValidation
          }
          title=""
        />
      </Grid>
      <Grid
        id="finalise-payment"
        style={{
          background: '#C8C8D21A',
        }}
        item
        className="tj-grid-container"
      >
        <h2>Finalise Payment</h2>
        <p>
          By default, TJ automatically settles the transaction. This means that
          a single payment initiation is required to complete the payment
          process. Sometimes, when the use case requires it, payments should be
          authorised when the user makes a payment, but finalised at a later
          stage, for example, when goods have been delivered.
        </p>
        <p>
          If you need to split the authorisation and the settlement into 2
          separate events, you will use either of the below two calls to
          finalise an authorised payment.
        </p>
        <p>
          Note that both actions can be performed against authorised
          transactions via the TJ merchant portal.
        </p>
        <ul>
          <li>
            Auto Settle - chat to TJ to make sure this option is configured.
          </li>
          <li> Via Merchant portal - No further integration to be done</li>
          <li>
            Via API calls use CNP calls to settle or reverse, and refund if need
            be. Note you can also use the Merchant Portal.
          </li>
        </ul>
        <p>
          <h1 style={{ color: '#000' }}>Process a Refund</h1>
        </p>

        <p>
          Calling the refund API will process a refund back to the card that
          performed the original payment. A refund can be partial, as long as
          the refund value plus any previous refund values does not exceed the
          value of the original payment.
        </p>
        <p>
          Please note for card refunds: while a refund transaction is processed
          in real time, the actual funds will be returned to the cardholder’s
          account after the issuing bank has concluded a process on their side
          which can take 2 days to 3 weeks.
        </p>
        <p>
          An example can be found in the{' '}
          <a href="#additional-resources" className="link">
            Additional Resources
          </a>{' '}
          section.
        </p>
        <TJSwagger url={cardNotPresentYAML} />
      </Grid>
      <Grid
        item
        style={{
          background: '#00C1FF1A',
        }}
        className="tj-grid-container"
        id="webhook"
      >
        <h2>Webhook</h2>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </Grid>
      <Grid
        item
        id="additional-resources"
        style={{
          background: '#FFFFFFD9',
          backgroundImage: `url(${AdditionalResourceImg})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
          backgroundAttachment: 'fixed',
        }}
        className="tj-grid-container"
      >
        <h2>Additional Resources (optional)</h2>
        <h3 className="light-h3">INTEGRATION ASSISTANCE</h3>
        <IPGTabs data={PaymentGatewayData.DirectIntergration} />
        <p>
          <strong>C# GENERATION</strong>
        </p>
        <p>
          An example program generating a GUID follows. Essentially, a GUID and
          UUID are the same concept.
        </p>
        <IPGcurl />
      </Grid>
    </Grid>
  );
};

export default DirectIntergration;
