import React, { useState } from 'react';

// React Router
import { useHistory } from 'react-router-dom';

// Base Layout Components
import { TJPageHeader, TJCTACard } from 'tj-components';
import Grid from '@mui/material/Grid';

// Data import
import apis from '../helpers/apis';

const Home = () => {
  const history = useHistory();

  const currentApi = apis.filter(
    (api) =>
      api.name !== 'Direct Intergration' &&
      api.name !== 'Hosted Payment Page' &&
      api.name !== 'Transactions (current REST API page)' &&
      api.name !== 'Pay@Table' &&
      api.name !== 'Prompts & Notifications' &&
      api.name !== '3rd Party Use Cases' &&
      api.name !== 'FAQ'
  );
  return (
    <Grid container spacing={0} xs={12}>
      <TJPageHeader title="API Portal" />
      <div className="tj-main">
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          className="container selection-cards"
        >
          {/*.slice(0, 6)*/}
          {currentApi.map((api, index) => {
            return (
              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                lx={4}
                key={index}
                style={{ display: 'flex' }}
                onClick={() =>
                  history.push({
                    pathname: `/${api.slug}`,
                  })
                }
              >
                <TJCTACard
                  width="100%"
                  title={api.name}
                  height="auto"
                  minWidth="150px"
                  maxWidth="auto"
                  description={api.excerpt}
                  iconClass={api.icon}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Grid>
  );
};

export default Home;
