const refund = {
  request: {
    merchantId: "f3d69c2f-38c9-43eb-9946-809bd9a5ff04",
    profileId: "fb9aca6d-3614-4cd1-b1dc-8e4b5056c7a7",
    transactionId: "{{transactionId}}",
    amount: 150,
    currencyCode: "ZAR",
  },

  response: {
    transactionId: "d3c9eb65-e3b7-48e4-bdf2-30250def7b25",
    transactionStatus: "PAYMENT_PARTIALLY_REFUNDED",
    iso8583ResponseCode: "00",
    iso8583ResponseCodeMeaning: "Approved or completed successfully",
    responseText: "Approved",
  },
};

export default refund;
