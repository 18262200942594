export const cardPaymentKeyExchange = {
  request: {
    header: {
      messageFunction: "REQU",
      exchangeIdentification: "10e7fc2c-65e5-41c1-bf3e-39ab87df30c8",
    },
    body: {
      transaction: {
        keyExchangeFunction: "RQKY",
        keyExchangeTransactionIdentification: {
          systemTraceAuditNumber: "831706",
          transmissionDataTime: "2022-08-18T13:04:50.291Z",
          retrievalReferenceNumber: "223015045028",
        },
        keyExchangeType: "PTKA",
      },
    },
  },
  response: {
    header: {
      messageFunction: "REQU",
      exchangeIdentification: "10e7fc2c-65e5-41c1-bf3e-39ab87df30c8",
    },
    body: {
      transaction: {
        keyExchangeFunction: "RQKY",
        keyExchangeTransactionIdentification: {
          systemTraceAuditNumber: "831706",
          transmissionDataTime: "2022-08-18T13:04:50.291+00:00",
          retrievalReferenceNumber: "223015045028",
        },
        keyExchangeType: "PTKA",
        keyExchangeData: {
          encryptedData:
            "A0072P0TN00S0000419FA4B6B952369B8355D0D0AE8649FB42846F6A61620AC28161F31A",
          keyCheckSumValue: "5979B9",
        },
      },
      processingResult: {
        result: "PRCS",
      },
    },
  },
};
