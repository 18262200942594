const qrCodeTransNotify = {
  request: {
    UTI: "66f0dd40-d375-428a-a25d-64399dcfc884",
    localTransactionDateTime: "2022-07-13T10:01:25.545+02:00",
    retrievalReferenceNumber: "007916224931",
    STAN: 913063,
    transactionType: "PURCHASE",
    amount: 10000,
    tipAmount: 2000,
    tipAmountQRP: 2000,
    cashAmount: 5000,
    currencyCode: 710,
    cardAcceptor: {
      cardAcceptorId: "123456789123456",
      cardAcceptorName: "Acme Traders Small Str",
      cardAcceptorCity: "Cape Town",
      cardAcceptorRegion: "WC",
      cardAcceptorCountry: "ZA",
    },
    terminalId: "AC123401",
    responseCode: "00",
    responseText: "APPROVED",
    authId: "A12345",
    qrPartner: "Any_QR_Partner",
    paymentMethod: "CARD",
    maskedPan: "419410*******243",
  },

  response: {
    responseCode: "00",
    responseText: "Approved",
    UTI: "66f0dd40-d375-428a-a25d-64399dcfc884",
  },
};

export default qrCodeTransNotify;
