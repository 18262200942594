const voucher = {
  request: {
    amount: 500,
    reference: "f40030cd-c3af-4aee-a2e2-09c080fbf499",
    pos: "57290093",
    tran: "VOUCHER",
    store: "RESTSIM00000001",
    productID: "6009681804124",
    routeID: "d94f178c-4db0-4673-be8e-5ed7c08f1c17",
    chain: "Test",
  },

  response: {
    authID: "977880",
    tran: "VOUCHER",
    response_code: "00",
    response_text: "Approved",
    amount: 500,
    reference: "77f389e4-5718-4640-9435-7ddb3deee0b8",
    pos: "57290093",
    store: "RESTSIM00000001",
    data: [],
    voucher_pin: "12345",
    voucher_serial_number: "0987654321",
    product_name: "Airtime",
    network_name: "MTN",
    cust_message: "Use the Force",
  },
};

export default voucher;
