import api from "../helpers/api";
import store from "../helpers/store";
import {
  FETCH_USER_INFO,
  RECEIVED_USER_INFO,
  FETCH_USER_INFO_ERROR,
} from "./actionTypes";

export const fetch_user_info = () => {
  return {
    type: FETCH_USER_INFO,
  };
};

export const receive_user_info = (userInfo) => {
  return {
    type: RECEIVED_USER_INFO,
    data: userInfo,
  };
};

export const receive_user_info_error = () => {
  return {
    type: FETCH_USER_INFO_ERROR,
  };
};

export const thunk_fetch_user_info = (userInfo) => {
  store.dispatch(fetch_user_info());

  return async (dispatch) => {
    try {
      dispatch(receive_user_info(userInfo));
    } catch (error) {
      dispatch(receive_user_info_error());
    }
  };
};

export const updatePassword = (password, callback) => {
  api
    .put(`/update?value=${password}`)
    .then((response) => {
      callback(response);
    })
    .catch((error) => {});
};

export const logout = (callback) => {
  api
    .get(`/logout`)
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error.response);
    });
};
