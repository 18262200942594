import React from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background:
      "transparent linear-gradient(90deg, #EE236B 0%, #0254A8 25%, #0075CB 51%, #00C1FF 78%, #08F9BA 100%) 0% 0% no-repeat padding-box",
    minHeight: "200px",
    padding: "0 7%",
    [theme.breakpoints.up("xl")]: {
      padding: "5rem 10%",
    },
    color: "#fff",
    fontSize: 35,
    [theme.breakpoints.up("sm")]: {
      fontSize: 45,
    },
    fontFamily: "Montserrat-Regular",
    marginTop: 65,
  },
}));

const TJPageHeader = ({ title }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          {title}
        </Grid>
      </Grid>
    </div>
  );
};

export default TJPageHeader;
