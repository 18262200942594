import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { cardPayAuth } from "../transactionData/cardPaymentAPI/cardPaymentAuth";
import { cardPayAuthTrans } from "../transactionData/cardPaymentAPI/cardPaymentAuthtrans";
import { cardPaymentComplSettlement } from "../transactionData/cardPaymentAPI/cardPaymentComplSettlement";
import { cardPaymentComplReverse } from "../transactionData/cardPaymentAPI/cardPaymentComplReverse";
import { cardPaymentKeyExchange } from "../transactionData/cardPaymentAPI/cardPaymentKeyExchange";
import { cardPayComplTranInfo } from "../transactionData/cardPaymentAPI/cardPaymentComplTranInfo";
import StringifyWithFloats from "stringify-with-floats";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<i class="far fa-arrow-circle-down"></i>}
    {...props}
  />
))(({ theme }) => ({
  marginLeft: 0,
  justifyContent: "flex-start",
  border: "none",
  padding: 0,
  alignItems: "center",
  fontFamily: "Montserrat-Regular",
  textTransform: "uppercase",
  color: "#00C1FF",
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "#00C1FF",
    fontSize: 20,
    marginTop: -3,
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(0),
    padding: 0,
    flex: "unset",
    marginRight: 10,
    fontFamily: "Montserrat-Regular",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
}));

export default function TJTextAccordion() {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const stringify = StringifyWithFloats({
    totalAmount: "float",
    amount: "float",
  });

  return (
    <div>
      <p style={{ color: "#000" }}>
        The operations available on the API are given below.
      </p>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panelad-content" id="panel1d-header">
          <h4>Authorisation (Auth)</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This authorisation operation accepts payment authorisation messages.
            A Completion (SETTLE) message is required to confirm the
            transaction. A Completion (REVERSE) can be used to reverse the
            transaction.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>{stringify(cardPayAuth.request, null, 2)}</pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>{stringify(cardPayAuth.response, null, 2)}</pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <h4>Authorisation (Tran)</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This authorisation operation accepts payment transaction messages. A
            Completion (SETTLE) message is not required to confirm the
            transaction. A Completion (REVERSE) can be used to reverse the
            transaction.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>{stringify(cardPayAuthTrans.request, null, 2)}</pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>{stringify(cardPayAuthTrans.response, null, 2)}</pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <h4>Completion (Settle)</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            The completion operation accepts follow-up messages to settle a
            previously approved Authorisation (AUTH).
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>
                        {stringify(cardPaymentComplSettlement.request, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {stringify(
                          cardPaymentComplSettlement.response,
                          null,
                          2
                        )}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <h4>Completion (Reverse)</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            The completion operation accepts follow-up messages to reverse a
            previously approved Authorisation (AUTH) or Authorisation (SETTLE).
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>
                        {stringify(cardPaymentComplReverse.request, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {stringify(cardPaymentComplReverse.response, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <h4>Completion (Tran Info)</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            The completion operation accepts follow-up messages to confirm or
            reverse a transaction with the second cryptogram created during the
            transaction performed on a chip card.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>
                        {stringify(cardPayComplTranInfo.request, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {stringify(cardPayComplTranInfo.response, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <h4>Key exchange</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            The key exchange operation allows clients to request a key for
            message encryption.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div s>
                      <pre>
                        {stringify(cardPaymentKeyExchange.request, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre style={{ whiteSpace: "break-spaces" }}>
                        {stringify(cardPaymentKeyExchange.response, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
