const vasPurchaseAirtimeTopup = {
  request: {
    tranRef: "1a5fe454-14f5-4f5e-8957-123456789abd",
    cardAcceptor: "1234567890     ",
    cardAcceptorTerminal: "12345678",
    currency: "710",
    productId: "123ZA5",
    phoneNumber: "847467416",
    amount: 5555,
  },

  response: {
    date: "2010-06-10 11:31:27",
    provider: "PROVIDER2",
    reference: "827232",
    reprint: false,
    responseCode: "00",
    responseText: "Approved",
  },
};

export default vasPurchaseAirtimeTopup;
