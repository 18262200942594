const FAQData = {
  questions: [
    {
      term: 'Terminal ID',
      description:
        'A unique code identifying the payment terminal where the card was used at the store.',
    },
    {
      term: 'Store Merchant ID',
      description:
        'A unique code identifying the store, this can be the same as the Bank Merchant Code in which case it’s also used in reconciliation and settlement.',
    },
    {
      term: 'Bank Merchant ID',
      description:
        'A unique code identifying the merchant to the bank. Transaction Junction can map an internal Store Merchant ID to a Bank Merchant ID if required.',
    },
    {
      term: 'Card Name',
      description: 'A description of the card product used in the transaction.',
    },
    {
      term: 'Card Number',
      description:
        'A number linking this card to the account holder and issuing bank. Also referred to as the PAN (Primary Account Number). To comply with PCI security rules, this number is masked with asterisks with only the first 6 and last 4 digits visible. E.g., 421315******0123',
    },

    {
      term: 'STAN',
      description:
        'The Systems Trace Audit Number helps to identify a transaction and remains the same for all message legs within a transaction.',
    },
    {
      term: 'RRN',
      description:
        'The Retrieval Reference Number helps to uniquely identify a transaction for a given merchant.',
    },
    {
      term: 'Auth ID',
      description:
        'A code generated by the authorising bank confirming approval.',
    },
    {
      term: 'PAN Entry Mode',
      description:
        'A description of how the PAN was entered at the point of sale e.g., ICC (Integrated Circuit Card – the chip embedded in a bank card), EMV Contactless or Magnetic Swipe.',
    },
    {
      term: 'PIN Statement',
      description:
        'Information on card holder verification used in processing the transaction. For support purposes.',
    },
    {
      term: 'TSI',
      description:
        'Transaction Status Indicator. Provides additional EMV related information for support purposes.',
    },
    {
      term: 'TVR',
      description:
        'Terminal Verification Results. Information used by the PED in deciding whether to decline, approve offline or to go online and printed for support purposes.',
    },
    {
      term: 'Cryptogram Type',
      description:
        'The type of cryptogram generated based on decisions involving the PED and card. Used for support purposes.',
    },
    {
      term: 'APL',
      description: 'Application Label. Used for support purposes.',
    },
    {
      term: 'AID',
      description:
        'The application identifier is used to identify the EMV application from the card that was used in processing the transaction. Used for support purposes.',
    },
    {
      term: 'IAD',
      description:
        'Issuer Application Data. Proprietary data sent online to the issuer when performing a transaction. Used for support purposes.',
    },
    {
      term: 'UTI',
      description:
        'Universal Transaction Identifier. A reference which uniquely identifies a transaction and provided when querying a transaction.',
    },
  ],
};

export default FAQData;
