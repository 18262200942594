import React from "react";

import { Grid } from "@material-ui/core";

import TJSwagger from "../components/TJSwagger";

// APIs
import eCommerceYAML from "../yaml/eCommerceApi.yaml";

// Layout helpers

import styled, { keyframes } from "styled-components";
import { fadeInUp, fadeInLeft, fadeInRight } from "react-animations";
import jPosimg from "../static/images/jpospmain.png";
import jPosLogo from "../static/images/jposlogo.png";
import AdditionalResourceImg from "../static/images/additional-resources-bg.png";

const fadeInUpAnimation = keyframes`${fadeInUp}`;
const fadeInRightAnimation = keyframes`${fadeInRight}`;
const fadeInLeftAnimation = keyframes`${fadeInLeft}`;

const AnimationContainer = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const AnimationContainerMobile = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    display: block;
  }
  @media screen and (min-width: 960px) {
    display: none;
  }
`;

const FadeInUp = styled.div`
  top: 35px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: -40px;
  text-align: center;
  z-index: 1;
  position: absolute;
  animation: 1s ${fadeInUpAnimation};
  width: 345px;

  @media screen and (min-width: 1025px) {
    width: 400px;
  }
  img {
    width: 300px;
    @media screen and (min-width: 600px) {
      width: 345px;
    }
    @media screen and (min-width: 1025px) {
      width: 400px;
    }
  }
`;

const FadeInRight = styled.div`
  bottom: 10%;
  right: 10%;
  z-index: 2;
  position: absolute;
  animation: 1s ${fadeInRightAnimation};

  img {
    width: 120px;
    @media screen and (min-width: 600px) {
      width: 150px;
    }
  }
`;

const FadeInLeft = styled.div`
  animation: 0.5s ${fadeInLeftAnimation};
  margin-top: 15px;
  width: 300px;
  height: 300px;
  border-radius: 15px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(0, 130, 217, 1) 58%,
    rgba(0, 193, 255, 1) 93%
  );
  @media screen and (min-width: 600px) {
    width: 345px;
    height: 345px;
  }
  @media screen and (min-width: 1025px) {
    width: 400px;
    height: 400px;
  }
`;

const ECommercePage = () => {
  return (
    <Grid container spacing={0}>
      <Grid container className="tj-grid-container" id="overview">
        <Grid xs={12} md={6} item>
          <AnimationContainerMobile>
            <FadeInUp>
              <img src={jPosimg} alt="Junction@POS" />
              <FadeInRight>
                <img
                  style={{ width: 120 }}
                  src={jPosLogo}
                  alt="Junction@POS Logo"
                />
              </FadeInRight>
            </FadeInUp>
            <FadeInLeft />
          </AnimationContainerMobile>

          <h2>Overview (required)</h2>
          <p>
            <strong>
              Transaction Junction offers a complete integrated payments and
              electronics product procurement application for deployment in
              retail environments.
            </strong>
          </p>
          <p>Description on what Open Payment Api is responsible for</p>
          <p>example:</p>
          <p>
            Junction@POS is a java application responsible for managing
            communication between the POS, supported PIN Entry Devices (PED’s)
            and the payment switch which manages communications with the various
            banks. Junction@POS is supported on Windows with Microsoft SQL
            Express or SQL Server. Ubuntu LTS with SQL Express for Linux will be
            supported in future.
          </p>
          <div className="hr-container">
            <hr className="small-hr" />
          </div>
        </Grid>
        <Grid
          xs={6}
          md={6}
          item
          style={{
            position: "relative",
          }}
        >
          <AnimationContainer>
            <FadeInUp>
              <img src={jPosimg} alt="Junction@POS" />
              <FadeInRight>
                <img
                  style={{ width: 120 }}
                  src={jPosLogo}
                  alt="Junction@POS Logo"
                />
              </FadeInRight>
            </FadeInUp>
            <FadeInLeft />
          </AnimationContainer>
        </Grid>
        <Grid xs={12} item>
          <h4 className="standalone-h4">Prerequisite (Optional)</h4>
          <p>Description should go here</p>
          <p>example:</p>
          <p>
            Please note that while this resource encompasses the entire
            Transaction Junction offering, it is not necessarily required that
            development be done for the entire suite of offerings. The
            integration scope will be determined by the client requirements in
            the form of desired transaction types, value added services,
            additional payment types and recon offerings.
          </p>
        </Grid>
      </Grid>

      <Grid
        item
        style={{
          background:
            "transparent linear-gradient(270deg, #0082D9 0%, #00C1FF 100%) 0% 0% no-repeat padding-box",

          color: "#fff",
          margin: "0 auto",
        }}
        className="tj-grid-container"
        id="implementation-guide"
      >
        <h2>Implementation Guide (required)</h2>
        <p>
          A description which tells the client how to implement Open Payment API
        </p>
        <p>For example</p>
        <p>
          Once a project has been lodged with Transaction Junction, development
          against the API can be performed by the integrator. The API is
          published as a Swagger 2.0 document, implying that much of the
          client-side code can be generated using the Swagger toolset
          encompassing a wide variety of programming languages and application
          frameworks. The Swagger 2.0 YAML document is provided to the
          integrator under the Junction@POS cards transaction API section of
          this page. This should reduce the amount of effort required to
          complete the integration significantly, and is the recommended
          approach, as it will largely eliminate any syntactic issues in the
          exchange of messages between the two parties. Should you require any
          assistance please reach out to posintegrations@switch.tj.
        </p>
      </Grid>
      <Grid
        item
        xs={12}
        id="section-3"
        className="tj-grid-container"
        style={{
          background: "#00C1FF1A",
          color: "#00C1FF",
        }}
      >
        <h2 style={{ color: "#000" }}>Section 3</h2>
        <h2 className="alt-h2">heading</h2>

        <h3 style={{ marginBottom: 0, color: "#000", fontSize: 16 }}>
          paragraph example:
        </h3>
        <p style={{ color: "#000" }}>
          Stores with wireless PED’s can request a list of PED’s that are not
          busy with a transaction. Because the PED’s aren’t physically connected
          to a POS it’s possible that another cashier has a transaction in
          progress. The cashier can physically select a device listed by the
          above request to continue with a transaction.
        </p>
      </Grid>
      <Grid item className="tj-grid-container" id="page-api">
        <TJSwagger url={eCommerceYAML} />
      </Grid>

      <Grid
        id="section-4"
        style={{
          background: "#C8C8D21A",
        }}
        item
        className="tj-grid-container"
      >
        <h2>Section 4</h2>
        <p>example:</p>
        <p>
          The example receipts provided below cover the minimum requirements for
          receipt printing. The receipt template indicates the required headings
          with the associated fields, while the receipt on the right is provided
          as an example of a populated receipt.
        </p>
      </Grid>

      <Grid
        id="section-5"
        item
        xs={12}
        style={{
          background:
            "transparent linear-gradient(270deg, #0082D9 0%, #00C1FF 100%) 0% 0% no-repeat padding-box",
          color: "#fff",
          margin: "0 auto",
        }}
        className="tj-grid-container"
      >
        <h2>Section 5</h2>
        <p>example:</p>
        <p>
          The example receipts provided below cover the minimum requirements for
          receipt printing. The receipt template indicates the required headings
          with the associated fields, while the receipt on the right is provided
          as an example of a populated receipt.
        </p>
      </Grid>
      <Grid
        item
        id="additional-resources"
        style={{
          background: "#FFFFFFD9",
          backgroundImage: `url(${AdditionalResourceImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          position: "relative",
        }}
        className="tj-grid-container"
      >
        <h2>Additional Resources (optional)</h2>
        <h3 className="light-h3">INTEGRATION ASSISTANCE</h3>
        <p>
          <strong>Additonal resource 1</strong>
        </p>
        <p>example:</p>
        <p>
          The example receipts provided below cover the minimum requirements for
          receipt printing. The receipt template indicates the required headings
          with the associated fields, while the receipt on the right is provided
          as an example of a populated receipt.
        </p>
        <p>
          <strong>Additional resource 2</strong>
        </p>
        <p>example:</p>
        <p>
          The example receipts provided below cover the minimum requirements for
          receipt printing. The receipt template indicates the required headings
          with the associated fields, while the receipt on the right is provided
          as an example of a populated receipt.
        </p>
      </Grid>
    </Grid>
  );
};

export default ECommercePage;
