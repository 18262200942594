const paymentIntent = {
  request: {
    merchantId: "f3d69c2f-38c9-43eb-9946-809bd9a5ff04",
    profileId: "fb9aca6d-3614-4cd1-b1dc-8e4b5056c7a7",
    merchantRef: "Demo Test Ref",
  },

  response: {
    paymentIntentId: "9fce35f8-6767-4eeb-b634-3e40ee02d937",
    profileId: "fb9aca6d-3614-4cd1-b1dc-8e4b5056c7a7",
    paymentIntentStatus: {
      id: 0,
      name: "",
      code: "",
    },
    merchantRef: "Demo Test Ref",
    createdAt: "2022-09-30T10:50:35.213+02:00",
    lastModified: "2022-09-30T10:50:35.213+02:00",
  },
};

export default paymentIntent;
