import React from "react";
import styled from "styled-components";

const ReceiptContainer = styled.div`
  width: 350px;
  height: 100%;
  background: #fff;
  margin-right: 6.3vh;
  border: 1px solid #000;
  padding: 10px;
  color: #000;
  display: inline-block;
  font-family: "VT323", monospace;
  position: relative;
  float: left
`;

const Address = styled.div`
  font-size: 12px;
`;

const TransactionDetails = styled.div`
  display: flex;
  margin: 0 10px 10px;
  font-size: 10px;
  justify-content: space-between;
`;

const TransactionDetailsMerge = styled.div`
  display: flex;
  margin: 0 10px 10px;
  font-size: 10px;
  color: #3dbdee;
  justify-content: space-between;
`;

const CenterHeading = styled.div`
  text-align: center;
  padding: 1rem;
`;

const Detail = styled.div`
  width: 50%;
  font-size: 10px;
`;

const PaymentDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
`;

const PaymentDetailsMerged = styled.div`
  display: flex;
  color: #3dbdee;
  justify-content: space-between;
  font-weight: 600;
`;

const Amount = styled.div`
  width: 50%;
  font-size: 10px;
  text-align: right;
`;

const TJReceiptMinApproved = () => {
  return (
    <ReceiptContainer>
        <CenterHeading>
        <h4>&lt;&lt;TranType&gt;&gt;</h4> 
        <h4>Customer/Merchant Copy</h4>
        <h4 style={{ fontSize: 14, fontWeight: "600" }}>
          &lt;&lt;TransactionOutcome&gt;&gt;
        </h4>
        &nbsp;
        <span style={{ fontSize: 14, fontWeight: "600" }}>
          &lt;&lt;response_code&gt;&gt;
        </span>
      </CenterHeading>
      
        <Address style={{display: "flex", justifyContent: "center"}}>Store Name, Store Address</Address>
      
      <TransactionDetails>
        <Detail>&lt;&lt;POSDate&gt;&gt;</Detail>
        <Detail>&lt;&lt;POSTime&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>&lt;&lt;MerchantID&gt;&gt;</Detail>
        <Detail>&lt;&lt;PosID&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>&lt;&lt;Pan&gt;&gt;</Detail>
        <Detail>&lt;&lt;Stan&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>&lt;&lt;CardName&gt;&gt;</Detail>
        <Detail>&lt;&lt;EntryMode&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>&lt;&lt;RetRefNum&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetailsMerge>
        <Detail>&lt;&lt;AuthID&gt;&gt;</Detail>
      </TransactionDetailsMerge>
      <TransactionDetailsMerge>
        <Detail>Bank Merchant ID:</Detail>
        <Detail>&lt;&lt;PosData.Key=BankMID&gt;&gt;</Detail>
      </TransactionDetailsMerge>
      <TransactionDetailsMerge>
        <Detail>&lt;&lt;CryptogramType&gt;&gt;:</Detail>
        <Detail>&lt;&lt;Cryptogram&gt;&gt;</Detail>
      </TransactionDetailsMerge>
      <TransactionDetailsMerge>
        <Detail>&lt;&lt;APL&gt;&gt;</Detail>
        <Detail>&lt;&lt;TVR&gt;&gt;</Detail>
      </TransactionDetailsMerge>
      <TransactionDetailsMerge>
        <Detail>&lt;&lt;AID&gt;&gt;</Detail>
        <Detail>&lt;&lt;TSI&gt;&gt;</Detail>
      </TransactionDetailsMerge>
      <TransactionDetailsMerge>
        <Detail>&lt;&lt;PINStatement&gt;&gt;:</Detail>
        <Detail>&lt;&lt;CVMResults&gt;&gt;</Detail>
      </TransactionDetailsMerge>
      <TransactionDetailsMerge>
        <Detail>IAD:</Detail>
        <Detail style={{position: "relative", right: "30px"}}>&lt;&lt;IAD&gt;&gt;</Detail>
      </TransactionDetailsMerge>
      <TransactionDetails>
        <Detail>UTI:</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail style={{display: "flex", justifyContent: "flex-end"}}>&lt;&lt;UTI&gt;&gt;</Detail>
      </TransactionDetails>
      <CenterHeading>
        <span style={{ color: "#3dbdee" }}>*****************************</span>
        <span style={{ display: "block", color: "#3dbdee" }}>
          &lt;&lt;ReceiptData&gt;&gt;
        </span>
        <span style={{ color: "#3dbdee" }}>*****************************</span>
      </CenterHeading>
      <PaymentDetails>
        <Detail>TOTAL</Detail>
        <Amount>R &lt;&lt;amount&gt;&gt;</Amount>
      </PaymentDetails>
      <PaymentDetailsMerged>
        <Detail>Cash Amount</Detail>
        <Amount>R &lt;&lt;cash&gt;&gt;</Amount>
      </PaymentDetailsMerged>
      <CenterHeading>
      </CenterHeading>
    </ReceiptContainer>
  );
};

export default TJReceiptMinApproved;
