const vasProvTopUpPost = {
  request: {
    productId: "A232z1",
    tranRef: "1a5fe454-14f5-4f5e-8957-123456789abd",
    customerId: "TestRetailer",
    storeId: "00001",
    currency: "710",
    phoneNumber: "0847467416",
    amount: 5000,
  },

  response: {
    responseCode: "00",
    responseText: "Transaction successful",
    reference: "asdf12345",
    productName: "Test Product",
    date: "2025-12-31 23:59:27",
  },
};

export default vasProvTopUpPost;
