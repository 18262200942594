const token = {
  request: {
    tran: "TOKEN",
    amount: 8000,
    reference: "f40030cc-c3af-4aee-a2e2-09c080fbf499",
    pos: "57290093",
    store: "RESTSIM00000001",
    chain: "Test",
  },

  response: {
    authID: "977880",
    tran: "TOKEN",
    response_code: "00",
    response_text: "Approved",
    amount: 8000,
    reference: "f40030cc-c3af-4aee-a2e2-09c080fbf499",
    seq: "000047",
    rrn: "000047241042",
    pos: "57290093",
    store: "RESTSIM00000001",
    receipt: [],
    data: [],
  },
};

export default token;
