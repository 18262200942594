const junctionPOSData = {
  responseCodes: [
    {
      code: "00",
      status: "Approved",
      message: "Approved",
    },
    {
      code: "01",
      status:
        "Declined. If transaction referral for voice authorisation is available, this can be used to seek transaction approval.",
      message: "Refer to card issuer",
    },
    {
      code: "02",
      status:
        "Declined. If transaction referral for voice authorisation is available, this can be used to seek transaction approval.",
      message: "Refer to card issuer special condition",
    },
    {
      code: "03",
      status:
        "Declined. This transaction will need investigation, starting with ensuring that the store value used is as provided by Transaction Junction.",
      message: "Invalid merchant, i.e. the store value is incorrect",
    },
    {
      code: "04",
      status:
        "Declined. This is typically an ATM response and is unlikely to be seen in this context.",
      message: "Pick-up card",
    },

    { code: "05", status: "Declined", message: "Do not honour" },
    {
      code: "06",
      status:
        "Declined. This typically indicates a system error, inspection of the Junction@POS log may reveal the problem.",
      message:
        "Error, typically indicates a system error. Inspection of the Junction@POS log may reveal the problem",
    },
    {
      code: "07",
      status:
        "Declined. This is typically an ATM response and is unlikely to be seen in this context.",
      message: "Pick-up card, special condition",
    },
    {
      code: "08",
      status: "Declined. This is unlikely to be seen in this context.",
      message: "Honour with identification",
    },
    {
      code: "09",
      status:
        "Declined. This transaction can be attempted again, as a new transaction.",
      message: "Request in progress",
    },
    {
      code: "10",
      status:
        "Approved partially. The outstanding amount must be authorised via another payment method, e.g. cash, or a new transaction on a Junction@POS-supported payment method, e.g. another card.",
      message:
        "Partial approval, indicating that the transaction was approved for a lesser amount than the requested amount",
    },
    {
      code: "11",
      status: "Approved. This is unlikely to be seen in this context.",
      message: "Approved, VIP",
    },
    {
      code: "12",
      status:
        "Declined. This typically indicates that the transaction type requested is not valid.",
      message: "Invalid transaction",
    },
    {
      code: "13",
      status:
        "Declined. The amount is invalid for this transaction, which may be caused by an upper or lower amount limit.",
      message: "Invalid amount",
    },
    {
      code: "14",
      status:
        "Declined. This occurs when validation of the card number fails, based on a mod 10 algorithm check performed on it.",
      message:
        "Invalid card number, indicates that the card number has validation",
    },
    {
      code: "15",
      status:
        "Declined. The card used has not been issued by an entity known to Transaction Junction or the acquiring bank.",
      message: "No such issuer",
    },
    {
      code: "16",
      status:
        "Approved, unlikely to be seen in this context.  It is a legacy code based on magnetic stripe cards featuring a writable track 3.",
      message: "Approved, update track 3",
    },
    {
      code: "17",
      status:
        "Declined. The cardholder has cancelled the transaction on the PIN entry device.",
      message:
        "Customer cancellation. The cardholder has cancelled the transaction on the PIN entry device",
    },
    {
      code: "18",
      status: "Declined. Unlikely to be seen in this context.",
      message: "Customer dispute",
    },
    {
      code: "19",
      status:
        "Declined. The transaction can be performed again, as a new transaction.  This code is unlikely in this context.",
      message: "Re-enter transact,ion",
    },
    {
      code: "20",
      status: "Declined. Unlikely to be seen in this context.",
      message: "Invalid response",
    },
    {
      code: "21",
      status:
        "Declined. The transaction should be finalised using another payment method or card.",
      message: "No action taken",
    },
    {
      code: "22",
      status:
        "Declined. This typically indicates a system error, inspection of the Junction@POS log may reveal the problem.",
      message: "Suspected malfunction",
    },
    {
      code: "23",
      status: "Declined. Unlikely to be seen in this context.",
      message: "Unacceptable transaction fee",
    },
    {
      code: "24",
      status: "Declined. Unlikely to be seen in this context.",
      message: "File update not supported",
    },
    {
      code: "25",
      status:
        "Applicable to a reversal where the original transaction was not received by Junction@POS. The reversal may be considered successful.",
      message:
        "Unable to locate record, applicable to a reversal where the original transaction was not received by Junction@POS. The reversal may be considered successful",
    },
    {
      code: "26",
      status:
        "Declined. Often refers to a transaction being duplicated in terms of items like UTI.",
      message: "Duplicate record",
    },
    {
      code: "27",
      status: "Declined. Unlikely to be seen in this context.",
      message: "File update edit error",
    },
    {
      code: "28",
      status: "Declined. Unlikely to be seen in this context.",
      message: "File update file locked",
    },
    {
      code: "29",
      status: "Declined. Unlikely to be seen in this context.",
      message: "File update failed",
    },
    {
      code: "30",
      status:
        "Declined. A field has been provided that does not meet the specification. Inspection of the Junction@POS log may help in resolving this issue.",
      message: "Format error, indicates that a field value may be incorrect",
    },
    {
      code: "31",
      status: "Declined. Unlikely to be seen in this context.",
      message: "Bank not supported",
    },
    {
      code: "32",
      status:
        "Declined. Unlikely to be seen in this context. It may relate to a partial approval, similar to 10, but more applicable to lodging transactions.",
      message: "Completed partially",
    },
    {
      code: "33",
      status:
        "Declined due to expired card. This code is unlikely as it is more relevant to automated environments like ATMs.",
      message: "Expired card, pick-up",
    },
    {
      code: "34",
      status:
        "Declined due to suspected. This code is unlikely as it is more relevant to automated environments like ATMs.",
      message: "Suspected fraud, pick-up",
    },
    {
      code: "35",
      status:
        "Declined due to suspected fraud. This code is unlikely as it is more relevant to automated environments like ATMs.",
      message: "Contact acquirer, pick-up",
    },
    {
      code: "36",
      status:
        "Declined due to a card being restricted by the issuer. This code is unlikely as it is more relevant to automated environments like ATMs.",
      message: "Restricted card, pick-up",
    },
    {
      code: "37",
      status:
        "Declined due to suspected fraud. This code is unlikely as it is more relevant to automated environments like ATMs.",
      message: "Call acquirer security, pick-up",
    },
    {
      code: "38",
      status:
        "Declined due to excessive PIN attempts. This code is unlikely as it is more relevant to automated environments like ATMs.",
      message: "PIN tries exceeded, pick-up",
    },
    {
      code: "39",
      status:
        "Declined. The specified account type is not associated with the card used. The transaction can be performed again, as it might be that the customer inadvertently selected the incorrect account type.",
      message: "No credit account",
    },
    {
      code: "40",
      status:
        "Declined. The operation requested is not supported. This most often relates to a VAS service that is not supported, and should not occur in this context.",
      message: "Function not supported",
    },
    {
      code: "41",
      status:
        "Declined. The card used has been reported as lost by the cardholder.",
      message: "Lost card",
    },
    {
      code: "42",
      status:
        "Declined. The specified account type is not associated with the card used. The transaction can be performed again, as it might be that the customer inadvertently selected the incorrect account type.",
      message: "No universal account",
    },
    {
      code: "43",
      status:
        "Declined. The card used has been reported as stolen by the cardholder.",
      message: "Stolen card",
    },
    {
      code: "44",
      status:
        "Declined. The specified account type is not associated with the card used. The transaction can be performed again, as it might be that the customer inadvertently selected the incorrect account type.",
      message: "No investment account",
    },
    {
      code: "51",
      status:
        "Declined, the cardholder has insufficient funds to cover the requested amount. This transaction can be performed again for a lesser amount.  Note that since this is a sensitive outcome, many POS systems will not display this outcome to the cashier.",
      message:
        "Insufficient funds. The authorising entity indicates that the cardholder account does not have sufficient funds for the transaction",
    },
    {
      code: "52",
      status:
        "Declined. The specified account type is not associated with the card used. The transaction can be performed again, as it might be that the customer inadvertently selected the incorrect account type.",
      message: "No check account",
    },
    {
      code: "53",
      status:
        "Declined. The specified account type is not associated with the card used. The transaction can be performed again, as it might be that the customer inadvertently selected the incorrect account type.",
      message: "No savings account",
    },
    {
      code: "54",
      status:
        "Declined. The card has expired. This transaction cannot be performed again on this card.",
      message: "Expired card.",
    },
    {
      code: "55",
      status:
        "Declined. The customer has entered an incorrect PIN. The transaction can be performed again, as it might be that the customer inadvertently entered the PIN incorrectly.",
      message: "Incorrect PIN",
    },
    {
      code: "56",
      status: "Declined. The card is not recognised by the authorising entity.",
      message:
        "No card record. The authorising entity does not have a record of the card.",
    },
    {
      code: "57",
      status:
        "Declined. The card used is not allowed to be presented for this transaction type.  The transaction cannot be performed again on the same card.",
      message: "Transaction type not permitted to cardholder",
    },
    {
      code: "58",
      status:
        "Declined, the transaction type performed is not permitted on this terminal.  This code should not be seen in the Junction@POS environment.",
      message: "Transaction type not permitted on this terminal",
    },
    {
      code: "59",
      status: "Declined. Do not perform any further transactions on this card.",
      message: "Suspected fraud",
    },
    {
      code: "60",
      status:
        "Declined. Contacting the acquiring bank may resolve the issue, and guidance may be provided as regards performing another transaction on the card.",
      message: "Contact acquirer",
    },
    {
      code: "61",
      status:
        "Declined. The card has exceeded a daily limit. No further transactions should be performed on this card.",
      message: "Exceeds withdrawal limit",
    },
    {
      code: "62",
      status:
        "Declined. No further transactions can be performed on this card.",
      message: "Restricted card",
    },
    {
      code: "63",
      status:
        "Declined. No further transactions can be performed on this card.",
      message: "Security violation",
    },
    {
      code: "64",
      status:
        "Declined. There was a problem with the transaction amount. This is an unlikely code in this environment.",
      message: "Original amount incorrect",
    },
    {
      code: "65",
      status:
        "Declined, no further debit transactions can be performed on this card.",
      message: "Exceeds withdrawal frequency",
    },
    {
      code: "66",
      status:
        "Declined. No further transactions can be performed on this card.",
      message: "Call acquirer security",
    },
    {
      code: "67",
      status:
        "Declined. No further transactions can be performed on this card.",
      message: "Hard capture",
    },
    {
      code: "68",
      status:
        "Declined. Another transaction can be performed against this card.",
      message: "Response received too late",
    },
    {
      code: "75",
      status:
        "Declined due to excessive PIN attempts. This code is unlikely as it is more relevant to automated environments like ATMs. ",
      message: "PIN tries exceeded",
    },
    {
      code: "77",
      status:
        "Declined. If transaction referral for voice authorisation is available, this can be used to seek transaction approval.",
      message: "Intervene, bank approval required",
    },
    {
      code: "78",
      status:
        "Declined. If transaction referral for voice authorisation is available, this can be used to seek transaction approval.",
      message: "Intervene, bank approval required for partial amount",
    },
    {
      code: "81",
      status:
        "Declined. The transaction timed out between the store and Transaction Junction.  It will be automatically reversed.",
      message: "Junction@POS time out",
    },
    {
      code: "82",
      status:
        "Declined. The transaction timed out between Transaction Junction and the authorising entity, and will be automatically reversed.",
      message: "Timed out from bank. Reversal will be generated",
    },
    {
      code: "83",
      status:
        "Declined. The cardholder's bank timed out, and the transaction will be reversed automatically.",
      message:
        "Card issuer time out, i.e. the cardholder’s bank did not respond in time",
    },
    {
      code: "84",
      status:
        "Declined. The transaction timed out within the Transaction Junction environment, and will be reversed.",
      message: "Timed out on switch. Reversal will be generated",
    },
    {
      code: "87",
      status:
        "Declined. The cardholder did not respond to a PED prompt in time. A new transaction can be performed",
      message: "Timeout on the PIN entry device",
    },
    {
      code: "88",
      status:
        "Declined. The cashier did not respond to a prompt in time. Another transaction can be performed.",
      message: "POS timed out on prompt to cashier",
    },
    {
      code: "89",
      status:
        "Declined. The cardholder removed the card from the PED before being prompted to. Another transaction can be performed.",
      message:
        "Early card removal, i.e. the cardholder removed the card before the transaction completed",
    },
    {
      code: "90",
      status: "Declined. A new transaction can be performed.",
      message: "Cut-off in progress",
    },
    {
      code: "91",
      status:
        "Declined. Indicates a timeout condition. Another transaction can be performed.",
      message:
        "Issuer or switch inoperative, indicates a timeout between Junction@POS and Transaction Junction",
    },
    {
      code: "92",
      status:
        "Declined. An error occurred at Transaction Junction or an authorising entity related to routing of the transaction to a destination. Do not attempt another transaction on this card.",
      message: "Routing error at Transaction Junction",
    },
    {
      code: "93",
      status:
        "Declined. No further transactions can be performed on this card.",
      message: "Violation of law",
    },
    {
      code: "94",
      status:
        "Declined. Some of the identification fields relate to a previous transaction, e.g. the uti value. A new transaction can be attempted.",
      message: "Duplicate transaction",
    },
    {
      code: "95",
      status:
        "Declined. This code should not be seen in the Junction@POS environment. A new transaction can be attempted.",
      message: "Reconcile error",
    },
    {
      code: "96",
      status:
        "Declined. An error occurred at Transaction Junction or an authorising entity. A new transaction can be performed, but if that fails with code 96, do not attempt further transactions using this code.",
      message: "System malfunction",
    },
    {
      code: "98",
      status:
        "Declined. A limit managed by an upstream authorising entity has been exceeded. Do not perform another transaction on the card.",
      message: "Exceeds cash limit",
    },
  ],
  transactionTypes: [
    {
      type: "PURCHASE",
      description:
        "A purchase transaction allows a consumer to pay for goods and services using their card or mobile phone, where applicable. A cash-back component can be added to a purchase for merchants wishing to offer this service where supported by the card and acquirer.",
    },
    {
      type: "RETURN",
      description:
        "A return transaction is enacted when a consumer returns goods, indicating a purchase needs to be returned, in whole or in part. There is no link between the purchase and the return transaction. The enablement of this transaction is optional and may require supervisor authorisation to be performed, which is typically recommended in this case. Return can be processed as either online or offline requests. When processed offline, the transaction is immediately approved by Junction@POS and an advice sent online to notify the upstream entity that the return has taken place.",
    },
    {
      type: "REVERSAL",
      description:
        "If there is no response from Junction@POS after a pre-configured timeout period, the POS should attempt to reverse the transaction. It’s recommended that this value be configurable as it can change depending on desired functionality. This value is usually set between 60 and 120 seconds as it needs to take PED interactions into account. Reversals are processed as offline requests. When processed offline, the transaction is immediately approved by Junction@POS and an advice sent online to notify the upstream entity that the reversal has taken place.",
    },

    {
      type: "CASH BACK",
      description:
        "Cashback allows a merchant to offer an added cash withdrawal element for customers making a purchase. Both an amount and cash amount are required. Transaction fees for these transactions are generally lower than for the corresponding cash withdrawal transaction type.",
    },

    {
      type: "VOUCHER",
      description:
        "The voucher functionality allows consumers to purchase prepaid airtime and data through the merchant’s POS.",
    },
    {
      type: "TOKEN",
      description:
        "The token transaction is used to allow a consumer to process a transaction using a token such as a QR code like Masterpass. The token is displayed on the PED and payment is done via the consumer’s mobile phone. The POS is notified of the outcome of the transaction. Junction@POS supports the following QR operations:",
    },
    {
      type: "PAYMENT",
      description:
        "The payment allows the merchant POS to accept account payments. This functionality can be implemented for private label account cards, which are accepted at POS.",
    },
    {
      type: "PAYMENT REFUND",
      description:
        "The payment refund allows the merchant POS to process refunds on specific account payments in the event of an operator error. TJ recommends that this feature includes a supervisor override function on the POS. This functionality can be implemented for private label account cards, which are accepted at POS.",
    },

    {
      type: "DEPOSIT",
      description:
        "Provides a function for customers to deposit funds against their card at a store.",
    },
    {
      type: "CASH WITHDRAWAL",
      description:
        "Allows cash to be withdrawn from an account at a store. This transaction type is usually priced higher than the corresponding cashback transaction.",
    },
    {
      type: "BALANCE ENQUIRY",
      description:
        "The balance request will initiate a request to an upstream provider to return an account balance. Support for this function is provider specific. At a minimum, the request will return an available balance. Some providers will return more detail: Account Balance, Credit Available, Ledger Balance. How these values are populated will be provider specific.",
    },

    {
      type: "DIGITAL VOUCHER",
      description:
        "Electronic forms of value can be requested using the digital_voucher request function. The request is designed to be versatile to cater for variable data fields. Please speak to your project manager or architect for clarity on whether this function should be used.",
    },
    {
      type: "LOYALTY",
      description:
        "The loyalty request can be used to notify a 3rd party of the details of a transaction which qualifies for some kind of loyalty benefit. It is a stand-alone message which is not linked to any previous transaction. Any linking to a previous transaction is the responsibility of the POS implementation.",
    },
    {
      type: "GIFTCARD ISSUE",
      description:
        "The giftcard issue transaction allows a consumer to purchase a gift card, activate and then load value onto the card.",
    },
    {
      type: "GIFTCARD RELOAD",
      description:
        "The giftcard reload transaction provides for additional value onto an already existing/issued giftcard.",
    },
    {
      type: "PUSH FUNDS",
      description:
        "Push funds allow consumers to perform instant transactions via either Mastercard, Visa or Bankserv. These transactions are not enabled by default.",
    },

    {
      type: "EFUEL PRE-AUTH",
      description:
        "The Efuel pre-auth allows a fuelling site that accepts Efuel tags or cards to process pre-authorisations for fuel sales.",
    },
    {
      type: "EFUEL FINALISATION",
      description:
        "The Efuel finalise completes the pre-auth transaction mentioned above. Once the transaction is finalised, the transaction is posted for settlement.",
    },
  ],
  receiptTable: [
    {
      code: "00",
      message: "Approved",
    },
    {
      code: "10",
      message: "Partially Approved",
    },
    {
      code: "Any other response code",
      message: "Declined",
    },
  ],
};

export default junctionPOSData;
