import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import qrCodeGetInfo from "../transactionData/qrCodeAPIs/qrCodeGetTransInfo";
import qrCodeTransNotify from "../transactionData/qrCodeAPIs/qrCodeTransNotify";
import qrCodeTransReversal from "../transactionData/qrCodeAPIs/qrCodeTransReversal";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<i class="far fa-arrow-circle-down"></i>}
    {...props}
  />
))(({ theme }) => ({
  marginLeft: 0,
  justifyContent: "flex-start",
  border: "none",
  padding: 0,
  alignItems: "center",
  fontFamily: "Montserrat-Regular",
  textTransform: "uppercase",
  color: "#00C1FF",
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "#00C1FF",
    fontSize: 20,
    marginTop: -3,
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(0),
    padding: 0,
    flex: "unset",
    marginRight: 10,
    fontFamily: "Montserrat-Regular",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
}));

export default function TJTextAccordion() {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <p style={{ color: "#000" }}>
        The operations available on the “QR to TJ” API are given below.
      </p>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panelad-content" id="panel1d-header">
          <h4>Get Transaction Info</h4>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li style={{ listStyleType: "circle" }}>
              The QR partner will send a Get Info Request to TJ as a request for
              transactional information.
            </li>
            <li style={{ listStyleType: "circle" }}>
              TJ will send a Get Info Response to the QR partner with the
              transactional information for the QR Partner to process the
              transaction with the acquirer.
            </li>
          </ul>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(qrCodeGetInfo.request, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(qrCodeGetInfo.response, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <h4>Transaction Notification</h4>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li style={{ listStyleType: "circle" }}>
              The QR partner will send the Transaction Notification to TJ to
              indicate the outcome of the transaction.
            </li>
            <li style={{ listStyleType: "circle" }}>
              TJ will send a Transaction Notification Response to the QR partner
              to indicate that the Transaction Notification was received.
            </li>
          </ul>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(qrCodeTransNotify.response, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(qrCodeTransNotify.request, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <br />
      <p style={{ color: "#000" }}>
        The one operation available on the “QR from TJ” API is given below.
      </p>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <h4>Transaction Reversal</h4>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <ul>
              <li style={{ listStyleType: "circle" }}>
                In the case of a timeout, TJ will route the Transaction Reversal
                Request to the QR partner to reverse the transaction. The QR
                partner will route the Transaction Reversal Request to the
                acquirer and notify the customer that the transaction has been
                reversed.
              </li>
              <li style={{ listStyleType: "circle" }}>
                The QR partner will send a Transaction Reversal Response to TJ
                to indicate that the reversal has been received. If no response
                is received, TJ will send a repeat reversal request to the QR
                partner for a configured number of seconds until either a
                response is received or the configured number of retries have
                completed.
              </li>
            </ul>
            <div style={{ marginTop: 20 }}>
              <div className="code-box-container">
                <div className="header-container">
                  <div>Request</div>
                  <div>Response</div>
                </div>
                <div className="body-container">
                  <div className="code-container">
                    <code>
                      <div>
                        <pre>
                          {JSON.stringify(qrCodeTransReversal.request, null, 2)}
                        </pre>
                      </div>
                    </code>
                  </div>
                  <div>
                    <code>
                      <div>
                        <pre>
                          {JSON.stringify(
                            qrCodeTransReversal.response,
                            null,
                            2
                          )}
                        </pre>
                      </div>
                    </code>
                  </div>
                </div>
              </div>
              <p>*Example shows basic use case with sample values.</p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
