const vasProvTopUpGet = {
  request: {
    tranRef: "1a5fe454-14f5-4f5e-8957-123456789abd",
    phoneNumber: "0847467416",
  },

  response: {
    responseCode: "00",
    responseText: "Transaction successful",
    reference: "asdf12345",
    productName: "Test Product",
    date: "2025-12-31 23:59:27",
  },
};

export default vasProvTopUpGet;
