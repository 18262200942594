const CardPaymentData = {
  responseCodes: [
    {
      code: "APPR",
      message: "Approved. Service has been successfully provided.",
    },
    {
      code: "DECL",
      message: "Declined. Service is declined.",
    },
    {
      code: "SUSP",
      message:
        "Suspended. Transaction has been received but has not been processed.",
    },
    {
      code: "PART",
      message: "PartialApproved. Service has been partially provided.",
    },
    {
      code: "TECH",
      message:
        "TechnicalError. Service cannot be provided for technicalreason (e.g. timeout contacting the issuer, security problem)",
    },
  ],
};

export default CardPaymentData;
