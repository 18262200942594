export const cardPaymentComplReverse = {
  request: {
    header: {
      messageFunction: "RVRA",
    },
    completionAdvice: {
      environment: {
        acquirer: {
          identification: "00000000002",
        },
        merchant: {
          identification: "123456789123456",
          locationAndContact: {
            addressLine: ["Acme Traders Small Str"],
            townName: "Cape Town",
            countrySubDivision: ["WC"],
            countryCode: "ZA",
          },
        },
        pointOfInteraction: {
          identification: "ATS10101",
          capabilities: {
            pinLengthCapabilities: 12,
          },
        },
        card: {
          plainCardData: {
            pan: "493805******7383",
            expiryDate: "2022-05",
            cardSequenceNumber: "320",
          },
          serviceOption: "100",
        },
      },
      context: {
        cardPresent: true,
        cardHolderPresent: true,
        onlineContext: true,
        cardDataEntryMode: "MGST",
      },
      transaction: {
        merchantCategoryCode: "1234",
        originalTransaction: {
          transactionIdentification: {
            transactionDateTime: "2022-08-18T12:59:46.000+00:00",
            transactionReference: "6df36776e575487a8442e4d167493591",
          },
          transactionType: "BALC",
        },
        transactionIdentification: {
          transactionDateTime: "2022-08-18T12:59:46.615Z",
          transactionReference: "303f8cf213fa416cb0d39d5bec6cfbc1",
        },
        transactionType: "CRDP",
        transactionDetails: {
          currency: "710",
          /* prettier-ignore */
          totalAmount: 160.00,
          accountType: "CHCK",
        },
      },
    },
  },
  response: {
    header: {
      messageFunction: "RVRR",
    },
    completionAdviceResponse: {
      environment: {
        acquirer: null,
        merchant: {
          identification: "123456789123456",
          locationAndContact: {
            addressLine: ["Acme Traders Small Str "],
            townName: "Cape Town    ",
            countrySubDivision: ["WC"],
            countryCode: "ZA",
          },
        },
        pointOfInteraction: {
          identification: "ATS10101",
          capabilities: {
            pinLengthCapabilities: 12,
          },
        },
        card: {
          plainCardData: {
            pan: "493805******7383",
            expiryDate: "2022-05",
            cardSequenceNumber: null,
            track1Data: null,
            track2Data: null,
            cardholderName: null,
          },
          maskedPan: null,
          serviceOption: null,
        },
        paymentToken: null,
        cardholder: null,
        posDataCode: null,
      },
      transaction: {
        transactionIdentification: {
          transactionDateTime: "2022-08-18T12:59:46.000+00:00",
          transactionReference: "303f8cf213fa416cb0d39d5bec6cfbc1",
        },
        response: "APPR",
      },
      supplementaryData: null,
    },
  },
};
