const vasValidateMeter = {
  request: {
    tranRef: "1a5fe454-14f5-4f5e-8957-123456789abd",
    cardAcceptor: "1234567890     ",
    cardAcceptorTerminal: "12345678",
    currency: "710",
    meterNumber: "01050020001",
    amount: 5555,
  },

  response: {
    customer: {
      name: "MR. J SMITH",
      address: "00071, PRETORIA GARDENS",
      standNumber: "00071, PRETORIA GARDENS",
      area: "PRETORIA GARDENS",
    },
    meterNumber: "1234",
    responseCode: "00",
    responseText: "Approved",
    utility: "Tshwane",
  },
};

export default vasValidateMeter;
