const vasGiftCardStatus = {
  request: {
    tranRef: "1a5fe454-14f5-4f5e-8957-123456789abd",
    cardAcceptor: "1234567890     ",
    cardAcceptorTerminal: "12345678",
    currency: "710",
    productId: "123ZA5",
    originalRef: "1a5fe454-14f5-4f5e-8957-123456789abd",
    amount: 5555,
    pan: "7531252464935949868",
  },
  response: {
    id: "23d84ffe-9472-466d-94bf-39e44f14f080",
    modified: "2022-08-02T17:58:41.285Z",
    pan: "7531252464935949868",
    pin: "a49dfc09",
    responseCode: "00",
    responseText: "Approved",
    reprint: true,
  },
};

export default vasGiftCardStatus;
