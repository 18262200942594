const giftcardReload = {
  request: {
    tran: "GIFTCARD RELOAD",
    amount: 5000,
    reference: "e6eda214-7fa1-4189-af06-d885c0d8aed5",
    pos: "57290093",
    store: "RESTSIM00000001",
    chain: "Test",
  },

  response: {
    authID: "123457",
    tran: "GIFTCARD RELOAD",
    response_code: "00",
    response_text: "Approved",
    amount: 5000,
    reference: "e6eda214-7fa1-4189-af06-d885c0d8aed5",
    seq: "000045",
    rrn: "000046241042",
    pos: "57290093",
    store: "RESTSIM00000001",
    card: "188451******7052",
    card_name: "Playstation Store GiftCard",
    sign: false,
    card_entry: "S",
    receipt: [],
    data: [],
  },
};

export default giftcardReload;
