import React from "react";
import styled from "styled-components";

const ReceiptContainer = styled.div`
  width: 350px;
  height: 352px;
  background: #fff;
  margin-right: 6.5vh;
  border: 1px solid #000;
  padding: 10px;
  color: #000;
  display: inline-block;
  font-family: "VT323", monospace;
  position: relative;
  float: left;
`;

const TransactionDetails = styled.div`
  display: flex;
  margin: 0 10px 10px;
  font-size: 10px;
  justify-content: space-between;
`;

const TransactionDetailsMerge = styled.div`
  display: flex;
  margin: 0 10px 10px;
  font-size: 10px;
  color: #3dbdee;
  justify-content: space-between;
`;

const CenterHeading = styled.div`
  text-align: center;
  padding: 1rem;
`;

const Detail = styled.div`
  width: 50%;
  font-size: 10px;
`;

const PaymentDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
`;

const Amount = styled.div`
  width: 50%;
  font-size: 10px;
  text-align: right;
`;

const TJReceiptDeclined = () => {
  return (
    <ReceiptContainer>
      <CenterHeading>
        <h4>Transaction Declined (response_code)</h4>
        <h4>Customer/Merchant Copy</h4>
      </CenterHeading>
      <TransactionDetails>
        <Detail>&lt;&lt;POSDate&gt;&gt;</Detail>
        <Detail>&lt;&lt;POSTime&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>&lt;&lt;MerchantID&gt;&gt;</Detail>
        <Detail>&lt;&lt;PosID&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail style={{ color: "#3dbdee" }}>&lt;&lt;PAN&gt;&gt;</Detail>
        <Detail>&lt;&lt;STAN&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetailsMerge>
        <Detail>&lt;&lt;CryptogramType&gt;&gt;</Detail>
        <Detail>&lt;&lt;cryptogram&gt;&gt;</Detail>
      </TransactionDetailsMerge>
      <TransactionDetailsMerge>
        <Detail>&lt;&lt;APL&gt;&gt;</Detail>
        <Detail>&lt;&lt;TVR&gt;&gt;</Detail>
      </TransactionDetailsMerge>
      <TransactionDetailsMerge>
        <Detail>AID:</Detail>
        <Detail>&lt;&lt;aid&gt;&gt;</Detail>
      </TransactionDetailsMerge>
      <TransactionDetailsMerge>
        <Detail>CVM Results:</Detail>
        <Detail>CVMResults</Detail>
      </TransactionDetailsMerge>
      <TransactionDetails>
        <Detail>UTI:</Detail>
        <Detail>&lt;&lt;reference&gt;&gt;</Detail>
      </TransactionDetails>
      <br />
      <PaymentDetails>
        <Detail>Declined Amount:</Detail>
        <Amount>R &lt;&lt;amount&gt;&gt;</Amount>
      </PaymentDetails>
    </ReceiptContainer>
  );
};

export default TJReceiptDeclined;
