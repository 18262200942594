const eFuelFinalisation = {
  request: {
    tran: "EFUEL FINALISATION",
    amount: 0,
    reference: "8c1b0d6b-a7b1-47aa-afab-d56a5c413f82",
    original_stan: "000007",
    original_terminal_id: "57290093",
    pos: "57290093",
    store: "RESTSIM00000001",
    chain: "Test",
  },

  response: {
    tran: "EFUEL FINALISATION",
    response_code: "00",
    response_text: "Approved",
    amount: 900000,
    reference: "8c1b0d6b-a7b1-47aa-afab-d56a5c413f82",
    seq: "000008",
    rrn: "000008190060",
    pos: "57290093",
    store: "RESTSIM00000001",
    card: "500857*********0028",
    receipt: [],
    data: [],
  },
};

export default eFuelFinalisation;
