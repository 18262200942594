const threeDSValidate = {
  request: {
    version: "4.0",
    merchantID: "123456",
    xid: "MDAwMDEyMzQ1NkxNYW9sdVI1NTU=",
    mdStatus: "1",
    mdErrorMsg:
      "Y-status/Challenge authentication via ACS: https://acsabsatest.bankserv.co.za/mdpayacs/creq",
    veresEnrolledStatus: "Y",
    paresTxStatus: "Y",
    iReqCode: "",
    iReqDetail: "",
    vendorCode: "",
    eci: "02",
    cavv: "jLRErAZhP8jWCREAgBl7BpIAAAA=",
    cavvAlgorithm: "",
    MD: "",
    PAResVerified: "true",
    PAResSyntaxOK: "true",
    protocol: "3DS2.1.0",
    cardType: "2",
    signature:
      "KRZrYIuLjsNJDfXQtG9WqERWDh7XHJ8yWni08/rzNoofLvD0pC6KuOw/-n1pTCB2IbGsPn9+viKMN6Arw9K9j1kJuKh0NvMLtZAe3YDCEFtvSMeZU54DwMBns2csdESKc-F2HVT2myzTONboauREZ4QEt68zGWM/iuQJ3H8pAKUDTMpZUZm7jk395zwDlGmhICv+fatWME-q28JpKbTZbrq0O0p2fr+SpnpJxVmoqpamOIxhjAG4oJYqQXEx4Hzq5L+tZOP/e/5QkBHVu/0-LiogHEkgr/4rhzjwDZDACWK/ErwHduy0zIYEFIeMjjcpJcbeEG683sQ4fIsEXxfkIklGNQ==",
    sID: "2",
    transactionId: "{{transactionId}}",
  },

  response: {
    allowedToContinue: true,
    transactionId: "71916204-3cf2-43a5-8852-28bc6afea571",
  },
};

export default threeDSValidate;
