const vasProvVoucherTransGet = {
  request: {
    tranRef: "1a5fe454-14f5-4f5e-8957-123456789abd",
  },

  response: {
    responseCode: "00",
    responseText: "Transaction successful",
    reference: "asdf12345",
    barcode: "65468635546",
    productName: "Test Product",
    pin: "123 456 7890",
    serialNumber: "8987654",
    expiryDate: "2025/12/31",
    activateMessage: "Please dial 0800 123 and read your pin to the operator",
    supportMessage: "If you have problems loading your voucher dial 0800 123",
    receiptHeader: "Test Product TopUp from Test Network",
    receiptFooter: "Please enjoy your day",
  },
};

export default vasProvVoucherTransGet;
