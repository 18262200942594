import React from "react";
import styled from "styled-components";
import TJReceiptLogo from "../static/images/receiptLogo.png";

const ReceiptContainer = styled.div`
width: 350px;
height: 861px;
background: #fff;
border: 1px solid #000;
padding: 10px;
color: #000;
font-family: "VT323",monospace;
display: inline-block;
position: relative;
`;

const ReceiptLogoContainer = styled.div`
  text-align: center;
  padding: 20px;
`;
const ReceiptLogo = styled.img`
  text-align: center;
  padding: 20px 20px 0;
  width: 120px;
`;

const AddressContainer = styled.div`
  margin-bottom: 40px;
  padding: 0 20px;
  text-align: center;
`;
const Address = styled.div`
  font-size: 12px;
`;

const TransactionDetails = styled.div`
  display: flex;
  margin: 0 10px 10px;
  font-size: 10px;
  justify-content: space-between;
`;

const Detail = styled.div`
  width: 50%;
  font-size: 10px;
  text-transform: uppercase;
`;

const CenterHeading = styled.div`
  text-align: center;
  padding: 1rem;
`;

const PaymentDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
`;

const Amount = styled.div`
  width: 50%;
  font-size: 10px;
  text-transform: uppercase;
  text-align: right;
`;

const TJReceiptApprovedExample = () => {
  return (
    <ReceiptContainer>
      <ReceiptLogoContainer>
        <ReceiptLogo src={TJReceiptLogo} alt="brand logo" />
      </ReceiptLogoContainer>
      <AddressContainer>
        <Address>Transaction Junction</Address>
        <Address>1 Waterhouse Place, century City</Address>
      </AddressContainer>
      <CenterHeading>
        <h4>Purchase</h4>
        <h4>Customer Copy</h4>
      </CenterHeading>
      <TransactionDetails>
        <Detail>Terminal ID</Detail>
        <Detail>TRN#1313</Detail>
      </TransactionDetails>
      {/* <TransactionDetails>Helped by: Cashier Name</TransactionDetails>
        <div className="centerItem bold">
          <div className="item">ExtraCare Card #: *********1875</div>
        </div> */}
      <TransactionDetails>
        <Detail>Date and Time:</Detail>
        <Detail>20211014113059</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>Store Merchant ID:</Detail>
        <Detail>My Store</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>Bank Merchant ID:</Detail>
        <Detail>My Bank</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>Card Name:</Detail>
        <Detail>Mastercard</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>Card Number:</Detail>
        <Detail>541216******2356</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>STAN:</Detail>
        <Detail>100234</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>RRN:</Detail>
        <Detail>0000001120781</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>AUTH ID:</Detail>
        <Detail>649279</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>PAN Entry Mode:</Detail>
        <Detail>C</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>PIN Statement:</Detail>
        <Detail>No CVM used</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>TSI:</Detail>
        <Detail>E800</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>TVR:</Detail>
        <Detail>0000000001</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>ARQC:</Detail>
        <Detail>984AF700E62AEF75</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>APL:</Detail>
        <Detail>{`<Mastercard`}</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>AID:</Detail>
        <Detail>A0000000041010</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>IAD:</Detail>
        <Detail>0110A04303A200040000</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>UTI:</Detail>
        <Detail>514adae2-fc03-40d9-8eca-f2b9667afb43</Detail>
      </TransactionDetails>
      <PaymentDetails>
        <Detail>TOTAL</Detail>
        <Amount>R125.23</Amount>
      </PaymentDetails>
      <PaymentDetails>
        <Detail>Cash Amount</Detail>
        <Amount>R0.00</Amount>
      </PaymentDetails>
      <CenterHeading>
        <span style={{ fontSize: 14, fontWeight: "600" }}>Approved</span>
        &nbsp;
        <span style={{ fontSize: 14, fontWeight: "600" }}>00</span>
      </CenterHeading>
    </ReceiptContainer>
  );
};

export default TJReceiptApprovedExample;
