const vasPurchaseLotto = {
  request: {
    tranRef: "1a5fe454-14f5-4f5e-8957-123456789abd",
    cardAcceptor: "1234567890     ",
    cardAcceptorTerminal: "12345678",
    currency: "710",
    games: {
      numbers: [
        {
          value: "2,27,29,30,35",
        },
      ],
      plus1: true,
      plus2: false,
      boards: 5,
    },
    game: "Lotto",
    type: "PlayerSelected",
  },

  response: {
    barcode: "07992010438912080494",
    coupon: {
      couponDate: "02/08/2022 19:58:46",
      numOfColumns: 4,
    },
    draw: {
      firstDrawDate: "18/12/2021",
      lastDrawDate: "18/12/2021",
      numOfDraws: 1,
      validDraws: {
        end: 2189,
        start: 2189,
      },
    },
    game: "Lotto",
    games: {
      numbers: [
        {
          value: "3,5,26,30,43,48",
        },
        {
          value: "9,15,42,44,45,50",
        },
      ],
      plus1: true,
    },
    responseCode: "00",
    responseText: "Approved",
    status: "ACCEPTED",
    ticketSerial: "7992010438912080494",
    type: "Quickpick",
  },
};

export default vasPurchaseLotto;
