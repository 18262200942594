const vasPurchaseGiftCard = {
  request: {
    tranRef: "1a5fe454-14f5-4f5e-8957-123456789abd",
    cardAcceptor: "1234567890     ",
    cardAcceptorTerminal: "12345678",
    currency: "710",
    productId: "123ZA5",
    amount: 5555,
    pan: "7531252464935949868",
  },
  response: {
    id: "829b5eed-72db-4fbb-9a8b-df720f2366fa",
    modified: "2022-08-02T17:58:36.182Z",
    pan: "7531252464935949868",
    pin: "56b45f37",
    responseCode: "00",
    responseText: "Approved",
  },
};

export default vasPurchaseGiftCard;
