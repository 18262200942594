import React from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './fonts/Montserrat/Montserrat-Regular.ttf';
import api from './helpers/api';
import Keycloak from 'keycloak-js';

// Include Redux
import Redux from './Redux';
// let keycloak = new Keycloak({
//   realm: process.env.REACT_APP_KEYCLOAK_REALM || 'Imbeko',
//   url:
//     process.env.REACT_APP_KEYCLOAK_URI || 'https://teststo.switch.tj:8443/auth',
//   clientId: process.env.REACT_APP_CLIENT_ID || 'api-portal',
// });

let keycloak = new Keycloak({
  realm: process.env.REACT_APP_KEYCLOAK_REALM || 'Imbeko',
  url: process.env.REACT_APP_KEYCLOAK_URI || 'https://keycloak.cmt-devubuntu01.test.tj',
  // 'http://localhost:8080',
  clientId: process.env.REACT_APP_CLIENT_ID || 'api-portal',
});

//Initialization of the keycloak instance
keycloak
  .init({ onLoad: 'login-required', checkLoginIframe: false })
  .then((authenticated) => {
    if (!authenticated) {
      window.location.reload();
    }

    //store authentication tokens in sessionStorage for usage in app
    sessionStorage.setItem('authentication', keycloak.token);
    sessionStorage.setItem('refreshToken', keycloak.refreshToken);

    api.defaults.headers = { Authorization: 'Bearer ' + keycloak.token };

    ReactDOM.render(
      <React.StrictMode>
        <Redux>
          <BrowserRouter>
            <App keycloak={keycloak} />
          </BrowserRouter>
        </Redux>
      </React.StrictMode>,
      document.getElementById('root')
    );
    //to regenerate token on expiry
    setInterval(() => {
      keycloak
        .updateToken(120)
        .then((refreshed) => {
          if (refreshed) {
            sessionStorage.setItem('authentication', keycloak.token);
            sessionStorage.setItem('refreshToken', keycloak.refreshToken);

            api.defaults.headers = {
              Authorization: 'Bearer ' + keycloak.token,
            };
          } else {
            console.warn(
              'Token not refreshed, valid for ' +
                Math.round(
                  keycloak.tokenParsed.exp +
                    keycloak.timeSkew -
                    new Date().getTime() / 1000
                ) +
                ' seconds'
            );
          }
        })
        .catch((error) => {
          console.error('Failed to refresh token');
        });
    }, 60000);
  })
  .catch((error) => {
    console.error('Authenticated Failed');
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
