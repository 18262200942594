const vasAllProd = {
  request: {
    customerId: "TestRetailer",
  },

  response: [
    {
      productId: "123",
      name: "Airtime R20 topup",
      provider: "testProvider",
      productType: "Prepaid Airtime",
      amount: 2000,
    },
    {
      productId: "321",
      name: "Airtime R50 topup",
      provider: "testProvider",
      productType: "Prepaid Airtime",
      amount: 5000,
    },
    {
      productId: "111",
      name: "Some voucher",
      provider: "differentProvider",
      productType: "shopping voucher",
    },
  ],
};

export default vasAllProd;
