const qrCodeTransReversal = {
  request: {
    UTI: "8b28976e-3a4d-8f50-dfdd-37750b23dc37",
    originalUTI: "a5d724da-0e42-a6bc-4c69-af3922ebbc37",
    localTransactionDateTime: "2022-07-13T00:37:04Z",
    retrievalReferenceNumber: "007916224931",
    STAN: "984952",
    repeat: "false",
    responseCode: "22",
    responseText: "null",
  },

  response: {
    responseCode: "00",
    responseText: "Approved",
    UTI: "8b28976e-3a4d-8f50-dfdd-37750b23dc37",
  },
};

export default qrCodeTransReversal;
