import React from 'react';

import { Grid } from '@material-ui/core';
import payTable1 from '../static/images/PayatTable1.jpg';
import payTable2 from '../static/images/PayatTable2.jpg';
import jPosimg from '../static/images/jpospmain.png';
import jPosLogo from '../static/images/jposlogo.png';

import TJSwagger from '../components/TJSwagger';
import styled, { keyframes } from 'styled-components';
import { fadeInUp, fadeInLeft, fadeInRight } from 'react-animations';

// APIs
import paytableYAML from '../yaml/payattable.yaml';

// Layout helpers
const fadeInUpAnimation = keyframes`${fadeInUp}`;
const fadeInRightAnimation = keyframes`${fadeInRight}`;
const fadeInLeftAnimation = keyframes`${fadeInLeft}`;

const AnimationContainer = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const AnimationContainerMobile = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    display: block;
  }
  @media screen and (min-width: 960px) {
    display: none;
  }
`;

const FadeInUp = styled.div`
  top: 35px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: -40px;
  text-align: center;
  z-index: 1;
  position: absolute;
  animation: 1s ${fadeInUpAnimation};
  width: 345px;

  @media screen and (min-width: 1025px) {
    width: 400px;
  }
  img {
    width: 300px;
    @media screen and (min-width: 600px) {
      width: 345px;
    }
    @media screen and (min-width: 1025px) {
      width: 400px;
    }
  }
`;

const FadeInRight = styled.div`
  bottom: 10%;
  right: 10%;
  z-index: 2;
  position: absolute;
  animation: 1s ${fadeInRightAnimation};

  img {
    width: 120px;
    @media screen and (min-width: 600px) {
      width: 150px;
    }
  }
`;

const FadeInLeft = styled.div`
  animation: 0.5s ${fadeInLeftAnimation};
  margin-top: 15px;
  width: 300px;
  height: 300px;
  border-radius: 15px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(0, 130, 217, 1) 58%,
    rgba(0, 193, 255, 1) 93%
  );
  @media screen and (min-width: 600px) {
    width: 345px;
    height: 345px;
  }
  @media screen and (min-width: 1025px) {
    width: 400px;
    height: 400px;
  }
`;
const PayTable = () => {
  return (
    <Grid container spacing={0}>
      <Grid container className="tj-grid-container" id="overview">
        <Grid xs={6} md={3} item>
          <h2>Overview</h2>

          <p style={{ marginTop: '20%' }}>
            Junction@POS provides functionality which allows a portable device
            to be used away from the POS to accept payments. The POS is required
            to provide certain information e.g. available checks and outstanding
            balances to Junction@POS to facilitate this.
          </p>
        </Grid>
        <Grid
          xs={6}
          md={9}
          item
          style={{
            position: 'relative',
          }}
        >
          <AnimationContainer>
            <FadeInUp>
              <img src={jPosimg} alt="Junction@POS" />
              <FadeInRight>
                <img
                  style={{ width: 120 }}
                  src={jPosLogo}
                  alt="Junction@POS Logo"
                />
              </FadeInRight>
            </FadeInUp>
            <FadeInLeft />
          </AnimationContainer>
        </Grid>
      </Grid>

      <Grid
        item
        style={{
          background:
            'transparent linear-gradient(270deg, #0082D9 0%, #00C1FF 100%) 0% 0% no-repeat padding-box',

          color: '#fff',
          margin: '0 auto',
        }}
        className="tj-grid-container"
        id="implementation"
      >
        <h2>Implementation</h2>
        <p>Integration consists of two API calls to the POS:</p>
        <h4>CheckInfo</h4>
        <p>Requests:</p>
        <p>
          <ul>
            <li>
              A list of open checks available for payment with the option for
              user ID and PIN authentication. When a user ID is provided the POS
              should only return open checks assigned to that user.
            </li>
            <li>
              The balance linked to a specific table number entered on the PED.
              Using specific table numbers is optional and must be configured if
              required.
            </li>
          </ul>
        </p>
        <p>
          If the POS implements user IDs and PINs, Junction@POS can be
          configured to prompt the user to enter these. The POS is responsible
          for authenticating these values. If user IDs are implemented without
          PINs the user can press enter on the device instead of entering a PIN.
          The POS would only authenticate based on the user ID and not evaluate
          the PIN.
        </p>
        <p>
          If user IDs are not implemented by the POS, Junction@POS can be
          configured not to prompt for these values. The POS would then ignore
          these fields in the request. Further, this implies that all checks
          available are returned in the response.
        </p>
        <h4>PostPayment</h4>
        <p>
          Notifies the POS that a full or partial payment against an open check
          has taken place. In the event of a split payment this notification may
          be sent multiple times.
        </p>
        <p>
          Two payment mechanisms apply, card or token. Token will be returned if
          the guest chooses to pay using a token-based system like Masterpass
          (QR code). It is possible for both payment mechanisms to be used in
          the case of a partial payment.
        </p>
        <p>
          The outstanding balance on the check should be returned in the
          postpayment response.
        </p>
        <p>Gratuities (Tipping):</p>
        <p>
          If gratuities are configured, the total amount will include the
          gratuity amount in the total amount paid. The actual gratuity amount
          will be returned in the tip field and should be deducted from the
          total before determining the outstanding balance. This also implies
          that it is possible for the total amount to be more than the check
          balance.
        </p>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: '40px',
          }}
        >
          <div>
            <img src={payTable1} />
          </div>
          <br />
          <div>
            <img src={payTable2} />
          </div>
        </div>
      </Grid>
      <Grid item className="tj-grid-container" id="page-api">
        <TJSwagger url={paytableYAML} />
      </Grid>
    </Grid>
  );
};

export default PayTable;
