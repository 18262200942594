const PaymentGatewayData = {
  HPP: {
    transactionTypes: [
      {
        type: "Payment Intent",
      },
      {
        type: "Session",
      },
    ],
    descriptions: [
      {
        number: "1.1) ",
        description:
          "Takes in Client_id and Client_secret to obtain a new access token. Refer to request in API spec for sample requests",
      },
      {
        number: "1.3) ",
        description: "Access_token is returned in response body.",
      },
      {
        number: "2.1) ",
        description:
          "The Payment api can then be called using the Access_token in the header for subsequent API calls. See request samples in API Spec",
      },
    ],
    responseCodes: [
      {
        code: "200 ",
        message: "Payment intent created",
      },
      {
        code: "403 ",
        message: "Merchant inactive",
      },
      {
        code: "403 ",
        message: "Profile inactive",
      },
      {
        code: "404",
        message: "Merchant not found",
      },
      {
        code: "404",
        message: "Profile does not exist",
      },

      {
        code: "404",
        message: "Merchant ref not supplied or invalid",
      },
    ],

    session: [
      {
        code: "200 ",
        message: "Session created",
      },
      {
        code: "400 ",
        message: "Invalid transaction amount specified",
      },
      {
        code: "403",
        message: "Profile inactive",
      },
      {
        code: "403",
        message: "Merchant inactive",
      },
      {
        code: "404",
        message: "Profile does not exist",
      },
      {
        code: "404",
        message: "Payment intent does not exist",
      },
      {
        code: "404",
        message: "Payment intent not supplied",
      },
      {
        code: "404",
        message: "Merchant ref not supplied",
      },
      {
        code: "404",
        message: "Profile Id not supplied",
      },
      {
        code: "409",
        message: "Transaction already exist for payment intent",
      },
    ],
  },
  DirectIntergration: {
    transactionTypes: [
      {
        type: "Payment Intent",
      },
      {
        type: "Transaction",
      },
      {
        type: "3DS Lookup",
      },
      {
        type: "3DS Validate",
      },
      {
        type: "Authorize",
      },
      {
        type: "Settle",
      },
      {
        type: "Reverse",
      },
      {
        type: "Refund",
      },
    ],
    descriptions: [
      {
        number: "1.1) ",
        description:
          "Takes in Client_id and Client_secret to obtain a new access token. Refer to request in API spec for sample requests",
      },
      {
        number: "1.3) ",
        description: "Access_token is returned in response body.",
      },
      {
        number: "2.1) ",
        description:
          "The Payment api can then be called using the Access_token in the header for subsequent API calls. See request samples in API Spec",
      },
    ],
    responseCodes: {
      Ecomm: [
        {
          code: "200",
          message: "Payment intent created",
        },
        {
          code: "403",
          message: "Merchant inactive",
        },
        {
          code: "403",
          message: "Profile inactive",
        },
        {
          code: "404",
          message: "Merchant not found",
        },
        {
          code: "404",
          message: "Profile does not exist",
        },
        {
          code: "404",
          message: "Merchant ref not supplied or invalid",
        },
      ],
      Transaction: [
        {
          code: "200",
          message: "Transaction created",
        },
        {
          code: "400",
          message: "Invalid transaction amount specified",
        },
        {
          code: "403",
          message: "Profile inactive",
        },
        {
          code: "403",
          message: "Merchant inactive",
        },
        {
          code: "404",
          message: "Profile does not exist",
        },
        {
          code: "404",
          message: "Payment intent does not exist",
        },
      ],

      ThreeDSLookUp: [
        {
          code: "200",
          message: "Initiate a Transaction and return an ACS page",
        },
        {
          code: "404",
          message: "Profile not found",
        },
      ],
      ThreeDSValidation: [
        {
          code: "200",
          message: "Transaction validated",
        },
        {
          code: "404",
          message: "Transaction not found",
        },
      ],
    },
  },
};

export default PaymentGatewayData;
