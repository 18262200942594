import React from "react";
import styled from "styled-components";

const ReceiptContainer = styled.div`
  width: 350px;
  height: 352px;
  background: #fff;
  border: 1px solid #000;
  padding: 14px;
  color: #000;
  display: inline-block;
  font-family: "VT323", monospace;
  position: relative;
`;

const TransactionDetails = styled.div`
  display: flex;
  margin: 0 10px 10px;
  font-size: 10px;
  justify-content: space-between;
`;

const CenterHeading = styled.div`
  text-align: center;
  padding: 1.6rem;
`;

const Detail = styled.div`
  width: 50%;
  font-size: 10px;
`;

const PaymentDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
`;

const Amount = styled.div`
  width: 50%;
  font-size: 10px;
  text-align: right;
`;

const TJReceiptDeclinedExample = () => {
  return (
    <ReceiptContainer>
      <CenterHeading>
        <h4>Transaction Declined (91)</h4>
        <h4>Customer Copy</h4>
      </CenterHeading>
      <TransactionDetails>
        <Detail>20170116</Detail>
        <Detail>175120</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>120034005600780</Detail>
        <Detail>TJ123456</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>541216******2356</Detail>
        <Detail>100234</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>TC:</Detail>
        <Detail>D738E9696C12A12B</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>MASTERCARD</Detail>
        <Detail>0000008000</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>AID:</Detail>
        <Detail>A0000000041014</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>CVM Results:</Detail>
        <Detail>440302</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>UTI:</Detail>
        <Detail>48952612-8733-45c8-bb78-5aa6c763ec14</Detail>
      </TransactionDetails>

      <PaymentDetails>
        <Detail>Total:</Detail>
        <Amount>R R125.20</Amount>
      </PaymentDetails>
    </ReceiptContainer>
  );
};

export default TJReceiptDeclinedExample;
