import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import vasValidateMeter from "../transactionData/vasClient/vasValidateMeter";
import vasPurchaseElectricity from "../transactionData/vasClient/vasPurchaseElectricity";
import vasPurchaseAirtimeTopup from "../transactionData/vasClient/vasPurchaseAirtimeTopup";
import vasPurchaseLotto from "../transactionData/vasClient/vasPurchaseLotto";
import vasPurchaseGiftCard from "../transactionData/vasClient/vasPurchaseGiftCard";
import vasElectricityStatus from "../transactionData/vasClient/vasElectricityStatus";
import vasAirtimeTopupStatus from "../transactionData/vasClient/vasAirtimeTopupStatus";
import vasGiftCardStatus from "../transactionData/vasClient/vasGiftCardStatus";
import vasPurchaseVoucher from "../transactionData/vasClient/vasPurchaseVoucher";
import vasVoucherStatus from "../transactionData/vasClient/vasVoucherStatus";
import vasCancelPurchase from "../transactionData/vasClient/vasCancelPurchase";
import vasListAllProducts from "../transactionData/vasClient/vasListAllProduct";
import vasListProducts from "../transactionData/vasClient/vasListProducts";
import style from "styled-components";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<i class="far fa-arrow-circle-down"></i>}
    {...props}
  />
))(({ theme }) => ({
  marginLeft: 0,
  justifyContent: "flex-start",
  border: "none",
  padding: 0,
  alignItems: "center",
  fontFamily: "Montserrat-Regular",
  textTransform: "uppercase",
  color: "#00C1FF",
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "#00C1FF",
    fontSize: 20,
    marginTop: -3,
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(0),
    padding: 0,
    flex: "unset",
    marginRight: 10,
    fontFamily: "Montserrat-Regular",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
}));

export default function TJTextAccordion() {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const Linkurl = style.div`
  position: relative;
   left: 3rem;
  top: 2rem;
  }
`;
  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panelad-content" id="panel1d-header">
          <h4>LIST ALL PRODUCTS</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This operation will list all of the products available over this
            API.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <Linkurl>
                GET https://vasdev.imbeko.live/v1/listAllProducts
              </Linkurl>
              <div className="body-container">
                <div className="code-container"></div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasListAllProducts.response, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <h4>LIST PRODUCTS</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This operation will list the products or other categories in the
            hierarchy under the category contained in the request.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <Linkurl>
                GET https://vasdev.imbeko.live/v1/listProducts/Digital%20Content
              </Linkurl>
              <div className="body-container">
                <div className="code-container"></div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasListProducts.response, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <h4>VALIDATE METER</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This operation allows the merchant to validate that the electricity
            or water meter number provided by the consumer is correct and if so
            to return the name of the meter owner.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <Linkurl>
                POST https://vasdev.imbeko.live/v1/validateMeter
              </Linkurl>

              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasValidateMeter.request, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasValidateMeter.response, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <h4>PURCHASE ELECTRICITY</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This operation allows a consumer to acquire a prepaid voucher for
            their electricity or water meter.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <Linkurl>
                POST https://vasdev.imbeko.live/v1/purchaseElectricity
              </Linkurl>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(
                          vasPurchaseElectricity.request,
                          null,
                          2
                        )}
                      </pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(
                          vasPurchaseElectricity.response,
                          null,
                          2
                        )}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <h4>ELECTRICITY STATUS</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This operation can be used to inquire about the status of a prepaid
            electricity or water transaction.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <Linkurl>
                POST https://vasdev.imbeko.live/v1/electricityStatus
              </Linkurl>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasElectricityStatus.request, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasElectricityStatus.response, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <h4>PURCHASE AIRTIME TOPUP</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This operation allows a consumer to acquire a data or SMS bundle or
            to purchase top-up credit.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <Linkurl>
                POST https://vasdev.imbeko.live/v1/purchaseAirtimeTopup
              </Linkurl>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(
                          vasPurchaseAirtimeTopup.request,
                          null,
                          2
                        )}
                      </pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(
                          vasPurchaseAirtimeTopup.response,
                          null,
                          2
                        )}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <h4>AIRTIME TOPUP STATUS</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This operation can be used to inquire about the status of a top-up
            transaction.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <Linkurl>
                POST https://vasdev.imbeko.live/v1/airtimeTopupStatus
              </Linkurl>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasAirtimeTopupStatus.request, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(
                          vasAirtimeTopupStatus.response,
                          null,
                          2
                        )}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel8"}
        onChange={handleChange("panel8")}
      >
        <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
          <h4>PURCHASE LOTTO</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This operation allows a consumer to enter South African National
            Lottery games.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <Linkurl>
                POST https://vasdev.imbeko.live/v1/purchaseLotto
              </Linkurl>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasPurchaseLotto.request, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasPurchaseLotto.response, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel9"}
        onChange={handleChange("panel9")}
      >
        <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
          <h4>PURCHASE GIFT CARD</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This operation allows a consumer to acquire a prepaid gift card or
            voucher for digital content.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <Linkurl>
                POST https://vasdev.imbeko.live/v1/purchaseGiftCard
              </Linkurl>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasPurchaseGiftCard.request, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasPurchaseGiftCard.response, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel10"}
        onChange={handleChange("panel10")}
      >
        <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
          <h4>GIFT CARD STATUS</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This operation can be used to inquire about the status of a digital
            content prepaid gift card or voucher transaction.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <Linkurl>
                POST https://vasdev.imbeko.live/v1/giftCardStatus
              </Linkurl>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasGiftCardStatus.request, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasGiftCardStatus.response, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel11"}
        onChange={handleChange("panel11")}
      >
        <AccordionSummary aria-controls="panel11d-content" id="panel11d-header">
          <h4>PURCHASE VOUCHER</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This operation allows a consumer to acquire a prepaid voucher for
            products such as airtime.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <Linkurl>
                POST https://vasdev.imbeko.live/v1/purchaseVoucher
              </Linkurl>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasPurchaseVoucher.request, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasPurchaseVoucher.response, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel12"}
        onChange={handleChange("panel12")}
      >
        <AccordionSummary aria-controls="panel12d-content" id="panel12d-header">
          <h4>VOUCHER STATUS</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This operation can be used to inquire about the status of a prepaid
            voucher transaction.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <Linkurl>
                POST https://vasdev.imbeko.live/v1/voucherStatus
              </Linkurl>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasVoucherStatus.request, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasVoucherStatus.response, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel13"}
        onChange={handleChange("panel13")}
      >
        <AccordionSummary aria-controls="panel13d-content" id="panel13d-header">
          <h4>CANCEL PURCHASE</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This operation will attempt to reverse a purchase operation and
            return any products to the VAS provider.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>

              <Linkurl>
                POST https://vasdev.imbeko.live/v1/cancelPurchase/123456
              </Linkurl>
              <div className="body-container">
                <div className="code-container"></div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasCancelPurchase.response, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
