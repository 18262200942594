import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import paymentIntent from "../transactionData/IPG/paymentIntent";
import session from "../transactionData/IPG/session";
import transaction from "../transactionData/IPG/transaction";
import threeDSLookup from "../transactionData/IPG/3dsLookup";
import threeDSValidate from "../transactionData/IPG/3dsValidate";
import authorize from "../transactionData/IPG/authorize";
import settle from "../transactionData/IPG/settle";
import reverse from "../transactionData/IPG/reverse";
import refund from "../transactionData/IPG/refund";
// Assets
const IPGTab = withStyles((theme) => ({
  root: {
    textTransform: "uppercase",
    minWidth: 130,
    width: 130,
    height: 60,
    fontWeight: 800,
    marginRight: theme.spacing(4),
    fontFamily: "Montserrat-Bold",
    background: "#00C1FF33",
    wordBreak: "keep-all",
    marginBottom: 20,
    borderRadius: 10,
    color: "#000000",
    "&:hover": {
      color: "#00C1FF",
      background: "#034EA2",
    },
    "&$selected": {
      color: "#00C1FF",
      background: "#034EA2",
    },
    "&:focus": {
      color: "#00C1FF",
    },
  },

  selected: {},
}))((props) => <Tab {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography style={{ color: "#000" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {},
}));

export default function IPGTabs({ data }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        textColor="primary"
        variant="fullWidth"
        aria-label="transaction-types"
      >
        {data.transactionTypes.map((tranType, index) => {
          return (
            <IPGTab label={tranType.type} key={index} {...a11yProps(index)} />
          );
        })}
      </Tabs>

      {data.transactionTypes.map((tranType, index) => {
        return (
          <TabPanel key={index} value={value} index={index}>
            {/* {tranType.description} */}

            {tranType.type === "Payment Intent" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container2">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(paymentIntent.request, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(paymentIntent.response, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>*Example shows basic use case with sample values.</p>
              </div>
            )}
            {tranType.type === "Session" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>{JSON.stringify(session.request, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre style={{ whiteSpace: "break-spaces" }}>
                            {JSON.stringify(session.response, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>*Example shows basic use case with sample values.</p>
              </div>
            )}
            {tranType.type === "Transaction" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(transaction.request, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(transaction.response, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>*Example shows basic use case with sample values.</p>
              </div>
            )}

            {tranType.type === "3DS Lookup" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre style={{ whiteSpace: "break-spaces" }}>
                            {JSON.stringify(threeDSLookup.request, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(threeDSLookup.response, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>*Example shows basic use case with sample values.</p>
              </div>
            )}
            {tranType.type === "3DS Validate" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre style={{ whiteSpace: "break-spaces" }}>
                            {JSON.stringify(threeDSValidate.request, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(threeDSValidate.response, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>*Example shows basic use case with sample values.</p>
              </div>
            )}
            {tranType.type === "Authorize" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(authorize.request, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>
                            {JSON.stringify(authorize.response, null, 2)}
                          </pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>*Example shows basic use case with sample values.</p>
              </div>
            )}
            {tranType.type === "Settle" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>{JSON.stringify(settle.request, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>{JSON.stringify(settle.response, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>*Example shows basic use case with sample values.</p>
              </div>
            )}
            {tranType.type === "Reverse" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>{JSON.stringify(reverse.request, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>{JSON.stringify(reverse.response, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>*Example shows basic use case with sample values.</p>
              </div>
            )}
            {tranType.type === "Refund" && (
              <div style={{ marginTop: 20 }}>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        <div>
                          <pre>{JSON.stringify(refund.request, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                    <div>
                      <code>
                        <div>
                          <pre>{JSON.stringify(refund.response, null, 2)}</pre>
                        </div>
                      </code>
                    </div>
                  </div>
                </div>
                <p>*Example shows basic use case with sample values.</p>
              </div>
            )}
          </TabPanel>
        );
      })}
    </div>
  );
}
