const giftcardIssue = {
  request: {
    tran: "GIFTCARD ISSUE",
    amount: 0,
    reference: "d081582e-47e6-4a9a-b590-893202527821",
    pos: "57290093",
    store: "RESTSIM00000001",
    chain: "Test",
  },

  response: {
    authID: "123456",
    tran: "GIFTCARD ISSUE",
    response_code: "00",
    response_text: "Approved",
    amount: 500,
    reference: "d081582e-47e6-4a9a-b590-893202527821",
    seq: "000044",
    rrn: "000046241042",
    pos: "57290093",
    store: "RESTSIM00000001",
    card: "188451******7052",
    card_name: "Playstation Store GiftCard",
    receipt: [],
    data: [],
  },
};

export default giftcardIssue;
