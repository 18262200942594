const eFuelPreAuth = {
  request: {
    tran: "EFUEL PRE-AUTH",
    amount: 0,
    reference: "61d8d5e5-dbe0-4f38-bcda-01e5fdf8bd21",
    pos: "57290093",
    store: "RESTSIM00000001",
    chain: "Test",
    card: "500857*********0028",
  },

  response: {
    tran: "EFUEL PRE-AUTH",
    response_code: "00",
    response_text: "Approved",
    amount: 0,
    reference: "61d8d5e5-dbe0-4f38-bcda-01e5fdf8bd21",
    seq: "000003",
    rrn: "000003190060",
    pos: "57290093",
    store: "RESTSIM00000001",
    card: "500857*********0028",
    card_name: "FUEL TEST CARD",
    sign: true,
    receipt: [],
    data: [],
    allowedProducts: "AllowedProducts",
  },
};

export default eFuelPreAuth;
