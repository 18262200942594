const vasVoucherStatus = {
  request: {
    tranRef: "1a5fe454-14f5-4f5e-8957-123456789abd",
    cardAcceptor: "1234567890     ",
    cardAcceptorTerminal: "12345678",
    currency: "710",
    productId: "123ZA5",
    originalRef: "1a5fe454-14f5-4f5e-8957-123456789abd",
    amount: 5555,
  },

  response: {
    activateMessage: "CThe body||NOn multiple lines",
    expiryDate: "20100610",
    pin: "123456789012345678",
    receiptFooter: "CThe end",
    receiptHeader: "EHeader Text",
    reprint: true,
    responseCode: "00",
    responseText: "Approved",
    serialNumber: "6003796822292",
    supportMessage: "NPress some buttons||Nto recharge",
  },
};

export default vasVoucherStatus;
