import React from 'react';

// React Router
import { useParams } from 'react-router';

// Base Layout Components
import ApiNavigation from '../components/ApiNavigation';
import TJPageHeader from '../components/TJPageHeader';

// Helper Methods
import apis from '../helpers/apis';

// API cpontent
import JunctionPosPage from './JunctionPosPage';
import QRCodePage from './QRCodePage';
import VasProviderPage from './VasProviderPage';
import VasClientPage from './VasClientPage';
import OpenPaymentPage from './OpenPaymentPage';
import CardPaymentPage from './CardPaymentPage';
import ECommercePage from './ECommercePage';
import PaymentGateway from './PaymentGateway';
import DirectIntergration from './DirectIntergrateionPage';
import IPGHomePage from './IPGHomePage';
import IntergrationLandingPage from './IntergrationLandingPage';
import PayTable from './Pay@Table';
import PromptsAndNotifications from './Prompts&Notifications';
import TransactionPage from './TransactionPage';
import FAQPage from './FaqPage';
import PartyUseCase from './3rdPartyUseCasePage';

const ApiPage = ({ keycloak }) => {
  const { id } = useParams();
  const currentAPI = apis.find((api) => id === api.slug);
  const activeAPI = (api) => {
    switch (api) {
      case 'junction@POS-rest-api':
        return <JunctionPosPage />;
      case 'qrcode-api':
        return <QRCodePage />;
      case 'pos-intergration':
        return <IntergrationLandingPage />;
      case 'vas-provider-api':
        return <VasProviderPage />;
      case 'openpayment-api':
        return <OpenPaymentPage />;
      case 'card-payment-api':
        return <CardPaymentPage />;
      case 'ecommerce-api':
        return <ECommercePage />;
      case 'vas-client-api':
        return <VasClientPage />;
      case 'payment-gateway-api':
        return <PaymentGateway />;
      case 'direct-intergration-api':
        return <DirectIntergration />;
      case 'internet-payment-gateway':
        return <IPGHomePage />;
      case 'pay@table':
        return <PayTable />;
      case 'prompts-&-notifications':
        return <PromptsAndNotifications />;
      case 'transactions':
        return <TransactionPage />;
      case 'faq':
        return <FAQPage />;
      case '3rd-party-use-cases':
        return <PartyUseCase />;
      default:
        return <JunctionPosPage />;
    }
  };

  return (
    <>
      <ApiNavigation pageTitle={currentAPI.name} keycloak={keycloak} />
      <TJPageHeader title={currentAPI.name} />
      {activeAPI(currentAPI.slug)}
    </>
  );
};

export default ApiPage;
