const returns = {
  request: {
    tran: "RETURN",
    amount: 500,
    reference: "6f51d8bf-3ea6-48a6-ac63-86327251f30c",
    pos: "57290093",
    store: "RESTSIM00000001",
    chain: "Test",
  },

  response: {
    authID: "977880",
    tran: "RETURN",
    response_code: "00",
    response_text: "Approved",
    amount: 500,
    reference: "6f51d8bf-3ea6-48a6-ac63-86327251f30c",
    seq: "000046",
    rrn: "000046241042",
    pos: "57290093",
    store: "RESTSIM00000001",
    card: "402167******7052",
    card_name: "Investec Platinum",
    card_entry: "S",
    sign: "false",
    receipt: [],
    data: [],
  },
};

export default returns;
