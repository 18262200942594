const session = {
  request: {
    redirectSuccessUrl: "https://www.google.com/",
    redirectFailedUrl: "https://www.bing.com/",
    transaction: {
      paymentIntentId: "{{paymentIntentID}}",
      amount: 94.99,
      currency: "ZAR",
      cardItems: [
        {
          quantity: 2,
          ref: "tv",
          description: "Hisense 30inch TV",
          amount: 5000,
        },
        {
          quantity: 2,
          ref: "drill",
          description: "Bosch 12v Drill",
          amount: 2500,
        },
        {
          quantity: 5,
          ref: "ref",
          description: "Canon inkjet printer",
          amount: 1000,
        },
      ],
      merchantId: "f3d69c2f-38c9-43eb-9946-809bd9a5ff04",
      profileId: "fb9aca6d-3614-4cd1-b1dc-8e4b5056c7a7",
      merchantRef: "Demo ref",
    },
  },

  response: {
    ipgwSId: "bc437031-5c67-43f5-bad1-06225a8e9e4c",
    redirectUrl:
      "https://uat-ipgw.imbeko.live/payments?ipgwSId=bc437031-5c67-43f5-bad1-06225a8e9e4c",
  },
};

export default session;
