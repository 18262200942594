import React from 'react';

// React Router
import { useHistory } from 'react-router-dom';

// Base Layout Components
import { TJCTACard } from 'tj-components';
import Grid from '@mui/material/Grid';

// Data import
import apis from '../helpers/apis';

// Layout helpers

import styled, { keyframes } from 'styled-components';
import { fadeInUp, fadeInLeft, fadeInRight } from 'react-animations';
import jPosimg from '../static/images/jpospmain.png';
import jPosLogo from '../static/images/jposlogo.png';
import POS1 from '../static/images/POS1.png';
import POS2 from '../static/images/POS2.png';

import TJTextAccordion from '../components/TJTextAccordion';

const fadeInUpAnimation = keyframes`${fadeInUp}`;
const fadeInRightAnimation = keyframes`${fadeInRight}`;
const fadeInLeftAnimation = keyframes`${fadeInLeft}`;

const AnimationContainer = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const AnimationContainerMobile = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    display: block;
  }
  @media screen and (min-width: 960px) {
    display: none;
  }
`;

const FadeInUp = styled.div`
  top: 35px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: -40px;
  text-align: center;
  z-index: 1;
  position: absolute;
  animation: 1s ${fadeInUpAnimation};
  width: 345px;

  @media screen and (min-width: 1025px) {
    width: 400px;
  }
  img {
    width: 300px;
    @media screen and (min-width: 600px) {
      width: 345px;
    }
    @media screen and (min-width: 1025px) {
      width: 400px;
    }
  }
`;

const FadeInRight = styled.div`
  bottom: 10%;
  right: 10%;
  z-index: 2;
  position: absolute;
  animation: 1s ${fadeInRightAnimation};

  img {
    width: 120px;
    @media screen and (min-width: 600px) {
      width: 150px;
    }
  }
`;

const FadeInLeft = styled.div`
  animation: 0.5s ${fadeInLeftAnimation};
  margin-top: 15px;
  width: 300px;
  height: 300px;
  border-radius: 15px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(0, 130, 217, 1) 58%,
    rgba(0, 193, 255, 1) 93%
  );
  @media screen and (min-width: 600px) {
    width: 345px;
    height: 345px;
  }
  @media screen and (min-width: 1025px) {
    width: 400px;
    height: 400px;
  }
`;

const IntergrationLandingPage = () => {
  const history = useHistory();
  const currentApi = apis.filter(
    (api) =>
      api.name === 'Transactions (current REST API page)' ||
      api.name === 'Pay@Table' ||
      api.name === 'Prompts & Notifications' ||
      api.name === '3rd Party Use Cases' ||
      api.name === 'FAQ'
  );

  return (
    <Grid container spacing={0} xs={12}>
      <Grid container className="tj-grid-container" id="overview">
        <Grid xs={12} md={6} item>
          <AnimationContainerMobile>
            <FadeInUp>
              <img src={jPosimg} alt="Junction@POS" />
              <FadeInRight>
                <img
                  style={{ width: 120 }}
                  src={jPosLogo}
                  alt="Junction@POS Logo"
                />
              </FadeInRight>
            </FadeInUp>
            <FadeInLeft />
          </AnimationContainerMobile>
          <h2>Overview</h2>
          <p>
            <strong>
              Transaction Junction offers a complete integrated payments and
              electronics product procurement application for deployment in
              retail environments.
            </strong>
          </p>
          <p>
            Junction@POS is a java application responsible for managing
            communication between the POS, supported PIN Entry Devices (PED’s)
            and the payment switch which manages communications with the various
            banks.
          </p>
          <p>
            Junction@POS supports two transactions “modes”, POS initiated and
            PED initiated.
          </p>
          <p>
            POS initiated transactions are the typical retail lane model where
            items are rung up on the POS and a request to start a payment
            transaction is then sent to Junction@POS which drives the PED,
            manages the online authorization, and responds back to the POS with
            the transaction outcome. API calls for this model are described in
            the Transactions section below.
          </p>

          <br />
        </Grid>
        <Grid
          xs={6}
          md={6}
          item
          style={{
            position: 'relative',
          }}
        >
          <AnimationContainer>
            <FadeInUp>
              <img src={jPosimg} alt="Junction@POS" />
              <FadeInRight>
                <img
                  style={{ width: 120 }}
                  src={jPosLogo}
                  alt="Junction@POS Logo"
                />
              </FadeInRight>
            </FadeInUp>
            <FadeInLeft />
          </AnimationContainer>
        </Grid>
        <Grid xs={12} item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={POS1} />
          </div>
          <p>
            PED initiated transaction turn this flow around and Junction@POS
            will query the POS to get the details of the transaction. A typical
            use case for this scenario is a restaurant environment where a
            waiter will start the payment process at the table rather than walk
            to a counter POS. API calls for this model are described in the
            Pay@Table section below.
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={POS2} />
          </div>
          <p>
            Junction@POS is supported on Windows with Microsoft SQL Express or
            SQL Server and Ubuntu LTS with SQL Express for Linux.
          </p>
        </Grid>
        <div className="hr-container">
          <hr className="small-hr" />
        </div>
        <Grid xs={12} item>
          <h4 className="standalone-h4">Prerequisite</h4>
          <p>
            Please note that while this resource encompasses the entire
            Transaction Junction offering, it is not necessarily required that
            development be done for the entire suite of offerings. The
            integration scope will be determined by the client requirements in
            the form of desired transaction types, value added services,
            additional payment types and recon offerings.
          </p>
          <p>
            These should be outlined in a checklist that was completed during a
            kick off meeting. If you no longer have access to this document
            please reach out to posintegrations@switch.tj.
          </p>
          <p>
            Should you be exploring integration without specific client
            requirements in mind, be mindful that the scope of your development
            should be guided by prospective client requirements.
          </p>

          <div className="hr-container">
            <hr className="small-hr" />
          </div>
          <h4 className="standalone-h4">PCI Compliance</h4>
          <p>
            Junction@POS is P2PE 2.0 compliant. Enter “Transaction Junction” in
            the search box at the following link for details: <br />
            <a
              className="body-link"
              href="https://www.pcisecuritystandards.org/assessors_and_solutions/point_to_point_encryption_solutions?agree=true"
              target="_blank"
              rel="noreferrer"
            >
              Official PCI Security Standards Council Site - Verify PCI
              Compliance, Download Data Security and Credit Card Security
              Standards
            </a>
          </p>
          <h4 className="standalone-h4">Supported Deployment Models</h4>
          <p>
            If there is any uncertainty on which model to use, please discuss
            with your Transaction Junction representative.
          </p>
          <TJTextAccordion />
        </Grid>
      </Grid>
      <div className="tj-main">
        {' '}
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          className="container selection-cards"
          id="more"
        >
          {currentApi.map((api, index) => {
            return (
              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                lx={4}
                key={index}
                style={{ display: 'flex' }}
                onClick={() =>
                  history.push({
                    pathname: `/${api.slug}`,
                  })
                }
              >
                <TJCTACard
                  width="100%"
                  title={api.name}
                  height="auto"
                  minWidth="150px"
                  maxWidth="auto"
                  description={api.excerpt}
                  iconClass={api.icon}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Grid>
  );
};

export default IntergrationLandingPage;
