const deposit = {
  request: {
    tran: "DEPOSIT",
    amount: 1000,
    store: "RESTSIM00000001",
    pos: "57290093",
    chain: "Test",
    reference: "0611623e-929b-4cf1-a67b-636e287d262b",
  },

  response: {
    response_code: "00",
    response_text: "Approved",
    authID: "630317",
    amount: 1000,
    reference: "0611623e-929b-4cf1-a67b-636e287d262b",
    pos: "57290093",
    store: "RESTSIM00000001",
    tran: "DEPOSIT",
    rrn: "000040290093",
    data: [],
    card: "476173******0010",
    card_name: "VISA CTLS",
    seq: "000040",
    sign: false,
    acc_type: "Credit",
    card_entry: "I",
    cryptogram_type: "TC",
    cryptogram: "4AD8878D11",
    tvr: "0000000000",
    aid: "A0000000041010",
    tsi: "F800",
    cvm_results: "440302",
    iad: "0810A74213023000000000000000000000FF",
    pin_statement: "Pin Verified",
    receipt: [],
  },
};

export default deposit;
