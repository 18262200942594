const cashBack = {
  request: {
    tran: "CASH BACK",
    amount: 5000,
    store: "RESTSIM00000001",
    pos: "57290093",
    chain: "Test",
    cash: 5000,
    reference: "05ec74c8-ed2f-4a5a-83c8-dc4049877a9e",
  },

  response: {
    response_code: "00",
    response_text: "Approved",
    authID: "762471",
    amount: 5000,
    reference: "05ec74c8-ed2f-4a5a-83c8-dc4049877a9e",
    pos: "57290093",
    store: "RESTSIM00000001",
    tran: "CASH BACK",
    rrn: "000016290093",
    data: [],
    card: "476173******0010",
    card_name: "VISA CTLS",
    seq: "000016",
    sign: false,
    cash: 500,
    ext_payment: "00",
    acc_type: "Credit",
    card_entry: "I",
    cryptogram_type: "TC",
    cryptogram: "4AD8878D11",
    tvr: "0000000000",
    aid: "A0000000041010",
    tsi: "F800",
    cvm_results: "440302",
    iad: "0810A74213023000000000000000000000FF",
    pin_statement: "Pin Verified",
    receipt: [],
  },
};

export default cashBack;
