import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import TJSwagger from "../components/TJSwagger";

// APIs
import vasapiYAML from "../yaml/vas-provider-junction.yaml";

// Layout helpers
import styled, { keyframes } from "styled-components";
import { fadeInUp, fadeInLeft } from "react-animations";
import VASimg from "../static/images/VAS Provider.jpg";
import VasTextAccordion from "../components/VasTextAccordion";

const fadeInUpAnimation = keyframes`${fadeInUp}`;
const fadeInLeftAnimation = keyframes`${fadeInLeft}`;

const AnimationContainer = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const AnimationContainerMobile = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    display: block;
  }
  @media screen and (min-width: 960px) {
    display: none;
  }
`;

const FadeInUp = styled.div`
  top: 35px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: -40px;
  text-align: center;
  z-index: 1;
  position: absolute;
  animation: 1s ${fadeInUpAnimation};
  width: 345px;

  @media screen and (min-width: 1025px) {
    width: 400px;
  }
  img {
    width: 300px;
    @media screen and (min-width: 600px) {
      width: 345px;
    }
    @media screen and (min-width: 1025px) {
      width: 400px;
    }
  }
`;

const FadeInLeft = styled.div`
  animation: 0.5s ${fadeInLeftAnimation};
  margin-top: 15px;
  width: 300px;
  height: 300px;
  border-radius: 15px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(0, 130, 217, 1) 58%,
    rgba(0, 193, 255, 1) 93%
  );
  @media screen and (min-width: 600px) {
    width: 345px;
    height: 345px;
  }
  @media screen and (min-width: 1025px) {
    width: 400px;
    height: 400px;
  }
`;

const VasProviderPage = () => {
  return (
    <Grid container spacing={0}>
      <Grid container className="tj-grid-container" id="overview">
        <Grid xs={12} md={6} item>
          <AnimationContainerMobile>
            <FadeInUp>
              <img src={VASimg} alt="Vas Provider API image" />
            </FadeInUp>
            <FadeInLeft />
          </AnimationContainerMobile>

          <h2>Overview</h2>

          <p>
            Transaction Junction enables merchants to offer a range of digital
            products and services – such as prepaid airtime and vouchers – to
            consumers. These products/services are typically grouped under the
            term "value-added services” (VAS). This API enables a VAS
            provider/aggregator to integrate to Transaction Junction’s IMBEKO
            system so that the provider can offer their products to Transaction
            Junction’s merchants for sale to shoppers.
          </p>
          <p>
            This API allows for listing available products, purchasing products,
            and reversing unsuccessful transactions.
          </p>
          <p>
            Please note that while this resource encompasses several operations
            and several types of VAS product, it is not necessary for an
            integrator to implement all operations or all services. Note too
            that this interface is not concerned with the redemption of digital
            products or services.
          </p>
          <p>
            The scope of development will be outlined during a kick-off meeting
            between Transaction Junction and the integrator. For more
            information on this process, contact vas-integration@switch.tj.
          </p>
        </Grid>

        <Grid
          xs={6}
          md={6}
          item
          style={{
            position: "relative",
          }}
        >
          <AnimationContainer>
            <FadeInUp>
              <img
                src={VASimg}
                alt="Vas Provider API image"
                style={{ height: "400px", borderRadius: "15px" }}
              />
            </FadeInUp>
            <FadeInLeft />
          </AnimationContainer>
        </Grid>
      </Grid>

      <Grid
        item
        style={{
          background:
            "transparent linear-gradient(270deg, #0082D9 0%, #00C1FF 100%) 0% 0% no-repeat padding-box",

          color: "#fff",
          margin: "0 auto",
        }}
        className="tj-grid-container"
        id="implementation-guide"
      >
        <h2>Implementation Guide</h2>
        <p>
          Once a project has been lodged with Transaction Junction, development
          against the API can be performed by the integrator.
        </p>
        <p>
          The API is published as an OpenAPI 3 YAML document,{" "}
          <a href="#page-api" className="link">
            which is available on this page.
          </a>
        </p>
        <p>
          Using the OpenAPI 3 YAML format implies that much of the server-side
          code can be generated using the OpenAPI toolset encompassing a wide
          variety of programming languages and application frameworks. This
          should significantly reduce the effort required to complete the
          integration because it will largely eliminate any syntactic issues in
          the exchange of messages between the two parties.
        </p>
        <p>
          There is a wealth of information relating to the use of YAML
          documents.{" "}
          <a
            href="https://swagger.io"
            target="_blank"
            rel="nofollow"
            className="link"
          >
            Swagger
          </a>{" "}
          is a good place to start. To view the YAML document, a good option is
          the online Swagger Editor. To generate a Java server, C# server, or Go
          server from the YAML document, a good option is the{" "}
          <a
            href="https://github.com/OpenAPITools/openapi-generator"
            target="_blank"
            rel="nofollow"
            className="link"
          >
            OpenAPITools generator.
          </a>{" "}
          To generate tests from the YAML document, a good option is{" "}
          <a
            href=" https://learning.postman.com/docs/integrations/available-integrations/working-with-openAPI/"
            target="_blank"
            rel="nofollow"
            className="link"
          >
            Postman.
          </a>
        </p>
        <p>
          When the initial testing is complete to the satisfaction of both
          parties, user acceptance testing will be performed before piloting the
          solution or taking it live.
        </p>
        <p>
          Should you require any assistance, contact{" "}
          <a href="mailto:vas-integration@switch.tj" className="link">
            vas-integration@switch.tj
          </a>
          .
        </p>
      </Grid>
      <Grid
        item
        xs={12}
        id="operations"
        className="tj-grid-container"
        style={{
          background: "#00C1FF1A",
          color: "#00C1FF",
        }}
      >
        <h2 style={{ color: "#000" }}>Operations</h2>

        <VasTextAccordion />
      </Grid>
      <Grid item className="tj-grid-container" id="page-api">
        <TJSwagger url={vasapiYAML} />
      </Grid>
    </Grid>
  );
};

export default VasProviderPage;
