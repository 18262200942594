const vasProvCancel = {
  request: {
    tranRef: "1a5fe454-14f5-4f5e-8957-123456789abd",
  },

  response: {
    responseCode: "00",
    responseText: "Transaction successful",
  },
};

export default vasProvCancel;
