const reversal = {
  request: {
    reference: "de35f34c-59b7-4d74-854f-55a7da630585",
    original_reference: "f40030cc-c3af-4aee-a2e2-09c080fbf498",
  },

  response: {
    reference: "de35f34c-59b7-4d74-854f-55a7da630585",
    response_code: "00",
  },
};

export default reversal;
