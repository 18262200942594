import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import vasAllProd from "../transactionData/vasProvider/vasProvListAllProd";
import vasTransPost from "../transactionData/vasProvider/vasProvVoucherTransPost";
import vasTransGet from "../transactionData/vasProvider/vasProvVoucherTransGet";
import vasCancel from "../transactionData/vasProvider/vasProvCancelTransPost";
import vasTopUpPost from "../transactionData/vasProvider/vasProvTopupPost";
import vasTopUpGet from "../transactionData/vasProvider/vasProvTopupGet";
import style from "styled-components";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<i class="far fa-arrow-circle-down"></i>}
    {...props}
  />
))(({ theme }) => ({
  marginLeft: 0,
  justifyContent: "flex-start",
  border: "none",
  padding: 0,
  alignItems: "center",
  fontFamily: "Montserrat-Regular",
  textTransform: "uppercase",
  color: "#00C1FF",
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "#00C1FF",
    fontSize: 20,
    marginTop: -3,
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(0),
    padding: 0,
    flex: "unset",
    marginRight: 10,
    fontFamily: "Montserrat-Regular",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
}));

const Linkurl = style.div`
position: relative;
 left: 3rem;
top: 2rem;
}
`;
export default function TJTextAccordion() {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panelad-content" id="panel1d-header">
          <h4>LIST ALL PRODUCTS (GET)</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This operation will list all the products available to the specified
            customer.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <Linkurl>
                GET
                https://vasproviderdev.imbeko.live/v1/listAllProducts?customerId=TestRetailer
              </Linkurl>
              <div className="body-container">
                <div className="code-container"></div>
                <div>
                  <code>
                    <div>
                      <pre>{JSON.stringify(vasAllProd.response, null, 2)}</pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <h4>VOUCHER TRANSACTION (POST)</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>This operation allows for the purchase of a prepaid voucher.</p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <Linkurl>
                POST https://vasproviderdev.imbeko.live/v1/voucherTransaction
              </Linkurl>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>{JSON.stringify(vasTransPost.request, null, 2)}</pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasTransPost.response, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <h4>VOUCHER TRANSACTION (GET)</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This operation represents a query to see if a prepaid voucher
            purchase transaction succeeded or not. It will return the same
            information that the original purchase would have done.{" "}
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <Linkurl>
                GET
                https://vasproviderdev.imbeko.live/v1/voucherTransaction?tranRef=1a5fe454-14f5-4f5e-8957-123456789abd
              </Linkurl>
              <div className="body-container">
                <div className="code-container"></div>
                <div>
                  <code>
                    <div>
                      <pre>{JSON.stringify(vasTransGet.response, null, 2)}</pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <h4>CANCEL VOUCHER TRANSACTION (POST)</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This operation will attempt to reverse a prepaid voucher purchase
            transaction and return any products to the supplier.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <Linkurl>
                POST
                https://vasproviderdev.imbeko.live/v1/cancelVoucherTransaction/1a5fe454-14f5-4f5e-8957-123456789abd
              </Linkurl>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>{JSON.stringify(vasCancel.request, null, 2)}</pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>{JSON.stringify(vasCancel.response, null, 2)}</pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <h4>TOP-UP TRANSACTION (POST)</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This operation allows for the purchase of airtime top-up for a
            specified mobile number.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <Linkurl>
                POST https://vasproviderdev.imbeko.live/v1/topUpTransaction
              </Linkurl>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>{JSON.stringify(vasTopUpPost.request, null, 2)}</pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>
                        {JSON.stringify(vasTopUpPost.response, null, 2)}
                      </pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <h4>TOP-UP TRANSACTION (GET)</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            This operation represents a query to see if an airtime top-up
            purchase transaction succeeded or not. It will return the same
            information that the original purchase would have done.
          </p>
          <div style={{ marginTop: 20 }}>
            <div className="code-box-container">
              <div className="header-container">
                <div>Request</div>
                <div>Response</div>
              </div>
              <Linkurl>
                GET
                https://vasproviderdev.imbeko.live/v1/topUpTransaction?tranRef=1a5fe454-14f5-4f5e-8957-123456789abd&phoneNumber=0847467416
              </Linkurl>
              <div className="body-container">
                <div className="code-container">
                  <code>
                    <div>
                      <pre>{JSON.stringify(vasTopUpGet.request, null, 2)}</pre>
                    </div>
                  </code>
                </div>
                <div>
                  <code>
                    <div>
                      <pre>{JSON.stringify(vasTopUpGet.response, null, 2)}</pre>
                    </div>
                  </code>
                </div>
              </div>
            </div>
            <p>*Example shows basic use case with sample values.</p>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
