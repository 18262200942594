const vasElectricityStatus = {
  request: {
    tranRef: "1a5fe454-14f5-4f5e-8957-123456789abd",
    cardAcceptor: "1234567890     ",
    cardAcceptorTerminal: "12345678",
    currency: "710",
    originalRef: "1a5fe454-14f5-4f5e-8957-123456789abd",
    meterNumber: "01050020001",
    amount: 5555,
  },
  response: {
    custMsg: "ENQUIRIES: PHONE 0801111556 OR 3589999",
    customer: {
      name: "MR. J SMITH",
      standNumber: "00071, PRETORIA GARDENS",
      area: "PRETORIA GARDENS",
    },
    datetime: "2019-10-11 08:59:30",
    meter: {
      number: "01050020001",
      SGC: "100646",
      KRN: "1",
      TI: "04",
      ALG: "07",
      TT: "02",
    },
    receipt: "15707/0777170",
    receiptNumber: "15707/0777170",
    reference: "5cf936f08f82442a9d523776b36f7ad6",
    responseCode: "00",
    responseText: "Approved",
    tariffName: "Domestic Single Phase",
    tokens: [
      {
        number: "08593020191011085930",
        value: "4250",
        tax: "750",
        units: "57.50",
        description: "Electricity Credit",
        type: "PaidToken",
        supplierReceipt: "0777170",
        receiptNumber: "0777170",
        sortOrder: 1,
      },
    ],
    transactionTypeId: "194",
    utility: "City of Tshwane",
    vatNumber: "4000142267",
    reprint: true,
  },
};

export default vasElectricityStatus;
