export const cardPayAuthTrans = {
  request: {
    header: {
      messageFunction: "FAUQ",
    },
    authorisationRequest: {
      environment: {
        acquirer: {
          identification: "00000000002",
        },
        merchant: {
          identification: "123456789123456",
          locationAndContact: {
            addressLine: ["Acme Traders Small Str"],
            townName: "Cape Town",
            countrySubDivision: ["WC"],
            countryCode: "ZA",
          },
        },
        pointOfInteraction: {
          identification: "ATS10101",
          capabilities: {
            pinLengthCapabilities: 12,
          },
        },
        posDataCode: {
          cardPresentment: {
            availablePresentmentModes: [6],
            presentmentType: "0",
            availableDataCaptureModes: 0,
          },
          cardholderAuthentication: {
            availableAuthenticationModes: [1],
            actualAuthenticationMode: "14",
            authenticatingAgent: 0,
          },
          terminalCharacteristics: {
            terminalType: "0",
            terminalOperator: "0",
            availableCardRetentionModes: "0",
            terminalLocation: "0",
            terminalAttended: "0",
            availableOutputModes: "0",
          },
        },
        card: {
          plainCardData: {
            pan: "493805******7383",
            expiryDate: "2022-05",
            cardSequenceNumber: "320",
          },
          serviceOption: "100",
        },
      },
      context: {
        cardPresent: true,
        cardHolderPresent: true,
        onlineContext: true,
        cardDataEntryMode: "CTLS",
      },
      transaction: {
        merchantCategoryCode: "1234",
        transactionIdentification: {
          transactionDateTime: "2022-08-18T13:01:48.289Z",
          transactionReference: "130536095b8842cf8ecc6a119870aca0",
        },
        transactionType: "CRDP",
        transactionDetails: {
          currency: "710",
          /* prettier-ignore */
          totalAmount: 160.00,
          accountType: "CHCK",
        },
      },
    },
  },
  response: {
    header: {
      messageFunction: "FAUP",
    },
    authorisationResponse: {
      environment: {
        acquirer: {
          identification: "00000000002",
        },
        merchant: {
          identification: "123456789123456",
          locationAndContact: {
            addressLine: ["Acme Traders Small Str "],
            townName: "Cape Town    ",
            countrySubDivision: ["WC"],
            countryCode: "ZA",
          },
        },
        pointOfInteraction: {
          identification: "ATS10101",
          capabilities: {
            pinLengthCapabilities: 12,
          },
        },
        card: {
          plainCardData: {
            pan: "493805******7383",
            expiryDate: "2022-05",
            cardSequenceNumber: "320",
            track1Data: null,
            track2Data: null,
            cardholderName: null,
          },
          maskedPan: null,
          serviceOption: "100",
        },
        paymentToken: null,
        cardholder: null,
        posDataCode: null,
      },
      transaction: {
        authorisationResult: null,
        transactionType: "CRDP",
        additionalService: null,
        merchantCategoryCode: "1234",
        transactionIdentification: {
          transactionDateTime: "2022-08-18T13:01:48.000+00:00",
          transactionReference: "130536095b8842cf8ecc6a119870aca0",
        },
        originalTransaction: null,
        transactionDetails: {
          currency: "710",
          /* prettier-ignore */
          totalAmount: 160.00,
          detailedAmount: null,
          accountType: "CHCK",
          iccRelatedData: null,
          additionalAmounts: null,
        },
        balance: null,
      },
      transactionResponse: {
        authorisationResult: {
          response: "APPR",
          responseReason: null,
        },
        transactionType: null,
        additionalService: null,
        merchantCategoryCode: null,
        transactionIdentification: null,
        originalTransaction: null,
        transactionDetails: null,
        balance: null,
      },
      supplementaryData: null,
    },
  },
};
