import React, { useState } from "react";

import { Grid } from "@material-ui/core";

import TJSwagger from "../components/TJSwagger";

// APIs
import vasapiYAML from "../yaml/vas-client-junction.yaml";
import junctionPOSData from "../content/Junction@POS/content";

// Layout helpers
import MaterialTable from "material-table";
import { tableIcons } from "../helpers/tableHelpers";
import styled, { keyframes } from "styled-components";
import { fadeInUp, fadeInLeft } from "react-animations";
import VASimg from "../static/images/VAS Client.jpg";
import AdditionalResourceImg from "../static/images/additional-resources-bg.png";
import VasClientTextAccordion from "../components/VasClientTextAccordion";

const fadeInUpAnimation = keyframes`${fadeInUp}`;
const fadeInLeftAnimation = keyframes`${fadeInLeft}`;

const AnimationContainer = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const AnimationContainerMobile = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    display: block;
  }
  @media screen and (min-width: 960px) {
    display: none;
  }
`;

const FadeInUp = styled.div`
  top: 35px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: -40px;
  text-align: center;
  z-index: 1;
  position: absolute;
  animation: 1s ${fadeInUpAnimation};
  width: 345px;

  @media screen and (min-width: 1025px) {
    width: 400px;
  }
  img {
    width: 300px;
    @media screen and (min-width: 600px) {
      width: 345px;
    }
    @media screen and (min-width: 1025px) {
      width: 400px;
    }
  }
`;

const FadeInLeft = styled.div`
  animation: 0.5s ${fadeInLeftAnimation};
  margin-top: 15px;
  width: 300px;
  height: 300px;
  border-radius: 15px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(0, 130, 217, 1) 58%,
    rgba(0, 193, 255, 1) 93%
  );
  @media screen and (min-width: 600px) {
    width: 345px;
    height: 345px;
  }
  @media screen and (min-width: 1025px) {
    width: 400px;
    height: 400px;
  }
`;

const VasClientPage = () => {
  return (
    <Grid container spacing={0}>
      <Grid container className="tj-grid-container" id="overview">
        <Grid xs={12} md={6} item>
          <AnimationContainerMobile>
            <FadeInUp>
              <img src={VASimg} alt="Vas Client API image" />
            </FadeInUp>
            <FadeInLeft />
          </AnimationContainerMobile>

          <h2>Overview</h2>

          <p>
            This interface enables organisations to sell digital products and
            services – such as prepaid electricity, airtime, or digital content
            – over any channel. The interface provides a single integration
            point for the sale of the products that are available through an
            IMBEKO system. The API uses a simple HTTP request/response model.
          </p>
          <p>
            The interface also enables merchants – in-store and online – to
            request a catalogue of the products available to them for sale
            through the IMBEKO system.
          </p>
          <p>
            Note that this interface is not concerned with the redemption of
            these digital products or services.
          </p>
          <p>
            Note that the catalogue functionality available through this API is
            generic and returns a subset of the information that a provider
            supplies in their own catalogue. The API does not, for example,
            return all of the details that a provider may supply (such as terms
            and conditions or redemption instructions). During integration, if
            these additional details are needed for presentation to the
            consumer, then this will need to be addressed within the customer
            solution.
          </p>
        </Grid>

        <Grid
          xs={6}
          md={6}
          item
          style={{
            position: "relative",
          }}
        >
          <AnimationContainer>
            <FadeInUp>
              <img
                src={VASimg}
                alt="Vas Client API image"
                style={{ height: "400px", borderRadius: "15px" }}
              />
            </FadeInUp>
            <FadeInLeft />
          </AnimationContainer>
        </Grid>
      </Grid>

      <Grid
        item
        style={{
          background:
            "transparent linear-gradient(270deg, #0082D9 0%, #00C1FF 100%) 0% 0% no-repeat padding-box",

          color: "#fff",
          margin: "0 auto",
        }}
        className="tj-grid-container"
        id="implementation-guide"
      >
        <h2>Implementation Guide</h2>
        <p>
          Once a project has been lodged with Transaction Junction, development
          against the API can be performed by the integrator.
        </p>
        <p>
          The API is published as an OpenAPI 3 YAML document,{" "}
          <a href="#page-api" className="link">
            which is available on this page.
          </a>
        </p>
        <p>
          Using the OpenAPI 3 YAML format implies that much of the client-side
          code can be generated using the OpenAPI toolset encompassing a wide
          variety of programming languages and application frameworks. This
          should significantly reduce the effort required to complete the
          integration because it will largely eliminate any syntactic issues in
          the exchange of messages between the two parties.
        </p>
        <p>
          There is a wealth of information relating to the use of YAML
          documents.{" "}
          <a
            href="https://swagger.io"
            target="_blank"
            rel="nofollow"
            className="link"
          >
            Swagger
          </a>{" "}
          is a good place to start. To view the YAML document, a good option is
          the online Swagger Editor. To generate a Java client, C# client, or Go
          client from the YAML document, a good option is the{" "}
          <a
            href="https://github.com/OpenAPITools/openapi-generator"
            target="_blank"
            rel="nofollow"
            className="link"
          >
            OpenAPITools generator.
          </a>{" "}
          To generate tests from the YAML document, a good option is{" "}
          <a
            href=" https://learning.postman.com/docs/integrations/available-integrations/working-with-openAPI/"
            target="_blank"
            rel="nofollow"
            className="link"
          >
            Postman.
          </a>
        </p>
        <p>
          When the initial testing is complete to the satisfaction of both
          parties, user acceptance testing will be performed before piloting the
          solution or taking it live.
        </p>
        <p>
          Should you require any assistance, contact{" "}
          <a href="mailto:vasclient-integration@switch.tj" className="link">
            vasclient-integration@switch.tj
          </a>
          .
        </p>
      </Grid>
      <Grid
        item
        xs={12}
        id="operations"
        className="tj-grid-container"
        style={{
          background: "#00C1FF1A",
          color: "#00C1FF",
        }}
      >
        <h2 style={{ color: "#000" }}>Operations</h2>

        <VasClientTextAccordion />
      </Grid>
      <Grid item className="tj-grid-container" id="page-api">
        <TJSwagger url={vasapiYAML} />
      </Grid>

      <Grid
        id="response-codes"
        item
        xs={12}
        style={{
          background:
            "transparent linear-gradient(270deg, #0082D9 0%, #00C1FF 100%) 0% 0% no-repeat padding-box",
          color: "#fff",
          margin: "0 auto",
        }}
        className="tj-grid-container"
      >
        <h2>Response Codes</h2>
        <MaterialTable
          style={{ background: "transparent", boxShadow: "none" }}
          icons={tableIcons}
          columns={[
            {
              title: "Response Code",
              field: "code",
              type: "numeric",
              align: "left",
              headerStyle: {
                width: "20%",
              },
              cellStyle: {
                color: "#fff",
                width: "20%",
              },
            },
            {
              title: "Status",
              field: "status",
              headerStyle: {
                width: "40%",
              },
              cellStyle: {
                color: "#fff",
                width: "40%",
              },
            },
            {
              title: "Description",
              field: "message",
              cellStyle: {
                width: "60%",
                color: "#fff",
              },
              headerStyle: {
                width: "60%",
              },
            },
          ]}
          options={{
            pageSize: 10,
            toolbar: false,
            paginationType: "stepped",
            thirdSortClick: false,
            draggable: false,
            headerStyle: {
              background: "transparent",
              border: "none",
              color: "#fff",
              fontFamily: "montserrat-bold",
            },
          }}
          data={junctionPOSData.responseCodes}
          title=""
        />
      </Grid>
      <Grid
        item
        id="additional-resources"
        style={{
          background: "#FFFFFFD9",
          backgroundImage: `url(${AdditionalResourceImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          position: "relative",
        }}
        className="tj-grid-container"
      >
        <h2>Additional Developer Resources</h2>
        <h3 className="light-h3">INTEGRATION ASSISTANCE</h3>

        <p>
          <strong>GENERATION OF REFERENCE</strong>
        </p>
        <p>
          The reference property in the transaction bodies is a unique value
          that identifies the transaction over the full length of the
          authorisation and settlement chains. It is a UUID and follows RFC
          4122.
          <a
            href="https://tools.ietf.org/html/rfc4122"
            target="_blank"
            rel="nofollow"
            style={{
              color: "#034ea2",
              font: "16px Montserrat-Regular",
              textDecorationLine: "underline",
            }}
          >
            (https://tools.ietf.org/html/rfc4122)
          </a>
        </p>
        <p>
          <strong>JAVA GENERATION</strong>
        </p>
        <p>
          The UUID class in the Java SDK makes it easy to generate a UUID value
          for the reference property that makes all calls to this API.
          <br />
          <strong>An example follows:</strong>
        </p>
        <div className="code-box-container">
          <div className="body-container">
            <div className="code-container">
              <code>
                import java.util.UUID;
                <br />
                public class GenerateReference {`{`}
                <br />
                &nbsp;&nbsp;public static final void main(String[] args) {`{`}
                <br />
                &nbsp;&nbsp;UUID ref = UUID.randomUUID(); {`}`}
                <br />
                {`}`}
              </code>
            </div>
          </div>
        </div>

        <p>
          <strong>C# GENERATION</strong>
        </p>
        <p>
          An example program generating a GUID follows. Essentially, a GUID and
          UUID are the same concept.
        </p>
        <div className="code-box-container">
          <div className="body-container">
            <div className="code-container">
              <code>
                using System;
                <br />
                namespace uuid
                <br />
                &nbsp;&nbsp;{`{`} class Program
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`{`}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;static
                void Main(string[] args)
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {`{`}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Guid
                g;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;g
                = Guid.NewGuid();
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Console.WriteLine(g);
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {`}`}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {`}`}
                <br />
                &nbsp;&nbsp;&nbsp;{`}`}
              </code>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default VasClientPage;
